import React, { createContext } from "react";
import { useState } from "react";
import { GetUserSearchPresets } from "../models/Settings";

export const SearchPresetsContext = createContext();

export const SearchPresetsProvider = (props) => {
    const [presets, setPresets] = useState(GetUserSearchPresets());
    return (
        <SearchPresetsContext.Provider value={[presets, setPresets]}>
            {props.children}
        </SearchPresetsContext.Provider>
    );
};
