import React, { useState } from "react";
import { PostPayload } from "../../../../../functions/Http";
import Widget from "../../../../../widgets/Widget";
import SaveCancelButtons from "../../../../reusable/SaveCancelButtons";
import { SuccessAlert, ErrorAlert, AreYouSureAlert } from "../../../../reusable/Alerts";
import withAuthorizationCheck from "../../../../HOC/withAuthorizationCheck";
import { useAuth0 } from "@auth0/auth0-react";
import {useCurrentEnvironment} from "src/hooks/useEnvironment";

export default function AuthorizationsWidget(props) {
    const {
        account,
        authorizations,
        setAuthorizations,
        filteredAuths,
        label,
        searchBar,
        collapsible,
        overflowRef,
        shortcuts,
        permissions,
    } = props;

    const { getAccessTokenSilently } = useAuth0();
    const { baseUrl } = useCurrentEnvironment();
    const AUTHORIZATIONS_ENDPOINT = `${baseUrl}/accounts/${account.name}/authorizations`;
    const [saving, setSaving] = useState(false);
    const [authsCopy, setAuthsCopy] = useState(JSON.parse(JSON.stringify(authorizations)));
    const changedAuths = authsCopy.filter((auth, idx) => {
        return Boolean(auth.authorized) !== Boolean(authorizations[idx].authorized);
    });

    const shortcutButtons = shortcuts?.map((shortcut, idx) => {
        const authIdsMatchShortcutIds =
            authsCopy
                .filter((a) => a.authorized)
                .map((a) => a.authId)
                .sort()
                .join(",") === shortcut.authorizationIds.sort().join(",");
        return withAuthorizationCheck(
            permissions,
            <Widget.Button
                key={idx}
                text={shortcut.name}
                variant={authIdsMatchShortcutIds ? "success" : "outline-success"}
                onClick={() => handleShortcut(shortcut.authorizationIds)}
                style={{ marginRight: "10px" }}
            />,
            <Widget.Button
                key={idx}
                text={shortcut.name}
                variant={authIdsMatchShortcutIds && "success"}
                onClick={() => {}}
                style={{ marginRight: "10px" }}
                disabled
                title="User not authorized"
            />
        )();
    });

    const widgetListItems = (filteredAuths || authsCopy).map((auth) =>
        withAuthorizationCheck(
            permissions,
            <Widget.ToggleableListItem
                key={auth.authId}
                label={auth.authDescription}
                checked={isChecked(auth.authId)}
                changed={isChanged(auth.authId)}
                onChange={() => handleToggle(auth.authId)}
            />,
            <Widget.ToggleableListItem
                key={auth.authId}
                label={auth.authDescription}
                checked={isChecked(auth.authId)}
                changed={isChanged(auth.authId)}
                onChange={() => {}}
                disabled
            />
        )()
    );

    return (
        <React.Fragment>
            <Widget
                label={label}
                collapsible={collapsible}
                searchBar={searchBar}
                overflowRef={overflowRef}
                underLabel={shortcutButtons}>
                {widgetListItems}
            </Widget>
            <NumberOfChanges />
            <SaveCancelButtons
                visible={!!changedAuths.length}
                onSave={() => reviewChanges()}
                onCancel={() => handleCancel()}
                style={{ margin: "-10px 0 25px" }}
                showSpinner={saving}
                permissions={permissions}
            />
        </React.Fragment>
    );

    /*
     * ===================
     *      Functions
     * ===================
     */

    /**
     * If changes have been made, displays how many changes have taken place.
     */
    function NumberOfChanges() {
        const changes = changedAuths.length;
        return changes ? (
            <div style={{ float: "left", marginTop: "-15px" }}>
                {`${changes} ${changes === 1 ? "change" : "changes"}`}
            </div>
        ) : null;
    }

    /**
     * Determines whether or not to show the item in the list as "checked"
     * based on the "authorized" value. If the authorization's index is found in
     * the "changed" list, then the opposite of the actual "checked" value will
     * be displayed.
     *
     * @param {number} index the index of the auth in the "authList" list
     */
    function isChecked(authId) {
        return authsCopy.find((o) => o.authId === authId).authorized;
    }

    /**
     * Determines whether or not the user has changed this value in order
     * to indicate that on screen.
     *
     * @param {Number} authId the "authId" of the authorization
     */
    function isChanged(authId) {
        const db_auth = authorizations.find((o) => o.authId === authId).authorized;
        const local_auth = authsCopy.find((o) => o.authId === authId).authorized;
        return Boolean(db_auth) !== Boolean(local_auth);
    }

    /**
     * Updates the authsCopy list to reflect the changes the user is making on a
     * per-item basis (i.e. only handles one authorization being toggled at a time).
     *
     * @param {number} authId the "authId" of the authorization
     */
    function handleToggle(authId) {
        const auths = [...authsCopy];
        const auth = auths.find((auth) => auth.authId === authId);
        auth.authorized = !auth.authorized;
        setAuthsCopy(auths);
    }

    /**
     * Changes all of the authorizations to match the values outlined by the shortcut
     * (if shortcuts were passed as a prop). Shortcuts should include a list of IDs
     * for the authorizations that should be set to true. All other authorizations
     * will be set to false.
     *
     * @param {Array.<Number>} authIds an array of authorization IDs to set to true
     */
    function handleShortcut(authIds) {
        const auths = [...authsCopy];
        auths.forEach((auth) => (auth.authorized = authIds.includes(auth.authId)));
        setAuthsCopy(auths);
    }

    /**
     * Reviews changes being made and prompts user to confirm actions.
     */
    function reviewChanges() {
        const review = (
            <div className="review">
                {changedAuths.map((auth, idx) => {
                    const action = auth.authorized ? "Enable" : "Disable";
                    const className = action.toLowerCase();
                    return (
                        <div key={idx}>
                            <span className={className}>{action}</span>
                            <span>&nbsp;{auth.authDescription}</span>
                        </div>
                    );
                })}
            </div>
        );
        AreYouSureAlert({ html: review }).then(({ isConfirmed }) => isConfirmed && save());
    }

    /**
     * Saves changes to the database.
     */
    async function save() {
        setSaving(true);
        const token = await getAccessTokenSilently();
        const payload = PostPayload(token, { authorizations: changedAuths });

        fetch(AUTHORIZATIONS_ENDPOINT, payload)
            .then(async (res) => {
                const json = await res.json();
                if (!res.ok) throw Error(`Failed to save changes: ${JSON.stringify(json)}`);
                return json;
            })
            .then(() => SuccessAlert().then(() => setAuthorizations([...authsCopy])))
            .catch((err) => {
                ErrorAlert();
                console.log("Error saving authorizations", err);
            });
    }

    /**
     * Cancels out any changes that have been made.
     */
    async function handleCancel() {
        const warning = "All changes for this section will be reverted.";
        AreYouSureAlert({ text: warning }).then(({ isConfirmed }) => {
            isConfirmed && setAuthsCopy(JSON.parse(JSON.stringify(authorizations)));
        });
    }
}
