import React, { useContext } from "react";
import { Modal, Alert } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { AreYouSureAlert } from "src/components/reusable/Alerts";
import { ErrorAlert, SuccessAlert } from "src/components/reusable/Alerts";
import { ErrorMessage } from "src/components/reusable/ReactFormComponents";
import { PostPayload } from "src/functions/Http";
import SaveCancelButtons from "src/components/reusable/SaveCancelButtons";
import Widget from "src/widgets/Widget";
import { WebhookPatternRegex } from "src/enums/RegularExpressions";
import { useMutateWebhook } from "src/hooks/react-query/accounts/useWebhooks";
import { useWebhooks } from "src/hooks/react-query/accounts/useWebhooks";
import { AlertWrapper } from "../auth-schema-list/AuthSchemaListItemContent";
import { ThemeContext } from "src/contexts/ThemeContext";
import { patternMatchesPattern } from "src/hooks/react-query/accounts/useWebhooks";
import InfoIcon from "src/components/reusable/InfoIcon";

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const NewWebhookModal = ({ account, schemas, visible, onHide, onSave, permissions }) => {
    const defaultPattern = "https://";
    const defaultSchemaId = schemas?.[0]?.id ? String(schemas?.[0]?.id) : "";
    const defaultValues = {
        webhook: { urlPattern: defaultPattern, authSchemaId: defaultSchemaId },
    };
    const { register, errors, handleSubmit, control, reset } = useForm({
        mode: "onChange",
        defaultValues,
    });

    const urlPattern = useWatch({ control, name: "webhook.urlPattern" });
    const authSchemaId = useWatch({ control, name: "webhook.authSchemaId" });
    const changesMade = urlPattern !== defaultPattern || authSchemaId !== defaultSchemaId;

    const { darkMode } = useContext(ThemeContext);
    const { data } = useWebhooks(account.name);
    const webhooks = data?.webhooks;
    const duplicate = webhooks?.find((w) => w.urlPattern === urlPattern);
    const potentialConflicts = webhooks?.filter((w) => {
        return (
            patternMatchesPattern(w.urlPattern, urlPattern) ||
            patternMatchesPattern(urlPattern, w.urlPattern)
        );
    });

    const addWebhook = useMutateWebhook({
        accountName: account.name,
        onSuccess: () => SuccessAlert().then(() => onSave?.()),
        onError: () => ErrorAlert(),
    });

    return (
        <Modal
            size="lg"
            show={visible}
            onHide={() => onHide()}
            id="new-cred-modal"
            backdrop={changesMade ? "static" : true}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>New Webhook</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Widget>
                    <AlertWrapper darkMode={darkMode}>
                        <Alert variant="primary">
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto",
                                    alignItems: "center",
                                }}>
                                <div>
                                    <p>Examples:</p>
                                    <ul>
                                        <li>https://company.com</li>
                                        <li>https://company.com/*</li>
                                        <li>https://company.com/path/*</li>
                                        <li>https://company.com/*/path/*</li>
                                    </ul>
                                </div>
                                <InfoIcon info={wildcardInfo()} style={{ fontSize: "20px" }} />
                            </div>
                        </Alert>
                    </AlertWrapper>
                    {duplicate ? (
                        <Alert variant="danger" style={{ marginBottom: 15, marginTop: 10 }}>
                            This pattern already exists
                        </Alert>
                    ) : potentialConflicts?.length ? (
                        <AlertWrapper darkMode={darkMode}>
                            <Alert variant="danger">
                                <p>
                                    {(() => {
                                        const s = potentialConflicts.length > 1 ? "s" : "";
                                        return `Potential conflict${s} detected:`;
                                    })()}
                                </p>
                                <ul>
                                    {potentialConflicts?.map((w, idx) => (
                                        <li key={idx}>{w.urlPattern}</li>
                                    ))}
                                </ul>
                            </Alert>
                        </AlertWrapper>
                    ) : null}
                    <div>
                        <Widget.Input
                            permissions={permissions}
                            type="text"
                            name="webhook.urlPattern"
                            label="URL Pattern"
                            value={urlPattern}
                            placeholder="https://company.domain.com/postback/*"
                            ref={register({
                                ...required,
                                validate: (value) => {
                                    if (!WebhookPatternRegex.test(value))
                                        return "Must be a valid HTTPS URL";
                                },
                            })}
                            style={inputStyle}
                        />
                        <ErrorMessage message={errors.webhook?.urlPattern?.message} />
                    </div>
                    <div>
                        <Widget.Select
                            defaultValue={defaultSchemaId}
                            permissions={permissions}
                            name="webhook.authSchemaId"
                            label="Authentication"
                            noValueOption
                            noValueOptionLabel="NONE"
                            options={schemas?.map((s, idx) => ({
                                label: `Auth #${++idx}: [${s.type}]`,
                                value: String(s.id),
                            }))}
                            optionLabelProp={"label"}
                            optionValueProp={"value"}
                            ref={register()}
                        />
                    </div>
                </Widget>
                <SaveCancelButtons
                    visible={true}
                    onSave={handleSubmit(handleSave)}
                    onCancel={onCancel}
                    permissions={permissions}
                    saveDisabled={duplicate || !changesMade || !urlPattern || !!errors.webhook}
                />
            </Modal.Body>
        </Modal>
    );

    /*
     * ===================
     *      Functions
     * ===================
     */
    /**
     * Handles the UI functionality when the user clicks "Cancel".
     */
    function onCancel() {
        const cancel = () => {
            reset(defaultValues);
            onHide();
        };
        if (!changesMade) return cancel();
        const confirmText = "Unsaved changes will be lost.";
        AreYouSureAlert({ text: confirmText }).then(({ isConfirmed }) => isConfirmed && cancel());
    }

    function handleSave() {
        onHide();
        addWebhook.mutate((token) => {
            return PostPayload(token, {
                accountId: account.id,
                urlPattern: urlPattern,
                authSchemaId: parseInt(authSchemaId),
            });
        });
    }
};

export default NewWebhookModal;

/* eslint-disable react/jsx-no-comment-textnodes */
function wildcardInfo() {
    return (
        <span>
            Patterns can contain wildcard characters denoted with <code>*</code> which will match
            any non-slash character within the URL at that point unless the wildcard appears at the
            end of the pattern. If the pattern ends with <code>*</code> or <code>/*</code>, then any
            characters, including slash characters, are allowed where this wildcard is matched.
        </span>
    );
}
