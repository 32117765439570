import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { useInterval } from "src/hooks/useInterval";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

const UsernamePasswordWrapper = styled.div`
    margin-top: 15px;
    position: relative;

    i {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;

        &.fa-check {
            color: var(--success);
        }
    }

    .copied {
        position: absolute;
        right: 40px;
        top: 14px;
        font-size: 10px;
        font-weight: 700;
        background: #62b38a;
        border-radius: 3px;
        border: none;
        color: #103d1b;
        letter-spacing: 1px;
        white-space: nowrap;
        padding: 3px 0px;
        width: 0px;
        overflow: hidden;
        visibility: hidden;
        transition: all 0.15s ease;

        &.show {
            visibility: visible;
            border: 1px solid #103d1b;
            padding: 3px 5px;
            width: 130px;
            transition: all 0.15s ease;
        }
    }

    & .values-wrapper {
        text-align: left;
        display: grid !important;
        grid-auto-flow: row;
        row-gap: 20px !important;

        & span {
            display: grid;
            grid-auto-flow: row;
            row-gap: 3px;
            word-break: break-all;
        }
    }
`;

export default function UsernamePasswordDisplay({ username, password }) {
    const [copied, setCopied] = useState(false);

    useInterval(() => setCopied(false), copied ? 3000 : null);

    const copyText = () => {
        const formattedValues = `Username:\n${username}\n\nPassword:\n${password}`;
        navigator.clipboard.writeText(formattedValues);
        setCopied(true);
    };

    return (
        <React.Fragment>
            <Alert variant="danger" style={{ marginTop: 15 }}>
                <div>ATTENTION: Store these immediately!</div>
                <br />
                <div>
                    This username and password cannot be viewed again once this modal is closed.
                </div>
            </Alert>
            <UsernamePasswordWrapper className="review">
                <span className={`copied${copied ? " show" : ""}`}>Copied to clipboard!</span>
                <i
                    onClick={copyText}
                    className="far fa-copy"
                    title={copied ? "Copied to clipboard!" : "Copy to clipboard"}
                />
                <div className="values-wrapper">
                    <span>
                        <b>Username:</b>
                        <code>{username}</code>
                    </span>
                    <span>
                        <b>Password:</b>
                        <code>{password}</code>
                    </span>
                </div>
            </UsernamePasswordWrapper>
        </React.Fragment>
    );
}
