import React, { useState, useRef } from "react";
import Widget from "src/widgets/Widget";
import { PutPayload, DeletePayload } from "src/functions/Http";
import { AreYouSureAlert } from "src/components/reusable/Alerts";
import { SuccessAlert } from "src/components/reusable/Alerts";
import { ErrorAlert } from "src/components/reusable/Alerts";
import SaveCancelButtons from "src/components/reusable/SaveCancelButtons";
import { useForm, useWatch } from "react-hook-form";
import { useMutateCredentials } from "src/hooks/react-query/accounts/useCredentials";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
import { useCredentials } from "src/hooks/react-query/accounts/useCredentials";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
const { styled } = StyledComponentContext._currentValue;

const ListItemContentWrapper = styled.div`
    display: grid;
    row-gap: 10px;
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    margin-bottom: 5px;
    column-gap: 10px;
`;

const required = { required: "This field is required" };

const CredentialListItemContent = ({ account, credential, permissions }) => {
    const [editMode, setEditMode] = useState(null);
    const isMounted = useRef(null);

    const { refetch } = useCredentials(account.name);
    const updateCredentials = useMutateCredentials({
        accountName: account.name,
        onSuccess: () => {
            if (!isMounted.current) return;
            refetch()
                .then(SuccessAlert)
                .then(() => editMode && setEditMode(false));
        },
        onError: () => ErrorAlert(),
    });

    const defaultValues = { nickname: credential.nickname };
    const { register, errors, control, reset } = useForm({
        mode: "onChange",
        defaultValues,
    });
    const nickname = useWatch({ control, name: "nickname" });
    const changesMade = nickname !== credential.nickname;

    const onCancel = () => {
        const cancel = () => {
            reset(defaultValues);
            setEditMode(false);
        };
        if (!changesMade) return cancel();
        const confirmText = "Unsaved changes will be lost.";
        AreYouSureAlert({ text: confirmText }).then(({ isConfirmed }) => isConfirmed && cancel());
    };

    return (
        <ListItemContentWrapper ref={isMounted}>
            <Widget.Input
                readOnly={!editMode}
                label="Description"
                name="nickname"
                value={credential.nickname}
                ref={register(required)}
            />
            <ButtonWrapper>
                {editMode ? (
                    <SaveCancelButtons
                        visible={true}
                        onSave={() => handleSaveNickname(nickname)}
                        onCancel={onCancel}
                        permissions={permissions}
                        saveDisabled={!changesMade || !!errors.nickname}
                    />
                ) : (
                    withAuthorizationCheck(
                        permissions,
                        listItemOptions()
                            .filter(Boolean)
                            .map((option, idx) => (
                                <Widget.Button
                                    key={idx}
                                    variant={option.variant}
                                    onClick={option.handler}
                                    title={option.title}
                                    text={option.name}
                                />
                            ))
                    )()
                )}
            </ButtonWrapper>
        </ListItemContentWrapper>
    );

    /*
     * ===================
     *      Functions
     * ===================
     */
    function listItemOptions() {
        return [
            {
                name: "Edit",
                title: "Edit description",
                variant: "secondary",
                handler: () => setEditMode(true),
            },
            credential.active && {
                name: "Deactivate",
                title: "Deactivate credential",
                variant: "danger",
                handler: () => handleSetActive(false),
            },
            !credential.active && {
                name: "Activate",
                title: "Activate credential",
                variant: "info",
                handler: () => handleSetActive(true),
            },
            !credential.active && {
                name: "Delete",
                title: "Delete credential",
                variant: "danger",
                handler: () => handleDelete(),
            },
        ];
    }

    /**
     * Wrapper for making a request and handling timing of Swal alerts
     * as well as refreshing the page content.
     *
     * @param {*} text the text to show on the AreYouSureAlert
     * @param {*} payloadFunc a function which passes the access token as the
     *  only parameter and should return a payload to make the request with
     */
    async function makeRequest(text, payloadFunc) {
        AreYouSureAlert({ text }).then(async ({ isConfirmed }) => {
            if (!isConfirmed) return;
            updateCredentials.mutate(payloadFunc);
        });
    }

    /**
     * Handles updating the nickname of a credential.
     *
     * @param {*} newNickname the new nickname
     */
    async function handleSaveNickname(newNickname) {
        makeRequest(null, (token) =>
            PutPayload(token, {
                accountId: account.id,
                credentialId: credential.id,
                nickname: newNickname,
                active: Boolean(credential.active),
            })
        );
    }

    /**
     * Handles updating the active status of a credential.
     *
     * @param {*} setActive the new active status
     */
    async function handleSetActive(setActive) {
        const activateText = `
            This will allow this credential
            to be used to generate new access
            tokens for API access.`;
        const deactivateText = `
            This will prevent this credential
            from being used to generate new access
            tokens for API access.`;

        makeRequest(setActive ? activateText : deactivateText, (token) =>
            PutPayload(token, {
                accountId: account.id,
                credentialId: credential.id,
                nickname: credential.nickname,
                active: setActive,
            })
        );
    }

    /**
     * Handles deleting a credential.
     */
    async function handleDelete() {
        const text = "This credential will be permanently deleted.";
        makeRequest(text, (token) =>
            DeletePayload(token, {
                accountId: account.id,
                credentialId: credential.id,
            })
        );
    }
};

export default CredentialListItemContent;
