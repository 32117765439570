import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { NewSearchPreset } from "./NewSearchPreset";
import { SearchPreset } from "./SearchPreset";
import { v4 as uuid } from "uuid";
import { Settings } from "../../models/Settings";
import { SearchPresetsContext } from "../../contexts/SearchPresetsContext";

/**
 * Sorts search presets by their name in alphabetical order.
 *
 * @param {Object[]} arr objects representing a preset with a name property
 */
const sort = (arr) => {
    const sorted = [...arr];
    sorted.sort((s1, s2) => {
        return s1.name.localeCompare(s2.name);
    });
    return sorted;
};

/**
 * The section of the settings page pertaining to search presets.
 * Contains the "New Preset" component as well as the list of stored user presets.
 */
export const SearchPresetSettings = () => {
    const [presets, setPresets] = useContext(SearchPresetsContext);
    const names = presets.length > 0 ? presets.map((p) => p.name) : [];
    const invalidFeedback = (
        <Form.Control.Feedback type="invalid">Name already in use</Form.Control.Feedback>
    );

    const add = (name, search) => {
        const preset = {
            id: uuid(),
            name,
            search,
        };
        const newPresets = [...presets, preset];
        setPresets(sort(newPresets));
        localStorage.setItem(Settings.PRESETS, JSON.stringify(newPresets));
    };

    const save = (id, name, search) => {
        const newPresets = [...presets];
        const preset = newPresets.find((p) => p.id === id);
        preset.name = name;
        preset.search = search;
        setPresets(sort(newPresets));
        localStorage.setItem(Settings.PRESETS, JSON.stringify(newPresets));
    };

    const remove = (id) => {
        const newPresets = presets.filter((p) => p.id !== id);
        setPresets(sort(newPresets));
        localStorage.setItem(Settings.PRESETS, JSON.stringify(newPresets));
    };

    const Headings = () => {
        return presets.length === 0 ? (
            <small>
                When you add presets, they will appear here and be available to use as a default
                search as well as appear in a dropdown next to the search area for convenient
                access.
            </small>
        ) : (
            <div className="headings">
                <div>
                    <Form.Label>Name</Form.Label>
                </div>
                <div>
                    <Form.Label>Search Regex</Form.Label>
                </div>
            </div>
        );
    };

    return (
        <div>
            <b>Transaction Data Search Presets</b>
            <hr />
            <NewSearchPreset names={names} invalidFeedback={invalidFeedback} onAdd={add} />
            <Headings />
            {presets.map((preset) => (
                <SearchPreset
                    key={preset.id}
                    id={preset.id}
                    name={preset.name}
                    search={preset.search}
                    onSave={save}
                    onRemove={remove}
                    names={names}
                    invalidFeedback={invalidFeedback}
                />
            ))}
        </div>
    );
};
