import React, { useContext } from "react";
import { EditorCaseSettings } from "./EditorCaseSettings";
// import { EditorLanguageSettings } from "./EditorLanguageSettings";
import { EditorFontSettings } from "./EditorFontSettings";
import { EditorSearchSettings } from "./EditorSearchSettings";
import { Settings } from "../../models/Settings";
import { EditorDataSettings } from "./EditorDataSettings";
import { SearchPresetsContext } from "../../contexts/SearchPresetsContext";

export const Sides = {
    LEFT: "Left",
    RIGHT: "Right",
};

/**
 * The settings applicable to the editor functionality.
 *
 * @param {Object} props props
 * @param {Object} props.side Sides.LEFT or Sides.RIGHT
 */
export const EditorSettings = ({ side }) => {
    const [presets] = useContext(SearchPresetsContext);
    const leftSide = side === Sides.LEFT;
    const editor = leftSide ? "leftEditor" : "rightEditor";
    const caseSetting = leftSide ? Settings.LEFT_CASE : Settings.RIGHT_CASE;
    const dataSetting = leftSide ? Settings.LEFT_DATA : Settings.RIGHT_DATA;
    // const langSetting = leftSide ? Settings.LEFT_LANG : Settings.RIGHT_LANG;
    const fontSetting = leftSide ? Settings.LEFT_FONT : Settings.RIGHT_FONT;
    const searchSetting = leftSide ? Settings.LEFT_SEARCH : Settings.RIGHT_SEARCH;
    return (
        <div>
            <b>{side} Editor Defaults</b>
            <hr />
            <EditorCaseSettings ls_settingName={caseSetting} />
            <EditorDataSettings editor={editor} ls_settingName={dataSetting} />
            {/* <EditorLanguageSettings ls_settingName={langSetting} /> */}
            <EditorFontSettings ls_settingName={fontSetting} />
            <EditorSearchSettings
                editor={editor}
                ls_settingName={searchSetting}
                presets={presets}
            />
        </div>
    );
};
