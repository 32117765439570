import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

export const RequiredStar = styled.span`
    &::after {
        content: " *";
        color: orangered;
    }
`;

export const InputLabel = styled.label`
    font-size: 12px;
    margin-bottom: 5px;
    margin-left: 2px;
`;
