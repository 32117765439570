import React, { useState, createContext } from "react";

export const TransactionContext = createContext();

export const TransactionProvider = (props) => {
    const [transaction, setTransaction] = useState(null);

    return (
        <TransactionContext.Provider value={[transaction, setTransaction]}>
            {props.children}
        </TransactionContext.Provider>
    );
};
