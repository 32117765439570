import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { UserContext } from "../../../../../contexts/UserContext";
import { ValidEmailRegex } from "../../../../../enums/RegularExpressions";
import Widget from "../../../../../widgets/Widget";
import { AreYouSureAlert } from "../../../../reusable/Alerts";
import { ErrorMessage } from "../../../../reusable/ReactFormComponents";
import SaveCancelButtons from "../../../../reusable/SaveCancelButtons";
import EditCloseButtons from "../../../../reusable/EditCloseButtons";
import { TwoColumnLayout } from "../../../../styled_components/Layouts";

const ReqFields = ["firstName", "lastName", "email"];
const requiredField = { required: "This field is required" };
const PrimaryChangeNotice = (
    <i style={{ fontSize: "12px", marginLeft: "5px" }}>
        This contact will become the primary contact for the account
    </i>
);

const UpdatePermission = ["account:contact:update"];
const DeletePermission = ["account:contact:delete"];

/*
 * ===============
 *      Modal
 * ===============
 */
class EditContactModal extends Component {
    static contextType = UserContext;

    origValues = {
        primary: !!this.props.contact?.primary,
        firstName: this.props.contact?.firstName || "",
        lastName: this.props.contact?.lastName || "",
        role: this.props.contact?.role || "",
        email: this.props.contact?.email || "",
        phone: this.props.contact?.phone || "",
    };

    state = { ...this.origValues };

    render() {
        const canUpdate = this.context.hasPermissions(UpdatePermission);
        const canDelete = this.context.hasPermissions(DeletePermission);
        const { contact, showModal, onSave, onDelete, saving, formControls } = this.props;
        const { register, errors } = formControls;
        const { primary, firstName, lastName, role, email, phone } = this.state;

        const errorMsg = (fieldName) =>
            errors[fieldName] && <ErrorMessage>{errors[fieldName].message}</ErrorMessage>;

        // if (primary) ReqFields.push("phone");

        const updatedValues = this.updatedValues();
        const modalTitle = contact.id ? "Contact Details" : "New Contact";
        const saveDisabled = contact.id
            ? !(Object.keys(updatedValues).length && this.reqFieldsPresent())
            : !this.reqFieldsPresent();

        return (
            <Modal
                size="lg"
                show={showModal}
                onHide={() => this.onCancel()}
                id="edit-contact-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Widget.ToggleableListItem
                        label="Primary contact"
                        inputName="primary"
                        checked={primary}
                        onChange={() => this.setState({ primary: !this.state.primary })}
                        changed={updatedValues.primary !== undefined}
                        ref={register({})}
                        disabled={!canUpdate}
                    />
                    {!this.origValues.primary && primary && PrimaryChangeNotice}
                    <Widget.Spacer />
                    <Widget.Input
                        type="text"
                        name="firstName"
                        label="First name"
                        placeholder="John"
                        value={firstName}
                        required={true}
                        ref={register({ ...requiredField })}
                        onChange={(firstName) => this.setState({ firstName })}
                        permissions={UpdatePermission}
                    />
                    {errorMsg("firstName")}
                    <Widget.Spacer />
                    <Widget.Input
                        type="text"
                        name="lastName"
                        label="Last name"
                        placeholder="Smith"
                        value={lastName}
                        required={true}
                        ref={register({ ...requiredField })}
                        onChange={(lastName) => this.setState({ lastName })}
                        permissions={UpdatePermission}
                    />
                    {errorMsg("lastName")}
                    <Widget.Spacer />
                    <Widget.Input
                        type="text"
                        name="email"
                        label="Email"
                        placeholder={`jsmith@company.com`} // TODO: Add company name?
                        value={email}
                        required={true}
                        ref={register({
                            ...requiredField,
                            pattern: {
                                value: ValidEmailRegex,
                                message: "Invalid email address",
                            },
                        })}
                        onChange={(email) => this.setState({ email })}
                        permissions={UpdatePermission}
                    />
                    {errorMsg("email")}
                    <Widget.Spacer />
                    <Widget.Input
                        type="text"
                        name="role"
                        label="Role"
                        placeholder="Sales Associate"
                        value={role}
                        ref={register()}
                        onChange={(role) => this.setState({ role })}
                        permissions={UpdatePermission}
                    />
                    {errorMsg("role")}
                    <Widget.Spacer />
                    <Widget.Input
                        type="text"
                        name="phone"
                        phone="phone"
                        label="Phone number"
                        placeholder="704-555-8800"
                        value={phone}
                        // required={primary}
                        ref={register({
                            // required: primary ? "Required for primary contacts" : false,
                            pattern: {
                                value: /^\d{3}-\d{3}-\d{4}$/,
                                message: "Must be in format XXX-XXX-XXXX",
                            },
                        })}
                        onChange={(phone) => this.setState({ phone })}
                        permissions={UpdatePermission}
                    />
                    {errorMsg("phone")}
                    <Widget.Spacer height="15" />
                    <TwoColumnLayout>
                        <div>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="hover-danger"
                                style={{
                                    display: canDelete ? "block" : "none",
                                    visibility: !contact.id || saving ? "hidden" : "visible",
                                }}
                                onClick={() => (canUpdate ? onDelete(contact) : null)}
                                disabled={!canUpdate}
                                title={canUpdate ? "" : "User not authorized"}>
                                Delete
                            </Button>
                        </div>
                        {canUpdate ? (
                            <SaveCancelButtons
                                visible={true}
                                saveDisabled={saveDisabled}
                                onSave={() => onSave({ ...this.state })}
                                onCancel={() => this.onCancel()}
                                showSpinner={saving}
                                permissions={UpdatePermission}
                            />
                        ) : (
                            <EditCloseButtons hideEdit onClose={this.onCancel} />
                        )}
                    </TwoColumnLayout>
                </Modal.Body>
            </Modal>
        );
    }

    /**
     * Cancels the editing of the contact and closes the modal.
     * If the contact has been changed, will prompt user to confirm
     * they want to finish editing before closing modal.
     *
     * @param {Object} param.saveDisabled whether or not the save button is disabled
     */
    onCancel = async () => {
        if (!Object.keys(this.updatedValues()).length) return this.props.setShowModal(false);
        const warning = "Are you sure you want to stop editing? All unsaved changes will be lost.";
        const { isConfirmed } = await AreYouSureAlert({ text: warning });
        isConfirmed && this.props.setShowModal(false);
    };

    /**
     * Determines which values have changed and returns them in an object.
     */
    updatedValues = () =>
        Object.entries(this.origValues)
            .filter(([key, val]) => val !== this.state[key])
            .reduce((prev, [key]) => ({ ...prev, [key]: this.state[key] }), {});

    /**
     * Returns true if all required fields have a value.
     */
    reqFieldsPresent = () =>
        Object.entries(this.state)
            .filter(([k]) => ReqFields.includes(k))
            .every(([, v]) => !!v);
}

export default EditContactModal;
