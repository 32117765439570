/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Widget from "src/widgets/Widget";
import { useForm, useWatch } from "react-hook-form";
import { ErrorMessage } from "src/components/reusable/ReactFormComponents";
import { WebhookPatternRegex } from "src/enums/RegularExpressions";
import SaveCancelButtons from "src/components/reusable/SaveCancelButtons";
import { AreYouSureAlert } from "src/components/reusable/Alerts";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
import { PutPayload, DeletePayload } from "src/functions/Http";
import { ErrorAlert } from "src/components/reusable/Alerts";
import { SuccessAlert } from "src/components/reusable/Alerts";
import { useMutateWebhook } from "src/hooks/react-query/accounts/useWebhooks";
import { useWebhooks } from "src/hooks/react-query/accounts/useWebhooks";
import { ThemeContext } from "src/contexts/ThemeContext";
import { Alert } from "react-bootstrap";
import { AlertWrapper } from "../auth-schema-list/AuthSchemaListItemContent";
import { patternMatchesPattern } from "src/hooks/react-query/accounts/useWebhooks";
import InfoIcon from "src/components/reusable/InfoIcon";
const { styled } = StyledComponentContext._currentValue;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 160px;
    // align-items: end;
    column-gap: 15px;

    .dropdown-toggle {
        height: 34px;
        margin-bottom: 1px;
    }

    .dropdown-item {
        text-transform: capitalize;
    }
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    margin: 5px 0;
    column-gap: 10px;
`;

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const WebhookListItemContent = (props) => {
    const { account, currentWebhook, schemas, permissions, refresh } = props;
    const {
        id: webhookId,
        urlPattern: defaultUrlPattern,
        authSchemaId: currentSchemaId,
    } = currentWebhook;

    const { data } = useWebhooks(account.name);
    const webhooks = data?.webhooks;
    const { darkMode } = useContext(ThemeContext);

    /*
     * ================
     *      Fields
     * ================
     */
    const defaultSchemaId = currentSchemaId ? String(currentSchemaId) : "";
    const [editMode, setEditMode] = useState(false);
    const [unsavedWork, setUnsavedWork] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const isMounted = useRef(null);

    /*
     * =====================
     *      Form Values
     * =====================
     */
    const defaultValues = {
        webhook: {
            urlPattern: defaultUrlPattern,
            authSchemaId: defaultSchemaId,
        },
    };
    const { register, errors, control, reset, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues,
    });
    const urlPattern = useWatch({ control, name: "webhook.urlPattern" });
    const authSchemaId = useWatch({ control, name: "webhook.authSchemaId" });
    const urlPatternChanged = urlPattern !== defaultUrlPattern;
    const authSchemaChanged = authSchemaId !== defaultSchemaId;
    const changesMade = urlPatternChanged || authSchemaChanged;
    const duplicate = webhooks?.find((w) => urlPatternChanged && w.urlPattern === urlPattern);
    const conflicts = webhooks?.filter((w) => {
        if (defaultUrlPattern === w.urlPattern) return false;
        return (
            patternMatchesPattern(w.urlPattern, urlPattern) ||
            patternMatchesPattern(urlPattern, w.urlPattern)
        );
    });

    /*
     * ===================
     *      Component
     * ===================
     */
    useEffect(() => {
        setUnsavedWork(changesMade);
        if (!changesMade) return setSaveBtnDisabled(true);
        setSaveBtnDisabled(!urlPattern || !!errors.webhook);
    }, [urlPattern, authSchemaId]);

    const updateWebhook = useMutateWebhook({
        accountName: account.name,
        onSuccess: () =>
            refresh()
                .then(SuccessAlert)
                .then(() => {
                    if (!isMounted.current) return;
                    setEditMode(false);
                }),
        onError: () => ErrorAlert(),
    });

    const conflictAlert = duplicate ? (
        <Alert variant="danger" style={{ marginBottom: 15, marginTop: 10 }}>
            This pattern already exists
        </Alert>
    ) : conflicts?.length ? (
        <AlertWrapper darkMode={darkMode}>
            <Alert
                variant="danger"
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    alignItems: "center",
                }}>
                <div>
                    <p>
                        {(() => {
                            const s = conflicts.length > 1 ? "s" : "";
                            return `Potential conflict${s} detected:`;
                        })()}
                    </p>
                    <ul>
                        {conflicts.map((w, idx) => (
                            <li key={idx}>{w.urlPattern}</li>
                        ))}
                    </ul>
                </div>
                <InfoIcon info={conflictInfo()} style={{ fontSize: "20px" }} />
            </Alert>
        </AlertWrapper>
    ) : null;

    return (
        <div ref={isMounted}>
            {conflictAlert}
            <input type="hidden" value={webhookId} name="webhook.id" ref={register()} />
            <InputWrapper>
                <div>
                    <Widget.Input
                        readOnly={!editMode}
                        permissions={permissions}
                        type="text"
                        name="webhook.urlPattern"
                        label="URL Pattern"
                        value={urlPattern}
                        placeholder="https://company.domain.com/postback/*"
                        ref={register({
                            ...required,
                            validate: (value) => {
                                if (!WebhookPatternRegex.test(value))
                                    return "Must be a valid webhook pattern with HTTPS protocol";
                            },
                        })}
                        style={inputStyle}
                    />
                    <ErrorMessage message={errors.webhook?.urlPattern?.message} />
                </div>
                <div>
                    <Widget.Select
                        readOnly={!editMode}
                        defaultValue={authSchemaId}
                        permissions={permissions}
                        name="webhook.authSchemaId"
                        label="Authentication"
                        noValueOption
                        noValueOptionLabel="NONE"
                        options={schemas.map((s, idx) => ({
                            label: `Auth #${++idx}: [${s.type}]`,
                            value: String(s.id),
                        }))}
                        optionLabelProp={"label"}
                        optionValueProp={"value"}
                        ref={register()}
                    />
                </div>
            </InputWrapper>
            <ButtonWrapper>
                {editMode ? (
                    <SaveCancelButtons
                        permissions={permissions}
                        visible={true}
                        onSave={handleSubmit(handleSave)}
                        onCancel={onCancel}
                        showSpinner={false}
                        saveDisabled={duplicate || saveBtnDisabled}
                    />
                ) : (
                    withAuthorizationCheck(
                        permissions,
                        listItemOptions()
                            .filter(Boolean)
                            .map((option, idx) => (
                                <Widget.Button
                                    key={idx}
                                    variant={option.variant}
                                    onClick={option.handler}
                                    title={option.title}
                                    text={option.name}
                                />
                            ))
                    )()
                )}
            </ButtonWrapper>
        </div>
    );

    /*
     * ===================
     *      Functions
     * ===================
     */
    function listItemOptions() {
        return [
            {
                name: "Edit",
                title: `Edit this configuration`,
                variant: "secondary",
                handler: () => setEditMode(true),
            },
            {
                name: "Delete",
                title: `Delete this webhook`,
                variant: "danger",
                handler: handleDelete,
            },
        ];
    }

    function onCancel() {
        const cancel = () => {
            reset(defaultValues);
            setSaveBtnDisabled(true);
            setEditMode(false);
        };
        if (!unsavedWork) return cancel();
        const confirmText = "Unsaved changes will be lost.";
        AreYouSureAlert({ text: confirmText }).then(({ isConfirmed }) => isConfirmed && cancel());
    }

    function handleSave({ webhook: values }) {
        const text = "You are about to update this webhook configuration.";
        AreYouSureAlert({ text }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            updateWebhook.mutate((token) =>
                PutPayload(token, {
                    id: Number.parseInt(values.id),
                    urlPattern: urlPattern,
                    authSchemaId: Number.parseInt(authSchemaId),
                })
            );
        });
    }

    function handleDelete() {
        const text = "You are about to delete this webhook configuration.";
        AreYouSureAlert({ text }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            updateWebhook.mutate((token) =>
                DeletePayload(token, {
                    accountId: account.id,
                    webhookId: webhookId,
                })
            );
        });
    }
};

export default WebhookListItemContent;

function conflictInfo() {
    return (
        <span>
            Conflicts occur when two or more patterns have the potential to match the same URL. For
            instance, consider the following two patterns:
            <ul style={{ marginBottom: 1 }}>
                <li>
                    <code>https://site.com/*</code>
                </li>
                <li>
                    <code>https://site.com/path/*</code>
                </li>
            </ul>
            Both of these patterns have the potential to match the URL:{" "}
            <code>https://site.com/path/value</code>.
            <br />
            The REST API will always attempt to match against the most specific patterns to
            determine which authentication to use so these patterns will not necessarily cause a
            problem if both are present, however, it is important to be aware of potential overlap
            in case of unexpected behavior.
        </span>
    );
}
