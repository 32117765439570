import React, { useState, useContext } from "react";
import { PostPayload } from "../../../../functions/Http";
import ResultsTable from "../../../reusable/ResultsTable";
import ResultsTableHeader from "../../../reusable/ResultsTableHeader";
import { Modal } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark, stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { FormatDate } from "../../../../functions/FormatDate";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import EditCloseButtons from "../../../reusable/EditCloseButtons";
import WarningFilters from "./WarningFilters";
import { StyledComponentContext } from "../../../../contexts/StyledComponentsContext";
import ExternalLink from "../../../reusable/ExternalLink";
import { useForm } from "react-hook-form";
import withAuthorizationCheck from "../../../HOC/withAuthorizationCheck";
import { Unauthorized } from "../../../authentication/SigninPrompt";
import { useMutateWarningLogs } from "src/hooks/react-query/developer/useWarningLogs";
const { styled } = StyledComponentContext._currentValue;

/*
 * ===========================
 *      Styled Components
 * ===========================
 */
const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
    border: 1px solid #a5a5a5;
    border-radius: 3px;
    margin-bottom: 5px;

    span[type="line"] {
        span:last-of-type {
            align-self: flex-end;
        }
    }
`;

const StyledTransactionLinkWrapper = styled.div`
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 10px 12px;
    padding-left: 0;

    & div {
        display: inline-block;
        margin-left: 5px;
    }
`;

/*
 * =============================
 *      Result Table Config
 * =============================
 */
const ResultsTableColumns = [
    { tableHeading: "ID", objProperty: "id" },
    { tableHeading: "Process", objProperty: "pname" },
    { tableHeading: "Message", objProperty: "message" },
    {
        tableHeading: "Time Inserted",
        objProperty: "time_inserted",
        valueFormatter: FormatDate,
        style: { whiteSpace: "nowrap" },
    },
    { tableHeading: "Severity", objProperty: "severity" },
];

/*
 * ===================
 *      Component
 * ===================
 */
const FilterWarnings = ({ location, history }) => {
    const { register, handleSubmit } = useForm();
    const { darkMode } = useContext(ThemeContext);
    const cachedFilters = location.state?.cachedFilters || [];

    const [currentWarning, setCurrentWarning] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const { data, error, isLoading, mutate } = useMutateWarningLogs();
    const warnings = data?.warnings;

    // ==============================
    //      Apply Search Filters
    // ==============================
    const search = (values) => {
        const { rangeFrom, rangeTo, limit, ...columnValues } = values;

        /**
         * Lambda currently expects column-specific values to be in an array format.
         * as follows:
         * [
         *     { column: "id", value: "230952" },
         *     { column: "severity", value: "3" },
         *     ...
         * ]
         *
         * This function tranforms the object containing the filter values into
         * the array format that is expected.
         */
        const columnValuePairs = Object.entries(columnValues)
            .map(([column, value]) => (value ? { column, value } : null))
            .filter(Boolean);

        mutate((token) => {
            return PostPayload(token, {
                columnValuePairs,
                rangeFrom,
                rangeTo,
                limit,
            });
        });
    };

    // ===============
    //      Modal
    // ===============
    const handleShowModal = (warning) => {
        setCurrentWarning(warning);
        setShowModal(true);
    };

    const getModal = () => {
        const onHide = () => setShowModal(false);
        const { uuid_trans } = currentWarning;
        const modal = (
            <Modal show={showModal} onHide={onHide} id="acd-modal" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <StyledSyntaxHighlighter
                            wrapLines
                            wrapLongLines
                            showLineNumbers
                            lineProps={{ type: "line" }}
                            language="json"
                            style={darkMode ? a11yDark : stackoverflowLight}>
                            {JSON.stringify(currentWarning, null, 4)}
                        </StyledSyntaxHighlighter>
                        <StyledTransactionLinkWrapper>
                            <b>Transaction:</b>
                            <ExternalLink href={`/transactions?id=${uuid_trans}`}>
                                {uuid_trans}
                            </ExternalLink>
                        </StyledTransactionLinkWrapper>
                    </div>
                    <EditCloseButtons onClose={onHide} hideEdit />
                </Modal.Body>
            </Modal>
        );
        return modal;
    };

    // ================
    //      Render
    // ================
    return (
        <div className="center-content">
            <ResultsTableHeader
                headerText="Warning Logs"
                hideActionButtons={isLoading}
                displayClearButton={warnings || error}
                onSearch={handleSubmit(search)}
                onClear={() => history.push(location.pathname)}
            />
            <WarningFilters register={register} defaultValues={cachedFilters} />
            <ResultsTable
                columns={ResultsTableColumns}
                collection={warnings}
                onClickRow={handleShowModal}
                loading={isLoading}
                error={error}
            />
            {showModal && getModal()}
        </div>
    );
};

export default withAuthorizationCheck(
    ["warn-logs:read"],
    FilterWarnings,
    <Unauthorized subtext="You are not authorized to view warning logs" />
);
