import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { setHtmlDarkTheme } from "../../functions/ColorTheme";
import { Settings, Themes, GetUserSettings } from "../../models/Settings";
import { ThemeContext } from "../../contexts/ThemeContext";
import DualButtonGroup from "./DualButtonGroup";
import { SettingsContext } from "../../contexts/SettingsContext";

/**
 * Setting to control the current and default color theme.
 */
export const ThemeSettings = () => {
    const { settings, updateSettings } = useContext(SettingsContext);
    const storedValue = GetUserSettings().theme === Themes.DARK;
    const [darkMode, setDarkMode] = useState(storedValue);
    const setContextDarkMode = useContext(ThemeContext).setDarkMode;

    const setDefaultDarkMode = (bool) => {
        localStorage.setItem(Settings.THEME, bool ? Themes.DARK : Themes.LIGHT);
        setDarkMode(bool);
        setContextDarkMode(bool);
        setHtmlDarkTheme(bool);
        updateSettings();
    };

    useEffect(() => {
        if (darkMode !== storedValue) setDarkMode(storedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <div className="dual-btn-option">
            <Form.Label style={{ display: "inline-block" }}>Color theme:</Form.Label>
            <DualButtonGroup
                leftActive={!darkMode}
                leftText="Light"
                rightText="Dark"
                leftClick={() => setDefaultDarkMode(false)}
                rightClick={() => setDefaultDarkMode(true)}
            />
        </div>
    );
};
