import React, { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { ErrorMessage } from "src/components/reusable/ReactFormComponents";
import Widget from "src/widgets/Widget";
import { PostbackType } from "../NewAuthSchemaModal";
import { TwoColumnLayout } from "src/components/styled_components/Layouts";
import { useAuthSchemas } from "src/hooks/react-query/accounts/useAuthSchemas";
import { DuplicateAlert } from "./_DuplicateAlert";

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

export function ApiKeyContent(props) {
    const {
        account,
        visible,
        readOnly,
        schema,
        setUnsavedWork,
        setSaveBtnVisible,
        setSaveBtnDisabled,
        disableSaveWhenUnchanged,
        updatePermissions,
        currentPostbackType,
        selectedPostbackType,
    } = props;
    const { apiKeyHeaderName: currentHeader, apiKey: currentApiKey } = schema?.key || {};

    const { register, errors, control } = useFormContext();
    const apiKey = useWatch({ control, name: "key.apiKey" });
    const apiKeyHeaderName = useWatch({ control, name: "key.apiKeyHeaderName" });
    const changesMade = apiKey !== currentApiKey || apiKeyHeaderName !== currentHeader;

    const { detectDuplicate } = useAuthSchemas(account.name);
    const identicalAuth = detectDuplicate({
        id: schema?.id,
        type: PostbackType.KEY,
        key: { apiKey, apiKeyHeaderName },
    });

    const handleChange = () => setUnsavedWork(changesMade);

    useEffect(() => {
        if (!visible) return;
        const fromOtherAuthType = currentPostbackType !== PostbackType.KEY;
        setSaveBtnVisible(fromOtherAuthType || changesMade);
        if (disableSaveWhenUnchanged && !changesMade) return setSaveBtnDisabled(true);
        if (identicalAuth) return setSaveBtnDisabled(true);
        setSaveBtnDisabled(!apiKey || !apiKeyHeaderName || !!errors.key);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiKey, apiKeyHeaderName, currentPostbackType, selectedPostbackType]);

    if (!visible) return null;
    return (
        <div>
            {identicalAuth && DuplicateAlert}
            <TwoColumnLayout>
                <div>
                    <Widget.Input
                        readOnly={readOnly}
                        permissions={updatePermissions}
                        type="text"
                        name="key.apiKeyHeaderName"
                        label="API Key Header Name"
                        value={apiKeyHeaderName}
                        placeholder="Ex: x-api-key"
                        inputAttributes={{ minLength: 1 }}
                        ref={register({
                            ...required,
                            pattern: {
                                value: /^[\w\d_-]{1,}$/,
                                message:
                                    "Must only contain letters, numbers, and hyphens (-) or underscores (_)",
                            },
                        })}
                        style={inputStyle}
                        onChange={handleChange}
                    />
                    <ErrorMessage message={errors.key?.apiKeyHeaderName?.message} />
                </div>
                <div>
                    <Widget.Input
                        readOnly={readOnly}
                        permissions={updatePermissions}
                        type={readOnly ? "password" : "text"}
                        name="key.apiKey"
                        label="API Key"
                        value={apiKey}
                        placeholder="Ex: ugCFgQdUwlLijGotDSm"
                        ref={register({
                            ...required,
                            validate: (value) => {
                                if (value?.length < 8) return "Must be 8 or more characters";
                                if (!/^[A-Za-z0-9!@#$%^&*()[\]?_-]{1,}$/.test(value)) {
                                    return "Must only contain letters, numbers, and the following symbols: !@#$%^&*()[]?_-";
                                }
                            },
                        })}
                        style={inputStyle}
                        onChange={handleChange}
                    />
                    <ErrorMessage message={errors.key?.apiKey?.message} />
                </div>
            </TwoColumnLayout>
            {/* {identicalAuth && <ErrorMessage>Identical authentication detected</ErrorMessage>} */}
        </div>
    );
}
