import React, { useState, useEffect, useRef, createContext } from "react";
import { DropdownDefaults } from "../enums/DropdownValues";
import { CaseSensitivity, Settings, GetUserSettings } from "../models/Settings";
import { getDataOption, parseQueryParams } from "../functions/QueryStrings";
import { QueryParameters } from "../enums/QueryParameters";
import DetectLanguage from "../functions/DetectLanguage";
import { useTransaction } from "src/hooks/react-query/transactions/useTransaction";

export const LeftEditorContext = createContext();

export const LeftEditorProvider = (props) => {
    const { transactionId } = props;
    const getRecordData = (records, dataOption) =>
        records.find(({ name }) => name.startsWith(dataOption))?.data;

    const ref = useRef("left-editor");
    // const [transaction] = useContext(TransactionContext);
    const transaction = useTransaction(transactionId).data;
    const { records } = transaction;
    const dataOptions = records.map(({ name }) => name).reverse();
    const settings = GetUserSettings().leftEditor;
    const queryParams = parseQueryParams(window.location.href.split("?").pop());

    const [caseSensitive, setCaseSensitive] = useState(settings.case === CaseSensitivity.SENSITIVE);
    const [search, setSearch] = useState(settings.search);
    const [dataOption, setDataOption] = useState(
        getDataOption(queryParams, QueryParameters.LEFT_DATA, dataOptions, Settings.LEFT_DATA)
    );
    const [content, setContent] = useState(getRecordData(records, dataOption));
    const [language, setLanguage] = useState(DetectLanguage(content));
    const [fontSize, setFontSize] = useState(settings.fontSize || DropdownDefaults.FONT_SIZE);
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        const newContent = getRecordData(records, dataOption);
        setContent(newContent);
        setLanguage(DetectLanguage(newContent));
    }, [records, dataOption]);

    const value = {
        caseSensitive,
        setCaseSensitive,
        content,
        setContent,
        dataOption,
        setDataOption,
        dataOptions,
        fontSize,
        setFontSize,
        language,
        setLanguage,
        markers,
        ref,
        setMarkers,
        search,
        setSearch,
        searchMatches: search && search.length > 0 ? markers.length : null,
    };

    return <LeftEditorContext.Provider value={value}>{props.children}</LeftEditorContext.Provider>;
};
