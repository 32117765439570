import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

export const ContentHeader = styled.h1`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

    & > i {
        font-size: 0.8em;
        cursor: pointer;
    }
`;
