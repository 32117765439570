import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import * as Colors from "../../../../../widgets/_Colors";
import { StyledComponentContext } from "../../../../../contexts/StyledComponentsContext";
import { TwoColumnLayout } from "../../../../styled_components/Layouts";
import { ThemeContext } from "../../../../../contexts/ThemeContext";
import { FormatDate } from "./NotesWidget";
import EditCloseButtons from "../../../../reusable/EditCloseButtons";
import { useAuth0 } from "@auth0/auth0-react";
import withAuthorizationCheck from "../../../../HOC/withAuthorizationCheck";
import Widget from "../../../../../widgets/Widget";
const { styled } = StyledComponentContext._currentValue;

const FormattedText = styled.p`
    // border: 1px solid ${({ darkMode }) => Colors.ListItemBorder(darkMode)} !important;
    border-radius: 3px;
    font-size: 14px !important;
    margin-bottom: 5px;
    padding: 16px 12px 18px !important;
    white-space: break-spaces;
`;

const NoteWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    row-gap: 10px;
    min-height: 425px;
    margin: 15px 0 0;

    &:nth-child(2) {
        font-size: 13px;
        div {
            margin-bottom: 10px;
        }
    }
`;

const ViewNote = ({ note, onEdit, onClose, onDelete }) => {
    const { darkMode } = useContext(ThemeContext);
    const { user } = useAuth0();
    const isAuthor = note.author === `${user.name}`;

    const DeleteButton = withAuthorizationCheck(
        ["account:note:delete"],
        isAuthor && (
            <Button
                variant="outline-secondary"
                size="sm"
                className="hover-danger"
                onClick={onDelete}>
                Delete
            </Button>
        )
    );

    return (
        <div>
            {/* <h3>{note.note_title}</h3> */}
            <NoteWrapper>
                <FormattedText darkMode={darkMode}>
                    {note.text || "No text to display"}
                </FormattedText>
                <div>
                    <i>Originally created:</i>
                    <div>{`${FormatDate(note.timeInserted)} by ${note.author}`}</div>
                </div>
            </NoteWrapper>
            <Widget.Spacer />
            <TwoColumnLayout>
                <div>
                    <DeleteButton />
                </div>
                <div style={{ display: "grid", justifyItems: "end", columnGap: "10px" }}>
                    <EditCloseButtons onClose={onClose} hideEdit={!isAuthor} onEdit={onEdit} />
                </div>
            </TwoColumnLayout>
        </div>
    );
};

export default ViewNote;
