import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Dropdown, DropdownButton, Form, FormControl, InputGroup } from "react-bootstrap";
import {
    DropdownActiveClass,
    Language,
    LanguageDropdownItems,
    FontSizeDropdownItems,
} from "../../../enums/DropdownValues";
import { resizeEditor } from "../../../functions/ResizeEditor";
import { GetUserSearchPresets } from "../../../models/Settings";
import { parseQueryParams, buildQueryString } from "../../../functions/QueryStrings";
import * as LocalStorage from "../../../functions/LocalStorage";
import { useContext } from "react";
import { SearchPresetsContext } from "../../../contexts/SearchPresetsContext";

const BTN_VARIANT = "outline";

/**
 * Filter to toggle between case sensitive and case-insensitive searching.
 *
 * TODO: Needs to be renamed to CaseFilter
 */
export const RegexFilter = ({ context }) => {
    const { caseSensitive, setCaseSensitive } = context;
    const iClassName = caseSensitive ? "fas fa-bullseye" : "far fa-circle";
    return (
        <DropdownButton
            className="regex-flags"
            as={InputGroup.Prepend}
            variant={BTN_VARIANT}
            title={<i className={iClassName}></i>}
            onSelect={(bool) => {
                setCaseSensitive(bool === "true");
            }}>
            <Dropdown.Item eventKey={false} className={!caseSensitive ? DropdownActiveClass : ""}>
                Case insensitive
            </Dropdown.Item>
            <Dropdown.Item eventKey={true} className={caseSensitive ? DropdownActiveClass : ""}>
                Case sensitive
            </Dropdown.Item>
        </DropdownButton>
    );
};

/**
 * Text input that allows searching through the data in the text editors.
 *
 * The search filter also contains a dropdown that contains, at the very least,
 * a "Clear search" option which removes the current text in the input and, if
 * the user has search presets saved, will also contain the names of the presets
 * and will populate the search filter with the appropriate search when selected.
 */
export const SearchFilter = ({ readOnly, context }) => {
    const { search, setSearch } = context;
    const searchRef = useRef(null);
    const borderClass = search ? "br-none" : "";
    return (
        <React.Fragment>
            <PresetsDropdown context={context} readOnly={readOnly} />
            <Form.Control
                ref={searchRef}
                type="text"
                size="sm"
                className={`search monospace ${borderClass}`}
                placeholder="Search"
                value={search}
                readOnly={readOnly}
                onChange={() => {
                    setSearch(searchRef.current.value);
                }}
            />
            {!readOnly && clearSearchButton()}
        </React.Fragment>
    );

    function clearSearchButton() {
        return !search ? null : (
            <Button
                as={InputGroup.Append}
                variant={BTN_VARIANT}
                title="Clear search"
                className="clear-search hov-blue"
                onClick={() => setSearch("")}
            />
        );
    }
};

/**
 * The data filter changes which data appears in the editor.
 */
export const DataFilter = ({ context, queryParamPrefix }) => {
    const history = useHistory();
    const location = useLocation();
    const { dataOptions, dataOption, setDataOption } = context;
    const [active, setActive] = useState(dataOption);

    useEffect(() => {
        if (dataOption) {
            const queryParams = parseQueryParams(window.location.href.split("?")[1]);
            const queryParamName = `${queryParamPrefix}d`;
            history.replace(
                {
                    search: buildQueryString({
                        ...queryParams,
                        [queryParamName]: dataOptions.indexOf(dataOption),
                    }),
                },
                { ...location.state }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOption]);

    return (
        <DropdownButton
            as={InputGroup.Append}
            variant={BTN_VARIANT}
            title="Data"
            onSelect={(option) => {
                setDataOption(option);
                setActive(option);
            }}>
            {dataOptions.map((option, index) => (
                <Dropdown.Item
                    key={index}
                    className={active === option ? DropdownActiveClass : ""}
                    eventKey={option}>
                    {option}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
};

/**
 * The language filter changes the language of the editor.
 */
export const LanguageFilter = ({ context }) => {
    const { language, setLanguage } = context;
    return (
        <DropdownButton
            as={InputGroup.Append}
            variant={BTN_VARIANT}
            title={language}
            onSelect={(lang) => {
                setLanguage(lang);
                resizeEditor();
            }}>
            <LanguageDropdownItems currentLang={language} />
        </DropdownButton>
    );
};

/**
 * The font size filter changes the font size of the editor.
 */
export const FontSizeFilter = ({ context }) => {
    const { fontSize, setFontSize } = context;

    const DefaultOption = () => {
        return fontSize === "14" ? null : (
            <React.Fragment>
                <Dropdown.Item eventKey="14">Default</Dropdown.Item>
                <Dropdown.Divider />
            </React.Fragment>
        );
    };

    return (
        <DropdownButton
            alignRight
            as={InputGroup.Append}
            variant={BTN_VARIANT}
            title={`${fontSize} px`}
            onSelect={(size) => {
                setFontSize(size);
            }}>
            <DefaultOption />
            <FontSizeDropdownItems currentSize={fontSize} />
        </DropdownButton>
    );
};

/**
 * These appear below the editor whenever a language
 * other than plain text is selected to give the user
 * the ability to expand and collapse the code folds.
 */
export const CodeFoldOptions = ({ context }) => {
    const { language, ref } = context;
    return language !== Language.PLAIN_TEXT ? (
        <small className="code-fold-buttons">
            <span>(&nbsp;</span>
            <span
                className="ptr hov-blue"
                onClick={() => {
                    ref.current.editor.getSession().foldAll();
                }}>
                Collapse all
            </span>
            <span>&nbsp;|&nbsp;</span>
            <span
                className="ptr hov-blue"
                onClick={() => {
                    ref.current.editor.getSession().unfold();
                }}>
                Expand all
            </span>
            <span>&nbsp;)</span>
        </small>
    ) : null;
};

/**
 * Iterates through markers by line number.
 */
export const MatchIterator = ({ context }) => {
    const { markers, ref } = context;
    let prevIndex = 0;
    let nextIndex = 0;
    const editor = ref.current.editor;
    const uniqueLines = [...new Set(markers.map((marker) => marker.startRow))];
    const getIndexes = () => {
        const currLine = editor.getSelectionRange().start.row;
        const lastUniqueLine = [...uniqueLines].pop();
        if (currLine < uniqueLines[0] || currLine > lastUniqueLine) {
            nextIndex = 0;
            prevIndex = uniqueLines.length - 1;
        } else {
            uniqueLines.forEach((lineNum, index) => {
                if (lineNum === currLine) {
                    const lastIndex = uniqueLines.length - 1;
                    const indexAtStart = index === 0;
                    const indexAtEnd = index === lastIndex;
                    prevIndex = indexAtStart ? uniqueLines.length - 1 : index - 1;
                    nextIndex = indexAtEnd ? 0 : index + 1;
                } else if (currLine > lineNum) {
                    prevIndex = index;
                    nextIndex = ++index;
                }
            });
        }
    };
    const scroll = (lineNum) => {
        editor.scrollToLine(lineNum + 1, true, true, () => {});
        editor.gotoLine(lineNum + 1, 0, true);
    };
    const next = () => {
        getIndexes();
        scroll(uniqueLines[nextIndex]);
    };
    const prev = () => {
        getIndexes();
        scroll(uniqueLines[prevIndex]);
    };
    return markers.length !== 0 ? (
        <small className="match-iterator-buttons">
            <span className="caret-up hov-blue ptr" onClick={prev}></span>
            <span className="caret-down hov-blue ptr" onClick={next}></span>
        </small>
    ) : null;
};

/**
 * Whenever there is a search present in the search filter,
 * this appears underneath the search filter to display how
 * many matches were returned from the search.
 */
export const MatchCount = ({ context }) => {
    const { searchMatches } = context;
    const plural = searchMatches !== 1;
    if (searchMatches !== null) {
        return searchMatches === 0 ? (
            <small className="no-results mr-3">No results</small>
        ) : (
            <small className="matches">{`${
                searchMatches + (plural ? " matches" : " match")
            }`}</small>
        );
    }
    return null;
};

/*
 * ======================
 *      NON-EXPORTED
 * ======================
 */
function PresetsDropdown({ readOnly, context }) {
    const [presets, setPresets] = useContext(SearchPresetsContext);
    const { search, setSearch } = context;

    return (search || presets.length > 0) && !readOnly ? (
        <DropdownButton
            as={InputGroup.Prepend}
            variant={BTN_VARIANT}
            title=""
            className="presets-dropdown"
            onClick={() => setPresets(GetUserSearchPresets())}
            onSelect={(search) => {
                setSearch(search);
            }}>
            <QuickSaveOption search={search} presets={presets} setPresets={setPresets} />
            {presets.map((preset, index) => (
                <Dropdown.Item
                    key={index}
                    className={preset.search === search ? DropdownActiveClass : ""}
                    eventKey={preset.search}>
                    {preset.name}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    ) : null;
}

function QuickSaveOption({ search, presets, setPresets }) {
    const formRef = useRef("form");
    const inputRef = useRef("input");
    const newSearch = LocalStorage.isNewSearch(search, presets);
    const [searchName, setSearchName] = useState("");

    const quicksave = (e) => {
        e.preventDefault();
        LocalStorage.isValidName(searchName, presets) &&
            (() => {
                LocalStorage.add(searchName, search);
                setSearchName("");
                setPresets(GetUserSearchPresets());
            })();
    };

    return !search || !newSearch ? null : (
        <React.Fragment>
            <Form ref={formRef} className="quicksave-form" onSubmit={quicksave}>
                <FormControl
                    autoFocus
                    ref={inputRef}
                    type="text"
                    className="search-quicksave"
                    placeholder="Save as..."
                    value={searchName}
                    onChange={() => setSearchName(inputRef.current.value)}
                    isInvalid={searchName && !LocalStorage.isValidName(searchName, presets)}
                />
                <Form.Control.Feedback type="invalid">Name already in use</Form.Control.Feedback>
                {presets.length > 0 ? <Dropdown.Divider /> : null}
            </Form>
        </React.Fragment>
    );
}
