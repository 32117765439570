import React, { useState, createContext } from "react";
import { GetUserSettings, Themes } from "../models/Settings";
import $ from "jquery";

export const ThemeContext = createContext();

export const ThemeProvider = (props) => {
    const [darkMode, setDarkMode] = useState(GetUserSettings().theme === Themes.DARK);
    const color = darkMode ? "light" : "secondary";
    const outlineVariant = `outline-${color}`;

    return (
        <ThemeContext.Provider value={{ darkMode, setDarkMode, color, outlineVariant }}>
            {props.children}
        </ThemeContext.Provider>
    );
};

export const setHtmlDarkTheme = (bool) => {
    $("html").attr("theme", bool ? "dark" : "light");
};

export const isDarkTheme = () => {
    return $("html").attr("theme") === "dark";
};

export const lightDarkOutline = () => {
    return isDarkTheme() ? "outline-light" : "outline-secondary";
};

export const lightDark = () => {
    return isDarkTheme() ? "light" : "secondary";
};

export const editorTheme = () => {
    return isDarkTheme() ? "idle_fingers" : "crimson_editor";
};
