import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { AreYouSureAlert, CustomReactAlert } from "src/components/reusable/Alerts";
import { ErrorAlert, SuccessAlert } from "src/components/reusable/Alerts";
import { ErrorMessage } from "src/components/reusable/ReactFormComponents";
import { generateRandomString } from "src/functions/RandomString";
import { PostPayload } from "src/functions/Http";
import SaveCancelButtons from "src/components/reusable/SaveCancelButtons";
import UsernamePasswordDisplay from "./UsernamePasswordDisplay";
import Widget from "src/widgets/Widget";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const BCrypt = require("bcryptjs");

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const NewCredentialModal = ({ account, showModal, setShowModal, onSave, permissions }) => {
    const { baseUrl } = useCurrentEnvironment();
    const CREDS_URL = `${baseUrl}/accounts/${account.name}/rest-credentials`;
    const nicknameExample = `${account.companyName} ${DateTime.now().toISODate()}`;
    const oneYearFromNow = DateTime.now().plus({ years: 1 }).toISODate();

    const { getAccessTokenSilently } = useAuth0();
    const defaultValues = { nickname: nicknameExample, expiresOn: oneYearFromNow };
    const { register, errors, handleSubmit, control, reset } = useForm({
        mode: "onChange",
        defaultValues,
    });
    const nickname = useWatch({ control, name: "nickname" });
    const expiresOn = useWatch({ control, name: "expiresOn" });
    const changesMade =
        nickname !== defaultValues.nickname || expiresOn !== defaultValues.expiresOn;

    const onCancel = () => {
        const cancel = () => {
            reset(defaultValues);
            setShowModal(false);
        };
        if (!changesMade) return cancel();
        AreYouSureAlert().then(({ isConfirmed }) => isConfirmed && cancel());
    };

    return (
        <Modal
            size="lg"
            show={showModal}
            onHide={() => setShowModal(false)}
            id="new-cred-modal"
            backdrop="static"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>New Credential</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Widget>
                    {/* <Widget */}
                    <Widget.Input
                        type="text"
                        name="nickname"
                        label="Description"
                        placeholder={nicknameExample}
                        value={nickname}
                        required={true}
                        ref={register({
                            ...required,
                            validate: (value) =>
                                !Array([{ username: "username" }])
                                    .map((a) => a.username)
                                    .includes(value) || "Name already in use",
                        })}
                        style={inputStyle}
                    />
                    <ErrorMessage message={errors.nickname?.message} />
                    <Widget.Input
                        type="text"
                        name="expiresOn"
                        label="Expires on"
                        required={true}
                        ref={register({ ...required, validate: validateDateInput })}
                        style={{ ...inputStyle, cursor: "default" }}
                        value={oneYearFromNow}
                    />
                    <ErrorMessage message={errors.expiresOn?.message} />
                </Widget>
                <SaveCancelButtons
                    visible={true}
                    onSave={handleSubmit(handleSave(getAccessTokenSilently))}
                    onCancel={onCancel}
                    permissions={permissions}
                    saveDisabled={!nickname || errors.expiresOn}
                />
            </Modal.Body>
        </Modal>
    );

    /*
     * ===================
     *      Functions
     * ===================
     */

    /**
     * Function used to check if date is acceptable.
     *
     * If the date is in the incorrect format, this will return
     * an error. Otherwise, if the date is today or in the past,
     * it will return an error, as well as if the date is too
     * far into the future (over a year).
     */
    function validateDateInput(input) {
        const isoRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (!isoRegex.test(input)) return "Must be in format YYYY-MM-DD";

        const date = DateTime.fromISO(input);
        const diffDays = Math.ceil(date.diffNow("days").days);
        if (diffDays > 366) return "Must not exceed one year";
        if (diffDays < 0) return "Must not be in the past";
        if (diffDays < 1) return "Must be after today";
    }

    /**
     * This handles both saving and updating a note. This function
     * returns another function that actually handles the saving that
     * must be passed the title and text of the new or current note.
     *
     * If it is a new note that is being added, the noteId should be
     * null, otherwise, the noteId being passed in will be updated with
     * the title and text provided.
     *
     * @param {Number} noteId the id of the note being saved
     */
    function handleSave(getAccessTokenSilently) {
        const USERNAME_CHARS = "distinguishable";
        const PASSWORD_CHARS = "alphanumeric";
        const HASH_ROUNDS = 13;

        return async (values) => {
            setShowModal(false);
            Swal.fire({
                title: "Generating...",
                showConfirmButton: false,
                willOpen: () => Swal.showLoading(),
            });

            const username = generateRandomString(USERNAME_CHARS, 20);
            const password = generateRandomString(PASSWORD_CHARS, 50);
            const hash = await BCrypt.hash(password, HASH_ROUNDS);

            getAccessTokenSilently().then(async (token) => {
                const payload = PostPayload(token, {
                    accountId: account.id,
                    nickname: values.nickname,
                    expiresOn: values.expiresOn,
                    username,
                    hash,
                });
                // new Promise((resolve) => setTimeout(() => resolve(true), 100))
                fetch(CREDS_URL, payload)
                    .then((res) => {
                        const json = res.json();
                        if (res.status !== 200) throw new Error(json);
                        return json;
                    })
                    .then(() => {
                        SuccessAlert()
                            .then(() => onSave && onSave())
                            .then(() => CredentialsAlert(username, password));
                    })
                    .catch((err) => {
                        console.log("Error creating new credential", err);
                        ErrorAlert();
                    });
            });
        };
    }
};

export default NewCredentialModal;

function CredentialsAlert(username, password) {
    CustomReactAlert({
        title: "Generated Credentials",
        icon: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: "Got 'em!",
        html: <UsernamePasswordDisplay username={username} password={password} />,
        preConfirm: () => {
            AreYouSureAlert({
                text: "If you lose the username and password, you will be unable to retrieve them again.",
            }).then(({ isConfirmed }) => {
                return !isConfirmed && CredentialsAlert(username, password);
            });
        },
    });
}
