import { Language } from "../enums/DropdownValues";

/**
 * Attempts to determine if the data is JSON or XML
 * and will return the language type if detected or
 * plain text if not.
 *
 * @param {*} content the content to determine the
 * language type for
 */
const DetectLanguage = (content) => {
    const stringContent = content && String(content);
    if (!stringContent) return Language.PLAIN_TEXT;
    if (stringContent.startsWith("{")) return Language.types.JSON;
    if (stringContent.startsWith("[")) return Language.types.JSON;
    if (stringContent.includes("xmlns")) return Language.types.XML;
    if (stringContent.includes("html")) return Language.types.HTML;
    return Language.PLAIN_TEXT;
};

export default DetectLanguage;
