const StandardHeaders = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
};

/**
 * Helper function to setup get requests with fetch api.
 *
 * @param {*} token the current Auth0 token
 */
export const GetPayload = (token) => ({
    method: "GET",
    mode: "cors",
    headers: {
        Authorization: `Bearer ${token}`,
        ...StandardHeaders,
    },
});

/**
 * Helper function to setup post requests with fetch api.
 *
 * @param {*} token the current Auth0 token
 * @param {*} payload the request payload
 */
export const PostPayload = (token, payload) => ({
    method: "POST",
    mode: "cors",
    headers: {
        Authorization: `Bearer ${token}`,
        ...StandardHeaders,
    },
    body: JSON.stringify(payload),
});

/**
 * Helper function to setup put requests with fetch api.
 *
 * @param {*} token the current Auth0 token
 * @param {*} payload the request payload
 */
export const PutPayload = (token, payload) => ({
    method: "PUT",
    mode: "cors",
    headers: {
        Authorization: `Bearer ${token}`,
        ...StandardHeaders,
    },
    body: JSON.stringify(payload),
});

/**
 * Helper function to setup delete requests with fetch api.
 *
 * @param {*} token the current Auth0 token
 * @param {*} payload the request payload
 */
export const DeletePayload = (token, payload) => ({
    method: "DELETE",
    mode: "cors",
    headers: {
        Authorization: `Bearer ${token}`,
        ...StandardHeaders,
    },
    body: JSON.stringify(payload),
});
