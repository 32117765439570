import React, { useState, useContext, useEffect } from "react";
import { PostPayload } from "src/functions/Http";
import Widget from "src/widgets/Widget";
import SaveCancelButtons from "src/components/reusable/SaveCancelButtons";
import { SuccessAlert, ErrorAlert, AreYouSureAlert } from "src/components/reusable/Alerts";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
import { ThemeContext } from "src/contexts/ThemeContext";
import {
    useCredentialGroups,
    useMutateCredentialGroups,
} from "src/hooks/react-query/accounts/useCredentialGroups";

export default function CredentialGroups(props) {
    const { account, permissions } = props;
    const { darkMode } = useContext(ThemeContext);

    const { data, error, isLoading, refetch } = useCredentialGroups(account.name);
    const updateCredentialGroups = useMutateCredentialGroups({
        accountName: account.name,
        onSuccess: () => refetch().then(SuccessAlert),
        onError: () => ErrorAlert(),
    });

    const groups = data?.groups;
    const [groupsCopy, setGroupsCopy] = useState(JSON.parse(JSON.stringify(groups ?? [])));
    const changedGroups = groupsCopy?.filter((grp, idx) => {
        return Boolean(grp.enabled) !== Boolean(groups?.[idx].enabled);
    });

    useEffect(() => setGroupsCopy(data?.groups || []), [data]);

    return (
        <div>
            <Widget
                header={<Widget.Header info={headerInfo()}>Permissions</Widget.Header>}
                loading={isLoading}>
                {error ? (
                    <span>ERROR: Could not fetch credential groups</span>
                ) : (
                    <WidgetListItems
                        darkMode={darkMode}
                        groups={groupsCopy}
                        isChecked={isChecked}
                        isChanged={isChanged}
                        handleToggle={handleToggle}
                        permissions={permissions}
                    />
                )}
            </Widget>
            {!error && (
                <>
                    <NumberOfChanges />
                    <SaveCancelButtons
                        visible={!!changedGroups.length}
                        onSave={() => handleSave()}
                        onCancel={() => handleCancel()}
                        style={{ margin: "-10px 0 25px" }}
                        showSpinner={updateCredentialGroups.isLoading}
                        permissions={permissions}
                    />
                </>
            )}
        </div>
    );
    /*
     * ===================
     *      Functions
     * ===================
     */

    /**
     * If changes have been made, displays how many changes have taken place.
     */
    function NumberOfChanges() {
        const changes = changedGroups.length;
        return changes ? (
            <div style={{ float: "left", marginTop: "-15px" }}>
                {`${changes} ${changes === 1 ? "change" : "changes"}`}
            </div>
        ) : null;
    }

    /**
     * Determines whether or not to show the item in the list as "checked"
     * based on the "authorized" value. If the authorization's index is found in
     * the "changed" list, then the opposite of the actual "checked" value will
     * be displayed.
     *
     * @param {number} index the index of the auth in the "authList" list
     */
    function isChecked(groupName) {
        return groupsCopy.find((o) => o.groupName === groupName).enabled;
    }

    /**
     * Determines whether or not the user has changed this value in order
     * to indicate that on screen.
     *
     * @param {Number} groupName the "name" of the group
     */
    function isChanged(groupName) {
        const db_auth = groups.find((o) => o.groupName === groupName).enabled;
        const local_auth = groupsCopy.find((o) => o.groupName === groupName).enabled;
        return Boolean(db_auth) !== Boolean(local_auth);
    }

    /**
     * Updates the authsCopy list to reflect the changes the user is making on a
     * per-item basis (i.e. only handles one authorization being toggled at a time).
     *
     * @param {number} groupName the "name" of the group
     */
    function handleToggle(groupName) {
        const newGroups = JSON.parse(JSON.stringify(groupsCopy));
        const changedGroup = newGroups.find((g) => g.groupName === groupName);
        changedGroup.enabled = !changedGroup.enabled;
        setGroupsCopy(newGroups);
    }

    /**
     * Reviews changes being made and prompts user to confirm actions.
     *
     * If user confirms, saves changes to the database.
     */
    function handleSave() {
        const review = (
            <div className="review">
                {changedGroups.map((group, idx) => {
                    const action = group.enabled ? "Enable" : "Disable";
                    const className = action.toLowerCase();
                    return (
                        <div key={idx}>
                            <span className={className}>{action}</span>
                            <span>&nbsp;{group.groupName}</span>
                        </div>
                    );
                })}
            </div>
        );
        AreYouSureAlert({ html: review }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            updateCredentialGroups.mutate((token) => PostPayload(token, { groups: changedGroups }));
        });
    }

    /**
     * Cancels out any changes that have been made.
     */
    async function handleCancel() {
        const warning = "All changes for this section will be reverted.";
        AreYouSureAlert({ text: warning }).then(({ isConfirmed }) => {
            isConfirmed && setGroupsCopy(JSON.parse(JSON.stringify(groups)));
        });
    }
}

/**
 * NOTE: This is its own component strictly for the reason that React
 * will pitch a fit if it isn't. Whenever this component is created
 * inside of the main one, React will call the hooks inconsistently
 * and log an error in the console.
 */
function WidgetListItems(props) {
    const { darkMode, groups, isChecked, isChanged, handleToggle, permissions } = props;

    return groups.map((group, idx) => {
        const { groupName } = group;
        const listItemLabel = (
            <span>
                {groupName}
                <small style={{ color: darkMode ? "#aaa" : "#8f8f8f", marginLeft: 8 }}>
                    {group.description}
                </small>
            </span>
        );
        const listItem = (disabled) => (
            <Widget.ToggleableListItem
                key={idx}
                label={listItemLabel}
                checked={isChecked(groupName)}
                changed={isChanged(groupName)}
                {...(disabled
                    ? { onChange: () => {} }
                    : { onChange: () => handleToggle(groupName) })}
                disabled={disabled}
            />
        );
        return withAuthorizationCheck(permissions, listItem(false), listItem(true))();
    });
}

/**
 * Information to display beside the header for this widget when
 * hovering over the information icon.
 */
function headerInfo() {
    return (
        <span>
            These permissions determine whether or not the customer will have access to these
            products through the REST API. When requests to the REST API are made, they all go an
            authorizer that checks to see if the sender is supposed have access to the resource. If
            the permission is not given, requests to endpoints related to these products will be
            denied.
            <hr />
            <b>Note:</b>&nbsp;if only the API group is granted, the customer will be able to
            retrieve an access token with their credentials and ensure they can authenticate
            successfully by sending a request to the <code>/healthcheck</code> endpoint without
            having access to an actual product.
        </span>
    );
}
