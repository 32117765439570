import React from "react";
import Widget from "../../../widgets/Widget";
import { GridLayout } from "../../styled_components/Layouts";
import { ACD_Tables, GlobalColumnLabels } from "./TableSchema";
import { StyledComponentContext } from "../../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

const TABLES = Object.entries(ACD_Tables).map(([key, { label, table }]) => ({ key, label, table }));

/*
 * ===========================
 *      Styled Components
 * ===========================
 */
const Label = styled.label`
    font-size: 12px;
    margin-bottom: 12px;
    margin-left: 2px;
`;

const RowLayout = styled.div`
    display: grid;
    grid-auto-flow: row;
    row-gap: ${({ rowGap }) => rowGap || "10px"};
`;

/*
 * ========================
 *      Main Component
 * ========================
 */
const ACDFilters = (props) => {
    const { currentTable, refs, handleTableChange, filters } = props;
    const { columns } = currentTable;

    const inputs = columns.map((column, idx) => {
        const currentValue = filters[column.column];
        const isInt = Number.isInteger(Number.parseInt(currentValue));
        const hasValue = isInt || currentValue;

        return (
            <Widget.Input
                key={idx}
                ref={refs[idx]}
                type={column.column === "usedcount" || column.column === "id" ? "number" : " text"}
                placeholder={column.label}
                value={hasValue ? String(currentValue) : ""}
                inputAttributes={{ ...column.inputAttributes }}
            />
        );
    });

    return (
        <div>
            <Widget>
                <div>
                    <Widget.Select
                        label="Category"
                        options={[...TABLES]}
                        optionValueProp={"key"}
                        optionLabelProp={"label"}
                        noValueOption={false}
                        defaultValue={TABLES.find(({ table }) => table === currentTable.table)}
                        onSelect={(key) => handleTableChange(ACD_Tables[key])}
                    />
                    <hr style={{ marginBottom: "10px" }} />
                    <RowLayout rowGap="10px">
                        <GridLayout columns="3" alignItems="start">
                            <SharedColumnInputs columns={columns} inputs={inputs} />
                            <MappedColumnInputs
                                columns={columns}
                                inputs={inputs}
                                currentTable={currentTable}
                            />
                            <StateColumnInputs
                                columns={columns}
                                inputs={inputs}
                                currentTable={currentTable}
                            />
                        </GridLayout>
                    </RowLayout>
                </div>
            </Widget>
        </div>
    );
};

export default ACDFilters;

/*
 * =======================
 *      Subcomponents
 * =======================
 */

const GlobalColumnsFilter = ({ label }) => GlobalColumnLabels.includes(label);
const MappedColumnsFilter = ({ label }) => /^mapped/i.test(label);
const StateColumnsFilter = ({ label }) =>
    !GlobalColumnsFilter({ label }) && !MappedColumnsFilter({ label });

/**
 * Creates the layout for a grouping of filters (shared, mapped, or state).
 *
 * @param {Array} param.columns the columns associated with this group of filters
 * @param {Array} param.inputs the full list of inputs mapped in the main function
 * @param {String} param.label the label for this group of filters
 */
const FilterGroup = ({ columns, inputs, label }) => {
    const mapColumnToInputFunc = (c) => {
        return inputs[inputs.findIndex((val) => val.props.placeholder === c.label)];
    };
    return columns.length ? (
        <div>
            <Label>{label}:</Label>
            <RowLayout>{columns.map(mapColumnToInputFunc)}</RowLayout>
        </div>
    ) : null;
};

/**
 * Inputs for columns that are shared among all ACD codes.
 */
const SharedColumnInputs = React.memo(({ columns, inputs }) => (
    <FilterGroup
        columns={columns.filter(GlobalColumnsFilter)}
        inputs={inputs}
        label="Global ACD Values"
    />
));

/**
 * Inputs for "mapped" values that we have assigned to ACD codes.
 */
const MappedColumnInputs = React.memo(({ columns, inputs, currentTable }) => {
    // const tableLabel = ACD_Tables[currentTable].label;
    const tableLabel = currentTable.label;
    return (
        <FilterGroup
            columns={columns.filter(MappedColumnsFilter)}
            inputs={inputs}
            label={`Mapped ${tableLabel} Values`}
        />
    );
});

/**
 * Inputs for the values that are provided by the state.
 */
const StateColumnInputs = React.memo(({ columns, inputs, currentTable }) => {
    // const tableLabel = ACD_Tables[currentTable].label;
    const tableLabel = currentTable.label;
    return (
        <FilterGroup
            columns={columns.filter(StateColumnsFilter)}
            inputs={inputs}
            label={`State ${tableLabel} Values`}
        />
    );
});

/*
 * ==================================
 *      Unused, In-Progress Code
 * ==================================
 */
// code for dropdown modifier on input
// (for adding things like <, >, = to the "times used" filter)
/*
const TimesUsedInput = () => (
        <React.Fragment>
            <DropdownButton
                className="regex-flags"
                size="sm"
                style={{
                    marginBottom: "5px",
                    border: "1px solid #5e5e5e",
                    borderRight: "none",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                }}
                as={InputGroup.Prepend}
                // variant={BTN_VARIANT}
                // title={<i className={iClassName}></i>}
                title={<span>&lt;</span>}
                onSelect={(bool) => {
                    // setCaseSensitive(bool === "true");
                }}>
                <Dropdown.Item eventKey={false} className={""}>
                    Case insensitive
                </Dropdown.Item>
                <Dropdown.Item eventKey={true} className={""}>
                    Case sensitive
                </Dropdown.Item>
            </DropdownButton>
            <Form.Control
                // ref={React.createRef()}
                // as={
                //     <Widget.Input
                //         // key={idx}
                //         // ref={refs[idx]}
                //         type="text"
                //         // placeholder={column.label}
                //         // value={refs[idx]?.current?.value || ""}
                //         // onChange={applyFilters}
                //     />
                // }
                as={Widget.Input}
                type="text"
                size="sm"
                className={`search monospace`}
                placeholder="Search"
                style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                // value={search}
                // readOnly={readOnly}
                onChange={() => {
                    // setSearch(searchRef.current.value);
                }}
            />
        </React.Fragment>
    );
    */
