import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { lightDarkOutline } from "../../functions/ColorTheme";

/**
 * The component for adding new search presets from the settings page.
 *
 * @param {Object} props props
 * @param {Object} props.names list of all the names of the currently stored presets
 * @param {Object} props.onAdd function to call when adding a new preset
 * @param {Object} props.invalidFeedback invalid feedback element to display if input is invalid
 */
export const NewSearchPreset = ({ names, onAdd, invalidFeedback }) => {
    const [newClicked, setNewClicked] = useState(false);
    const [name, setName] = useState("");
    const [search, setSearch] = useState("");
    const nameAlreadyTaken = names.includes(name);

    const reset = () => {
        setName("");
        setSearch("");
        setNewClicked(false);
    };

    const cancel = () => {
        const edited = name.length > 0 || search.length > 0;
        return edited ? window.confirm("Are you sure?") && reset() : reset();
    };

    const AddAndCancelButtons = () => {
        const disabled = name.length === 0 || search.length === 0;
        const addVariant = disabled || nameAlreadyTaken ? "secondary" : "success";
        return (
            <div className="actions">
                <Button
                    className="add-btn"
                    pill="true"
                    variant={`outline-${addVariant}`}
                    size="sm"
                    disabled={disabled || nameAlreadyTaken}
                    onClick={() => {
                        onAdd(name, search);
                        reset();
                    }}>
                    Add
                </Button>
                <Button
                    className="cancel"
                    pill="true"
                    variant={lightDarkOutline()}
                    size="sm"
                    onClick={() => cancel()}>
                    Cancel
                </Button>
            </div>
        );
    };

    const NewButton = () => {
        return (
            <div className="actions">
                <Button
                    className="new-btn"
                    pill="true"
                    variant="outline-success"
                    size="sm"
                    onClick={() => setNewClicked(true)}>
                    New
                </Button>
            </div>
        );
    };

    return newClicked ? (
        <div className="preset">
            <div>
                <Form.Label>Name</Form.Label>
                <Form.Control
                    size="sm"
                    type="text"
                    className="monospace outline-dark"
                    value={name}
                    placeholder="Name"
                    isInvalid={nameAlreadyTaken}
                    onChange={(e) => setName(e.target.value)}
                />
                {invalidFeedback}
            </div>
            <div>
                <Form.Label>Search Regex</Form.Label>
                <Form.Control
                    size="sm"
                    type="text"
                    className="monospace outline-dark"
                    value={search}
                    placeholder="Regex"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <AddAndCancelButtons />
        </div>
    ) : (
        <NewButton />
    );
};
