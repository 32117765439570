import React, { useState, useContext } from "react";
import Widget from "src/widgets/Widget";
import { useForm, FormProvider } from "react-hook-form";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { ApiKeyContent } from "./form-inputs/ApiKey";
import { BasicAuthContent } from "./form-inputs/Basic";
import { BearerAuthContent } from "./form-inputs/Bearer";
import SaveCancelButtons from "src/components/reusable/SaveCancelButtons";
import { AreYouSureAlert, UnsavedChangesAlert } from "src/components/reusable/Alerts";
import { ThemeContext } from "src/contexts/ThemeContext";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
import { useMutateAuthSchemas } from "src/hooks/react-query/accounts/useAuthSchemas";
import { ErrorAlert } from "src/components/reusable/Alerts";
import { SuccessAlert } from "src/components/reusable/Alerts";
import { PostPayload } from "src/functions/Http";
const { styled } = StyledComponentContext._currentValue;

const WebhookAuthHeaderWrapper = styled.div`
    margin-bottom: 15px;

    h5 {
        margin: 0;
        margin-top: -5px;
    }

    & button.dropdown-toggle::after {
        margin-left: 8px;
    }
`;

export const PostbackType = {
    KEY: "KEY",
    BASIC: "BASIC",
    BEARER: "BEARER",
};

const selectOptions = [
    { label: "API key", formValue: PostbackType.KEY },
    { label: "Basic auth", formValue: PostbackType.BASIC },
    { label: "Bearer token", formValue: PostbackType.BEARER },
];

export default function NewAuthSchemaModal(props) {
    const { account, showModal, setShowModal, refresh, permissions } = props;
    const { darkMode } = useContext(ThemeContext);

    const formMethods = useForm({ mode: "onChange", defaultValues: {} });
    const { register, handleSubmit, reset } = formMethods;

    const defaultPostbackType = PostbackType.KEY;
    const [postbackType, setPostbackType] = useState(defaultPostbackType);
    const [unsavedWork, setUnsavedWork] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const btnVariant = darkMode ? "light" : "secondary";

    const addSchema = useMutateAuthSchemas({
        accountName: account.name,
        onSuccess: () =>
            SuccessAlert()
                .then(() => setShowModal(false))
                .then(refresh)
                .then(() => {
                    setSaveBtnDisabled(true);
                    setUnsavedWork(false);
                    reset();
                }),
        onError: () => ErrorAlert(),
    });

    const Content = {
        [PostbackType.KEY]: ApiKeyContent,
        [PostbackType.BASIC]: BasicAuthContent,
        [PostbackType.BEARER]: BearerAuthContent,
    }[postbackType];

    const TypeDropdown = (
        <WebhookAuthHeaderWrapper>
            <Widget.Header
                info={headerInfo(postbackType)}
                button={withAuthorizationCheck(
                    permissions,
                    <DropdownButton
                        size="sm"
                        onSelect={(value) => {
                            return unsavedWork
                                ? UnsavedChangesAlert().then(({ isConfirmed }) => {
                                      if (!isConfirmed) return;
                                      setPostbackType(value);
                                      setUnsavedWork(false);
                                  })
                                : setPostbackType(value);
                        }}
                        variant={btnVariant}
                        title={(() => {
                            const option = selectOptions.find((o) => {
                                return o.formValue === postbackType;
                            });
                            return option?.label;
                        })()}>
                        {selectOptions.map((option, idx) => (
                            <Dropdown.Item
                                key={idx}
                                eventKey={option.formValue}
                                style={
                                    postbackType === option.formValue
                                        ? { color: "#73a9cf" }
                                        : undefined
                                }>
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                )()}>
                Type:
            </Widget.Header>
        </WebhookAuthHeaderWrapper>
    );

    return (
        <Modal
            size="lg"
            show={showModal}
            onHide={onCancel}
            id="new-auth-modal"
            backdrop="static"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>New Authentication Schema</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Widget>
                    <FormProvider {...formMethods}>
                        <input
                            type="hidden"
                            name="authType"
                            value={postbackType}
                            ref={register()}
                        />
                        {TypeDropdown}
                        <Content
                            visible
                            account={account}
                            updatePermissions={permissions}
                            selectedPostbackType={postbackType}
                            setUnsavedWork={setUnsavedWork}
                            setSaveBtnVisible={() => {}}
                            setSaveBtnDisabled={setSaveBtnDisabled}
                        />
                    </FormProvider>
                </Widget>
                {withAuthorizationCheck(
                    permissions,
                    <SaveCancelButtons
                        visible={true}
                        onSave={handleSubmit(onSave)}
                        onCancel={onCancel}
                        style={{ marginTop: "15px" }}
                        showSpinner={false}
                        saveDisabled={saveBtnDisabled}
                    />
                )()}
            </Modal.Body>
        </Modal>
    );

    /*
     * ===================
     *      Functions
     * ===================
     */

    function onSave(values) {
        addSchema.mutate((token) => PostPayload(token, values));
    }

    function onCancel() {
        const cancel = () => {
            reset({});
            setPostbackType(defaultPostbackType);
            setUnsavedWork(false);
            setSaveBtnDisabled(true);
            setShowModal(false);
        };
        if (!unsavedWork) return cancel();
        const confirmText = "Unsaved changes will be lost.";
        AreYouSureAlert({ text: confirmText }).then(({ isConfirmed }) => isConfirmed && cancel());
    }

    function headerInfo(postbackType) {
        return {
            [PostbackType.KEY]: (
                <span>
                    API Key authentication is one of the most simple forms of authentication that
                    can be used. The customer will specify a static value (the API key) and then
                    tell us the name of the header to send the key in (the API key header name).
                </span>
            ),
            [PostbackType.BASIC]: (
                <span>
                    Basic auth is one of the most common forms of authentication used on the web.
                    This consists of a traditional username and password combination that will be
                    sent in the <code>Authorization</code> header. Basic authentication is sent as{" "}
                    <code>Basic &lt;base64(username:password)&gt;</code>.
                    <hr />
                    <b>Note:</b>&nbsp;base64 encoding is not encryption, therefore, there is no key
                    needed to decode the value.
                </span>
            ),
            [PostbackType.BEARER]: (
                <span>
                    Bearer token authentication is a very common form of authentication that
                    requires authenticating to a separate URL with basic auth and then using a token
                    returned from that URL to authenticate to the final webhook. This is what the
                    REST API uses for access to its various endpoints. The <code>username</code> and{" "}
                    <code>password</code> are used for the basic auth that will be sent in the{" "}
                    <code>Authorization</code> header sent to the token endpoint using the HTTP
                    method specified. The token parameter name is used if the response contains a
                    JSON object with a token property rather than the entire response being a token.
                    The property name used to send the token should be specified in this scenario.
                    <hr />
                    <b>Note:</b>&nbsp;encourage customers to use "API Key" or "Basic" authentication
                    types if possible. While we support bearer token authentication, it's not
                    recommended to use this because of the extra complexity and overhead.
                </span>
            ),
        }[postbackType];
    }
}
