import { parseQueryParams, buildQueryString } from "./QueryStrings";
import { Range } from "ace-builds";

export function getMarkers({ keyword, context }) {
    const { content, caseSensitive, setMarkers } = context;
    const markers = [];
    if (keyword && keyword.length !== 0) {
        content.split("\n").forEach((line, index) => {
            const matches = regexMatch(keyword, line, caseSensitive);
            matches.forEach((match) => {
                markers.push({
                    ...match,
                    startRow: index,
                    endRow: index,
                    className: "ace_marker",
                    type: "text",
                });
            });
        });
    }
    setMarkers(markers);
    // setMatchCount(markers.length);
}

export function addSelection(editor, { selectionQueryParam }) {
    const queryParams = parseQueryParams(window.location.href.split("?").pop());
    const selection = queryParams[selectionQueryParam];
    const range = getRange(selection);
    if (range) {
        const { startRow, endRow } = range;
        const rangeObj = new Range(startRow - 1, 0, endRow - 1, 100000000000);
        editor.selection.setSelectionRange(rangeObj);
        editor.scrollToLine(startRow, true, true, () => {});
    }
}

export function handleSelection({
    editor,
    history,
    location,
    selectionQueryParam,
    ignoreSelectionChanges,
}) {
    if (ignoreSelectionChanges) return;
    const selection = getSelection(editor.selection);
    const queryParams = parseQueryParams(window.location.href.split("?").pop());
    const paramValue = queryParams[selectionQueryParam];
    let newParamValue = "";

    if (selection) {
        const { startRow, endRow } = selection;
        newParamValue = `${startRow}-${endRow}`;
    }

    if (newParamValue === paramValue) return;
    history.push({
        pathname: location.pathname,
        search: buildQueryString({
            ...queryParams,
            [selectionQueryParam]: newParamValue,
        }),
    });
}

/*
 * ======================
 *      NON-EXPORTED
 * ======================
 */

/**
 * Takes a regular expression and a string to search on and returns all
 * start and end indices of a match within the string.
 *
 * @param {String} regexString a string containing a valid regular expression
 * @param {String} searchString the string to find matches in
 */
function regexMatch(regexString, searchString, caseSensitive) {
    const matches = [];
    const flags = ["g"];
    if (!caseSensitive) flags.push("i");

    let valid = false;
    try {
        new RegExp(regexString, "g");
        valid = true;
    } catch (err) {}

    if (valid) {
        try {
            //The global modifier  "g" finds all matches instead of just the first
            const regex = new RegExp(regexString, flags.join(""));
            let match;
            while ((match = regex.exec(searchString)) !== null) {
                matches.push({
                    startCol: match.index,
                    endCol: match.index + match[0].length,
                });

                // Prevent infinite loop from bad expression
                if (matches.length > searchString.length) return [];
            }
        } catch (error) {
            console.log(error);
        } // Keeps page from throwing error while regex is being typed
    }

    return matches;
}

function getSelection({ anchor, cursor }) {
    const startAnchor = anchor.row < cursor.row;
    const startRow = startAnchor ? parseInt(anchor.row) + 1 : parseInt(cursor.row) + 1;
    const endRow = !startAnchor ? parseInt(anchor.row) + 1 : parseInt(cursor.row) + 1;
    const startCol = startAnchor ? anchor.column : cursor.column;
    const endCol = !startAnchor ? anchor.column : cursor.column;

    return startRow === endRow && startCol === endCol ? null : { startRow, endRow };
}

function getRange(selectionRange) {
    if (!selectionRange || !/^[\d]{1,}-[\d]{1,}$/.test(selectionRange)) return null;
    const rows = selectionRange.split("-");
    let startRow, endRow;
    if (rows.length < 2) {
        startRow = endRow = rows[0];
    } else {
        startRow = rows[0];
        endRow = rows[1];
    }
    return { startRow, endRow };
}
