import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "../reusable/Loading";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

const Auth0ClientId = "qmWfEZA6APUWFOqjqhewe4gnfpttjNZK";

/**
 * Handler for the Auth0 callback URL.
 *
 * When Auth0 redirects here, it takes just a minute for the
 * Auth0Provider to complete the authentication process which
 * can be waited for by checking the `isLoading` property
 * exported by the `useAuth0` hook.
 *
 * Once loading is completed, we can check to see if the user
 * that is signed in has access to this application by looking
 * at the `isAuthenticated` property. If this value is false,
 * it means the user signed in successfully, but does not have
 * access to use this application.
 *
 * If they do not have access, redirect them to the "Access
 * Denied" page where they will be prompted to sign in using
 * a different account. Otherwise, redirect them to the home
 * page of the application.
 */
const Authorize = () => {
    const { isLoading } = useAuth0();
    const { authenticating } = useContext(UserContext);

    useEffect(() => {
        if (isLoading || authenticating) return;
        const cookies = new Cookies();
        const auth0Cookie = cookies.get(`auth0.${Auth0ClientId}.is.authenticated`);

        if (!auth0Cookie) {
            Swal.fire({
                icon: "error",
                titleText: "Login Failed",
                text: "Third-party cookies must be enabled for this site",
            });
        }
    }, [isLoading, authenticating]);

    if (isLoading) return <Loading />;
    return <Redirect to={null || "/"} />;
};

export default Authorize;
