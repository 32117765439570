// import { useQuery } from "@tanstack/react-query";
// import { PostPayload } from "src/functions/Http";
// import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useSecureMutate } from "src/hooks/useSecureMutate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";
import { ErrorAlert } from "src/components/reusable/Alerts";
import Swal from "sweetalert2";

const url = (baseUrl) => `${baseUrl}/warning-logs`;

// export const useWarningLogs = ({ columnValuePairs, rangeFrom, rangeTo, limit } = {}) => {
//     const { baseUrl } = useCurrentEnvironment();
//     const { secureFetch } = useSecureFetch(url(baseUrl));

//     return useQuery({
//         queryKey: ["warning-logs"],
//         queryFn: async () =>
//             await secureFetch((token) =>
//                 PostPayload(token, {
//                     columnValuePairs,
//                     rangeFrom,
//                     rangeTo,
//                     limit,
//                 })
//             ),
//     });
// };

export const useMutateWarningLogs = () => {
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({
        url: url(baseUrl),
        onSuccess: () => Swal.close(),
        onError: () => ErrorAlert(),
    });
};
