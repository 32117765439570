import React, { useContext } from "react";
import { Col, InputGroup } from "react-bootstrap";
import {
    SearchFilter,
    DataFilter,
    LanguageFilter,
    RegexFilter,
    FontSizeFilter,
    CodeFoldOptions,
    MatchCount,
    MatchIterator,
} from "./Filters";

export default function ControlGroup(props) {
    const context = useContext(props.context);
    const { dataOption } = context;
    const { readOnly, queryParamPrefix } = props;

    return (
        <Col>
            <p className="viewing mb-2 ml-1">
                <b className="blue">Viewing:</b>&nbsp;{dataOption}
            </p>
            <InputGroup size="sm" className="editor-controls">
                <RegexFilter context={context} />
                <SearchFilter context={context} readOnly={readOnly} />
                <DataFilter context={context} queryParamPrefix={queryParamPrefix} />
                <LanguageFilter context={context} />
                <FontSizeFilter context={context} />
            </InputGroup>
            <p className="mb-2 ml-1 no-select">
                <MatchCount context={context} />
                <MatchIterator context={context} />
                <CodeFoldOptions context={context} />
            </p>
        </Col>
    );
}
