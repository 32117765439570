import React, { useState, useEffect, useContext } from "react";
import { Form, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { DropdownActiveClass } from "../../enums/DropdownValues";
import { ThemeContext } from "../../contexts/ThemeContext";
import { SearchPresetsContext } from "../../contexts/SearchPresetsContext";
import { SettingsContext } from "../../contexts/SettingsContext";

const noPreset = {
    id: 0,
    name: "None",
    search: "",
};

/**
 * Controls the default search settings for the editors.
 *
 * @param {Object} props props
 * @param {Object} props.editor which editor (leftEditor/rightEditor)
 * @param {Object} props.ls_settingName the setting name in LocalStorage (ls)
 */
export const EditorSearchSettings = ({ editor, ls_settingName }) => {
    const { outlineVariant } = useContext(ThemeContext);
    const [presets] = useContext(SearchPresetsContext);
    const { settings } = useContext(SettingsContext);
    const presetId = localStorage.getItem(ls_settingName);
    const [preset, setPreset] = useState(
        (presetId ? presets.find((p) => p.id === presetId) : noPreset) || noPreset
    );

    const setDefaultSearch = (id) => {
        if (id === String(noPreset.id)) {
            setPreset(noPreset);
            localStorage.setItem(ls_settingName, "");
        } else {
            const preset = presets.find((p) => p.id === id);
            setPreset(preset);
            localStorage.setItem(ls_settingName, preset.id);
        }
    };

    useEffect(() => {
        const selectionDeletedFromList = !presets.find((p) => p.id === preset.id);
        if (selectionDeletedFromList) {
            setPreset(noPreset);
            localStorage.setItem(ls_settingName, "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presets]);

    useEffect(() => {
        const search = settings[editor].search;
        if (preset.search === search) return;
        else setPreset(presets.find((p) => p.search === search) || noPreset);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <div className="dropdown-setting">
            <Form.Label>Default search:</Form.Label>
            <DropdownButton
                as={InputGroup.Append}
                variant={outlineVariant}
                title={preset.name}
                onSelect={(id) => {
                    setDefaultSearch(id);
                }}>
                <Dropdown.Item
                    key={noPreset.id}
                    eventKey={noPreset.id}
                    className={noPreset.id === preset.id ? DropdownActiveClass : ""}>
                    None
                </Dropdown.Item>
                {presets.length > 0 ? <Dropdown.Divider /> : null}
                {presets.map(({ id, name }) => (
                    <Dropdown.Item
                        key={id}
                        eventKey={id}
                        className={id === preset.id ? DropdownActiveClass : ""}>
                        {name}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </div>
    );
};
