import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { EditorLayout, InfoPanelLayout, Settings } from "../../models/Settings";
import DualButtonGroup from "./DualButtonGroup";
import { SettingsContext } from "../../contexts/SettingsContext";

/**
 * Settings for page layout such as the orientation of the editors
 * and whether or not the information panel is collapsed or axpanded
 * by default.
 */
export const LayoutSettings = () => {
    const { settings, updateSettings } = useContext(SettingsContext);
    const currentPanelSetting = localStorage.getItem(Settings.INFO_PANEL_LAYOUT);
    const currentEditorSetting = localStorage.getItem(Settings.EDITOR_LAYOUT);

    /*
     * ===========================
     *      INFO PANEL LAYOUT
     * ===========================
     */
    const [collapsed, setCollapsed] = useState(currentPanelSetting === InfoPanelLayout.COLLAPSED);
    const setDefaultCollapsed = (bool) => {
        localStorage.setItem(
            Settings.INFO_PANEL_LAYOUT,
            bool ? InfoPanelLayout.COLLAPSED : InfoPanelLayout.EXPANDED
        );
        setCollapsed(bool);
        updateSettings();
    };

    /*
     * =======================
     *      EDITOR LAYOUT
     * =======================
     */
    const [viewVertical, setViewVertical] = useState(
        currentEditorSetting === EditorLayout.VERTICAL
    );
    const setDefaultVertical = (bool) => {
        localStorage.setItem(
            Settings.EDITOR_LAYOUT,
            bool ? EditorLayout.VERTICAL : EditorLayout.HORIZONTAL
        );
        setViewVertical(bool);
        updateSettings();
    };

    /**
     * Ensures accuracy of these settings on screen when other settings change.
     */
    useEffect(() => {
        // INFO PANEL
        const newCollapsed = settings.infoPanelLayout === InfoPanelLayout.COLLAPSED;
        if (collapsed !== newCollapsed) setCollapsed(newCollapsed);

        // EDITOR LAYOUT
        const newViewVertical = settings.editorLayout === EditorLayout.VERTICAL;
        if (viewVertical !== newViewVertical) setViewVertical(newViewVertical);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <div>
            <div className="dual-btn-option">
                <Form.Label style={{ display: "inline-block" }}>Info panel layout:</Form.Label>
                <DualButtonGroup
                    leftActive={!collapsed}
                    leftText="Expanded"
                    rightText="Collapsed"
                    leftClick={() => setDefaultCollapsed(false)}
                    rightClick={() => setDefaultCollapsed(true)}
                />
            </div>
            <div className="dual-btn-option">
                <Form.Label style={{ display: "inline-block" }}>Editor layout:</Form.Label>
                <DualButtonGroup
                    leftActive={viewVertical}
                    leftText="Vertical"
                    rightText="Horizontal"
                    leftClick={() => setDefaultVertical(true)}
                    rightClick={() => setDefaultVertical(false)}
                />
            </div>
        </div>
    );
};
