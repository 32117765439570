import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { CaseSensitivity } from "../../models/Settings";
import DualButtonGroup from "./DualButtonGroup";
import { SettingsContext } from "../../contexts/SettingsContext";

/**
 * Controls the default case settings for the editors.
 *
 * @param {Object} props props
 * @param {Object} props.ls_settingName the setting name in LocalStorage (ls)
 */
export const EditorCaseSettings = ({ ls_settingName }) => {
    const { settings, updateSettings } = useContext(SettingsContext);
    const storedValue = localStorage.getItem(ls_settingName) === CaseSensitivity.SENSITIVE;
    const [caseSensitive, setCaseSensitive] = useState(storedValue);

    const setDefaultCaseSensitive = (bool) => {
        localStorage.setItem(
            ls_settingName,
            bool ? CaseSensitivity.SENSITIVE : CaseSensitivity.INSENSITIVE
        );
        setCaseSensitive(bool);
        updateSettings();
    };

    /**
     * Ensures accuracy of this setting on screen when other settings change.
     */
    useEffect(() => {
        if (caseSensitive !== storedValue) setCaseSensitive(storedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <div className="dual-btn-option">
            <Form.Label>Case sensitive:</Form.Label>
            <DualButtonGroup
                leftActive={caseSensitive}
                leftText="True"
                rightText="False"
                leftClick={() => setDefaultCaseSensitive(true)}
                rightClick={() => setDefaultCaseSensitive(false)}
            />
        </div>
    );
};
