import React from "react";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
import InfoIcon from "src/components/reusable/InfoIcon";
const { styled } = StyledComponentContext._currentValue;

/*
 * =======================
 *      Simple Styles
 * =======================
 */
const Header = styled.h5`
    margin: 0;
`;

const HeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding-bottom: 3px;
`;

export default function WidgetHeader(props) {
    const { info, button, children } = props;
    const infoIcon = info ? <InfoIcon info={info} /> : null;

    return (
        <HeaderWrapper>
            <Header>
                {children}
                {infoIcon}
            </Header>
            {button}
        </HeaderWrapper>
    );
}
