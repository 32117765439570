import React from "react";
import { Spinner } from "react-bootstrap";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
import withAuthorizationCheck from "../HOC/withAuthorizationCheck";
const { styled } = StyledComponentContext._currentValue;

const Container = styled.div`
    display: grid;
    place-content: ${({ alignLeft }) => (alignLeft ? "start" : "end")};
    grid-template-columns: max-content max-content;
    grid-template-columns: max-content ${({ hideCancel }) => !hideCancel && "max-content"};
    column-gap: 10px;
    // margin-bottom: 30px;
    // margin-top: -10px;

    button {
        padding: 3px 12px 4px !important;
        margin-top: 2px;
        font-size: 12px !important;
        width: max-content;
    }
`;

const SB = styled.button`
    &:hover {
        color: #fff !important;
        background-color: #28a745 !important;
        border-color: #28a745 !important;
        ${({ disabled }) => disabled && "cursor: not-allowed;"}
    }
`;

const CB = styled.button`
    &:hover {
        background: #dc3545 !important;
        color: #fff !important;
        border-color: #dc3545 !important;

        background: #7e7e7e !important;
        border-color: #7e7e7e !important;
    }
`;

export default function SaveCancelButtons(props) {
    const {
        permissions,
        visible,
        showSpinner,
        saveText,
        cancelText,
        saveDisabled,
        hideCancel,
        alignLeft,
        onSave,
        onCancel,
        style,
    } = props;

    const SaveButton = withAuthorizationCheck(
        permissions,
        <SB
            key={"Save"}
            type="button"
            className={`btn btn-sm btn-outline-${saveDisabled ? "secondary" : "success"}`}
            onClick={() => onSave && onSave()}
            disabled={saveDisabled}>
            {saveText || "Save"}
        </SB>,
        <SB
            key={"Save"}
            type="button"
            className={`btn btn-sm btn-outline-${saveDisabled ? "secondary" : "success"}`}
            onClick={() => {}}
            disabled
            title="User not authorized">
            {saveText || "Save"}
        </SB>
    )();

    const CancelButton = !hideCancel ? (
        <CB
            key={"Cancel"}
            type="button"
            className={`btn btn-sm btn-secondary`}
            onClick={() => onCancel && onCancel()}>
            {cancelText || "Cancel"}
        </CB>
    ) : null;

    const spinner = <Spinner animation="border" size="sm" />;
    const buttons = [CancelButton, SaveButton];
    if (alignLeft) buttons.reverse();

    return visible ? (
        <Container alignLeft={alignLeft} hideCancel={hideCancel} style={style}>
            {showSpinner ? spinner : buttons}
        </Container>
    ) : null;
}
