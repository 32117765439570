import React, { useState, useEffect, useContext, useRef, createContext } from "react";
import { DropdownDefaults } from "../enums/DropdownValues";
import { CaseSensitivity, Settings, GetUserSettings } from "../models/Settings";
import { getDataOption, parseQueryParams } from "../functions/QueryStrings";
import { QueryParameters } from "../enums/QueryParameters";
import { LeftEditorContext } from "./LeftEditorContext";
import DetectLanguage from "../functions/DetectLanguage";
import { useTransaction } from "src/hooks/react-query/transactions/useTransaction";

export const RightEditorContext = createContext();

export const RightEditorProvider = (props) => {
    const { transactionId } = props;
    const getRecordData = (records, dataOption) =>
        records.find(({ name }) => name.startsWith(dataOption))?.data;

    const leftEditorContext = useContext(LeftEditorContext);
    const { children, searchMirror } = props;
    const ref = useRef("right-editor");
    const transaction = useTransaction(transactionId).data;
    const { records } = transaction;
    const dataOptions = records.map(({ name }) => name).reverse();
    const settings = GetUserSettings().rightEditor;
    const queryParams = parseQueryParams(window.location.href.split("?").pop());

    const [caseSensitive, setCaseSensitive] = useState(settings.case === CaseSensitivity.SENSITIVE);
    const [search, setSearch] = useState(settings.search);
    const [dataOption, setDataOption] = useState(
        getDataOption(queryParams, QueryParameters.RIGHT_DATA, dataOptions, Settings.RIGHT_DATA)
    );
    const [content, setContent] = useState(getRecordData(records, dataOption));
    const [language, setLanguage] = useState(DetectLanguage(content));
    const [fontSize, setFontSize] = useState(settings.fontSize || DropdownDefaults.FONT_SIZE);
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        const newContent = getRecordData(records, dataOption);
        setContent(newContent);
        setLanguage(DetectLanguage(newContent));
    }, [records, dataOption]);

    const getSearchMatches = () => {
        const rightSearch = searchMirror ? leftEditorContext.search : search;
        return rightSearch && rightSearch.length > 0 ? markers.length : null;
    };

    const value = {
        caseSensitive,
        setCaseSensitive,
        content,
        setContent,
        dataOption,
        setDataOption,
        dataOptions,
        fontSize,
        setFontSize,
        language,
        ref,
        setLanguage,
        markers,
        setMarkers,
        search: searchMirror ? leftEditorContext.search : search,
        setSearch: searchMirror ? leftEditorContext.setSearch : setSearch,
        searchMatches: getSearchMatches(),
    };

    return <RightEditorContext.Provider value={value}>{children}</RightEditorContext.Provider>;
};
