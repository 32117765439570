import React from "react";
import { useState } from "react";
import AuthorizationsWidget from "./AuthorizationsWidget";

const MonitoringWidget = ({ account, products }) => {
    const [authorizations, setAuthorizations] = useState(
        products.filter((a) => a.authType === "Monitoring")
    );
    return (
        <AuthorizationsWidget
            key={JSON.stringify(authorizations)}
            label="Monitoring"
            account={account}
            authorizations={authorizations}
            setAuthorizations={setAuthorizations}
            permissions={["account:state-access"]}
        />
    );
};

export default MonitoringWidget;
