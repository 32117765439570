import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "../reusable/Loading";
import { REDIRECT_URI } from "../../enums/Endpoints";

/**
 * This component will redirect users to Auth0 so
 * they can sign in to the application.
 *
 * This component is needed primarily for the
 * `prompt` option utilized within the Auth0
 * `loginWithRedirect` method. This option tells
 * Auth0 to tell Google not to use the account
 * that was signed into previously when the user
 * signs in again.
 *
 * The reason for this is because if the user signs
 * in with Google but mistakenly signs in to the
 * wrong account, whenever they sign in through
 * Auth0 again, Google will still remember the
 * account they chose and default to that account
 * rather than letting them choose a different one.
 *
 * That makes it impossible to switch accounts if
 * someone selects the wrong one, so this call to
 * `loginWithRedirect` specifies that Google should
 * allow the user to choose their account again when
 * redirected back to the sign in page.
 */
const LoginWithRedirect = () => {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        loginWithRedirect({
            redirectUri: REDIRECT_URI,
            prompt: "select_account",
            appState: {},
        });
    });

    return <Loading />;
};

export default LoginWithRedirect;
