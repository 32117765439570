import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import Widget from "src/widgets/Widget";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
import AuthSchemaListItemContent from "./AuthSchemaListItemContent";
import NewAuthSchemaModal from "./NewAuthSchemaModal";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
import { useWebhooks } from "src/hooks/react-query/accounts/useWebhooks";
const { styled } = StyledComponentContext._currentValue;

const LabelWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: auto 1fr;
    column-gap: 5px;

    code {
        font-size: 1.1em;
        // padding: 0 1px;
        // background: var(--light);
    }
`;

export const SchemeBadgeColorMap = {
    KEY: "light",
    BASIC: "info",
    BEARER: "dark",
};

export default function AuthSchemaList({ account, permissions }) {
    const [showNewAuthModal, setShowNewAuthModal] = useState(false);

    const { data, error, isLoading, refetch } = useWebhooks(account.name);
    const schemas = data?.schemas;

    const schemaList = schemas?.map((schema, idx) => {
        const { type } = schema;
        const { apiKeyHeaderName } = schema.key ?? {};
        const { username } = schema.basic ?? {};
        const tokenParamName = schema.bearer?.tokenParamName || "<token>";

        const label = (
            <LabelWrapper className="monospace">
                <div style={{ fontSize: "1.1em" }}>{String(++idx)}.</div>
                <span>{apiKeyHeaderName || username || tokenParamName}</span>
            </LabelWrapper>
        );

        const badge = (
            <Badge pill variant={SchemeBadgeColorMap[type] || "danger"}>
                {type === "KEY" ? "API KEY" : type}
            </Badge>
        );

        return (
            <Widget.ListItem key={idx} label={label} value={badge}>
                <AuthSchemaListItemContent
                    account={account}
                    schema={schema}
                    refresh={refetch}
                    permissions={permissions}
                />
            </Widget.ListItem>
        );
    });

    const AddCredentialButton = withAuthorizationCheck(
        permissions,
        <Widget.Button text="Add" onClick={() => setShowNewAuthModal(true)} />
    );

    return (
        <React.Fragment>
            <Widget
                loading={isLoading}
                header={
                    <Widget.Header info={headerInfo()} button={<AddCredentialButton />}>
                        Authentication
                    </Widget.Header>
                }>
                {error && <span>ERROR: Could not fetch authentication schemes</span>}
                {!error && schemaList?.length && schemaList}
            </Widget>
            <NewAuthSchemaModal
                account={account}
                showModal={showNewAuthModal}
                setShowModal={setShowNewAuthModal}
                refresh={refetch}
                permissions={permissions}
            />
        </React.Fragment>
    );

    function headerInfo() {
        return (
            <span>
                Webhook authentication is how the <b>REST API authenticates back to the customer</b>{" "}
                when sending completed orders. In the same way that customers must have credentials
                to access our API, this allows customers to specify credentials they wish for us to
                use when we send requests to their API.
                <hr />
                <b>Note:</b>&nbsp;encourage customers to use "API Key" or "Basic" authentication
                types for this process. While we support bearer token authentication, it's not
                recommended to use this because of the extra complexity and overhead.
            </span>
        );
    }
}
