import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { InputLabel, RequiredStar } from "./_shared";
import * as Colors from "..//_Colors";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

const StyledTextArea = styled.textarea`
    background: ${({ darkMode }) => Colors.InputBackground(darkMode)} !important;
    border: 1px solid ${({ darkMode }) => Colors.ListItemBorder(darkMode)} !important;
    margin-bottom: 5px;
    padding: 16px 12px 18px !important;
    ${({ padRight }) => padRight && "padding-right: 30px !important;"}
    font-size: 14px !important;
    border-radius: 3px;
    color: ${({ darkMode }) => Colors.AltText(darkMode)} !important;
    max-height: ${({ maxHeight }) => maxHeight || "unset"};

    &::placeholder {
        color: ${({ darkMode }) => Colors.Placeholder(darkMode)} !important;
    }
`;

/**
 * Accessed via <Widget.Button>, this component is to be passed through the "button"
 * parameter on a <Widget> component.
 *
 * @param {obj} obj.text the text displayed on the button
 * @param {obj} obj.onClick the function to call when the button is clicked
 */
export const TextArea = React.forwardRef((props, ref) => {
    const { darkMode } = useContext(ThemeContext);
    const handleOnChange = (event) => {
        props.onChange(event.target.value);
    };
    const label = props.label ? (
        <InputLabel>
            {`${props.label}:`}
            {props.required && <RequiredStar />}
        </InputLabel>
    ) : null;
    return (
        <span>
            {label}
            <StyledTextArea
                ref={ref}
                rows={props.rows || "15"}
                cols={props.cols || "15"}
                maxHeight={props.maxHeight}
                name={props.name}
                darkMode={darkMode}
                defaultValue={props.value || ""}
                placeholder={props.placeholder}
                onChange={props.onChange && handleOnChange}
                className="form-control custom-scroll"
                {...props.inputAttributes}
            />
        </span>
    );
});
