import React from "react";
import Widget from "../../../widgets/Widget";
import { GridLayout } from "../../styled_components/Layouts";
import { States } from "../../../enums/States";
import { ErrorMessage } from "../../reusable/ReactFormComponents";
import { useAccounts } from "src/hooks/react-query/account-list/useAccounts";

/**
 * Requires state when certain other values are submitted.
 */
const validateStateFilter = (getValues) => (value) => {
    if (!value && getValues("license")) return "Required when license is provided";
    if (!value && getValues("firstName")) return "Required when first name is provided";
    if (!value && getValues("lastName")) return "Required when last name is provided";
    return true; // Valid
};

/*
 * ========================
 *      Main Component
 * ========================
 */
const TransactionFilters = (props) => {
    const { register, defaultValues, getValues, errors } = props;
    return (
        <Widget className="mb-0">
            <DropdownFilters
                defaultValues={defaultValues}
                register={register}
                getValues={getValues}
                errors={errors}
            />
            <TextFilters defaultValues={defaultValues} register={register} />
        </Widget>
    );
};

export default TransactionFilters;

/*
 * =======================
 *      Subcomponents
 * =======================
 */
function DropdownFilters(props) {
    const { defaultValues, register, getValues, errors } = props;
    const { category, state, account, limit } = defaultValues || {};
    const accountList = useAccounts().data?.accounts;

    const NoValueOptionLabel = "Any";
    const NumbersOfResultsOptions = [25, 50, 100, 250];

    const sorted = accountList && [...accountList.sort((a, b) => a.name.localeCompare(b.name))];
    return (
        <div>
            <GridLayout columns="4">
                <Widget.Select
                    label="Category"
                    options={["Normal", "Blocked"]}
                    name="category"
                    defaultValue={category}
                    ref={register()}
                />
                <Widget.Select
                    label="State"
                    options={[...States]}
                    optionValueProp={"abbreviation"}
                    optionLabelProp={"name"}
                    noValueOption={true}
                    noValueOptionLabel={NoValueOptionLabel}
                    defaultValue={States.find((s) => s.abbreviation === state)}
                    name="state"
                    ref={register({ validate: { required: validateStateFilter(getValues) } })}
                />
                {accountList ? (
                    <Widget.Select
                        label="Account"
                        options={sorted}
                        optionValueProp={"name"}
                        optionLabelProp={"name"}
                        noValueOption={true}
                        noValueOptionLabel={NoValueOptionLabel}
                        defaultValue={sorted.find((a) => a.name === account)}
                        name="account"
                        ref={register()}
                    />
                ) : (
                    <Widget.Input type="text" label="Account" value={"Loading..."} readOnly />
                )}
                <Widget.Select
                    label="Limit"
                    options={[...NumbersOfResultsOptions]}
                    defaultValue={Number.parseInt(limit, 10) || NumbersOfResultsOptions[0]}
                    name="limit"
                    ref={register()}
                />
            </GridLayout>
            <GridLayout columns="4" style={{ marginTop: 5 }}>
                <div>
                    {errors.category && <ErrorMessage>{errors.category.message}</ErrorMessage>}
                </div>
                <div>{errors.state && <ErrorMessage>{errors.state.message}</ErrorMessage>}</div>
                <div>{errors.account && <ErrorMessage>{errors.account.message}</ErrorMessage>}</div>
                <div>{errors.limit && <ErrorMessage>{errors.limit.message}</ErrorMessage>}</div>
            </GridLayout>
        </div>
    );
}

function TextFilters(props) {
    const { defaultValues, register } = props;
    const { id, endUser, firstName, lastName, license } = defaultValues || {};

    return (
        <GridLayout columns="5">
            <Widget.Input
                type="text"
                placeholder="ID (UUID or Cust Ref)"
                value={id || ""}
                name="id"
                ref={register()}
            />
            <Widget.Input
                type="text"
                placeholder="End User"
                value={endUser || ""}
                name="endUser"
                ref={register()}
            />
            <Widget.Input
                type="text"
                placeholder="First name"
                value={firstName || ""}
                name="firstName"
                ref={register()}
            />
            <Widget.Input
                type="text"
                placeholder="Last name"
                value={lastName || ""}
                name="lastName"
                ref={register()}
            />
            <Widget.Input
                type="text"
                placeholder="License number"
                value={license || ""}
                name="license"
                ref={register()}
            />
        </GridLayout>
    );
}
