import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { lightDarkOutline } from "../../functions/ColorTheme";

/**
 * Component for displaying search presets and allowing them to be edited.
 *
 * @param {Object} props props
 * @param {Object} props.id the id of the search preset
 * @param {Object} props.name the name of the search preset
 * @param {Object} props.search the content or "search" of the search preset
 * @param {Object} props.onSave function to call when saving/updating this preset
 * @param {Object} props.onRemove function to call when removing this preset
 * @param {Object} props.names list of all current preset names
 * @param {Object} props.invalidFeedback invalid feedback element to display if input is invalid
 */
export const SearchPreset = ({ id, name, search, onSave, onRemove, names, invalidFeedback }) => {
    const [newName, setNewName] = useState(name);
    const [newSearch, setNewSearch] = useState(search);
    const confirm = () => window.confirm("Are you sure?");

    const cancel = () =>
        confirm() &&
        (() => {
            setNewName(name);
            setNewSearch(search);
        })();

    const nameChanged = newName !== name;
    const searchChanged = newSearch !== search;
    const isInvalidName = nameChanged && names.includes(newName);

    const NameInput = () => (
        <div>
            <Form.Control
                size="sm"
                type="text"
                className="monospace outline-dark"
                value={newName}
                placeholder="Name"
                isInvalid={isInvalidName}
                onChange={(e) => setNewName(e.target.value)}
            />
            {invalidFeedback}
        </div>
    );

    const SearchInput = () => (
        <div>
            <Form.Control
                size="sm"
                type="text"
                className="monospace outline-dark"
                value={newSearch}
                placeholder="Regex"
                onChange={(e) => setNewSearch(e.target.value)}
            />
        </div>
    );

    const RemoveButton = () => (
        <div>
            <Button
                variant="outline-secondary"
                className="remove"
                size="sm"
                title="Delete this search"
                onClick={() => confirm() && onRemove(id)}
            />
        </div>
    );

    const SaveAndCancelButtons = () => {
        const disabled = isInvalidName || newName.length === 0 || newSearch.length === 0;
        const saveVariant = disabled ? "secondary" : "success";
        return nameChanged || searchChanged ? (
            <div className="actions">
                <Button
                    variant={`outline-${saveVariant}`}
                    size="sm"
                    onClick={() => onSave(id, newName, newSearch)}
                    disabled={isInvalidName}>
                    Save
                </Button>
                <Button
                    className="cancel"
                    variant={lightDarkOutline()}
                    size="sm"
                    onClick={() => cancel()}>
                    Cancel
                </Button>
            </div>
        ) : null;
    };

    return (
        <div className="preset">
            {NameInput()}
            {SearchInput()}
            {RemoveButton()}
            {SaveAndCancelButtons()}
        </div>
    );
};
