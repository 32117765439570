import React from "react";
import Widget from "../../../../../widgets/Widget";
import SaveCancelButtons from "../../../../reusable/SaveCancelButtons";

const MaxNoteSize = 60000;
const EditNote = (props) => {
    const {
        title,
        text,
        onChangeTitle,
        onChangeText,
        onSave,
        onCancel,
        saveDisabled,
        saving,
    } = props;
    return (
        <React.Fragment>
            <Widget.Input
                type="text"
                label="Title"
                placeholder="Ex: Note 09.15.20"
                value={title}
                onChange={onChangeTitle}
            />
            <Widget.Spacer />
            <Widget.TextArea
                label="Note"
                placeholder="Type note here..."
                value={text}
                onChange={onChangeText}
                inputAttributes={{ maxLength: MaxNoteSize }}
                maxHeight="550px"
            />
            <div style={{ textAlign: "right" }}>
                {text?.length}/{MaxNoteSize}
            </div>
            <Widget.Spacer />
            <SaveCancelButtons
                visible={true}
                saveDisabled={saveDisabled}
                onSave={() => onSave()}
                onCancel={() => onCancel()}
                style={{ marginTop: "5px" }}
                showSpinner={saving}
            />
        </React.Fragment>
    );
};

export default EditNote;
