import React from "react";
import PropTypes from "prop-types";
import { Button, Popover, OverlayTrigger, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    & > i {
        font-size: 1.2em;
    }
`;

const CustomDivider = styled.div`
    border-top: 1px solid #ababab;
    margin: 5px 15px;
`;

export default function InfoSectionAction(props) {
    const { hasPermissions } = useContext(UserContext);
    const {
        permissions,
        disabled,
        loading,
        onClick,
        btnText,
        iconClassName,
        title,
        infoText,
        infoSubText,
        btnVariant = "primary",
    } = props;

    const authorized = hasPermissions(permissions);

    const actionButton = (
        <Button
            id={`${btnText}-button`}
            pill="true"
            variant={btnVariant}
            size="sm"
            onClick={authorized ? onClick : null}
            disabled={authorized ? disabled || loading : true}
            style={{
                cursor: disabled ? "default" : authorized ? "pointer" : "not-allowed",
                width: 120,
            }}
            title={authorized ? title : "User not authorized"}>
            {iconClassName && <i className={iconClassName} />}
            <span>&nbsp;{btnText}</span>
        </Button>
    );

    return (
        <ActionContainer id={`${btnText}-action-container`}>
            {actionButton}
            {loading && <Spinner animation="border" size="sm" />}
            {!loading && infoText && (
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="auto-end"
                    overlay={
                        <Popover id={`${btnText}-popover`}>
                            <Popover.Title as="h3">What's this?</Popover.Title>
                            <Popover.Content>{infoText}</Popover.Content>
                            {infoSubText && (
                                <>
                                    <CustomDivider />
                                    <Popover.Content>{infoSubText}</Popover.Content>
                                </>
                            )}
                        </Popover>
                    }>
                    <i className="fas fa-info-circle" />
                </OverlayTrigger>
            )}
        </ActionContainer>
    );
}

InfoSectionAction.propTypes = {
    // Required
    onClick: PropTypes.func.isRequired,
    btnText: PropTypes.string.isRequired,
    iconClassName: PropTypes.string.isRequired,

    // Optional
    /**
     * User permissions required to perform this action.
     */
    permissions: PropTypes.arrayOf(PropTypes.string),
    /**
     * Disable the button. (Will autmoatically be disabled
     * if permissions are provided and user does not have
     * necessary permissions to perform the action).
     */
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    title: PropTypes.string,
    infoText: PropTypes.string,
    infoSubText: PropTypes.string,
    btnVariant: PropTypes.oneOf([
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "light",
        "dark",
        "outline-primary",
        "outline-secondary",
        "outline-success",
        "outline-danger",
        "outline-warning",
        "outline-info",
        "outline-light",
        "outline-dark",
    ]),
};
