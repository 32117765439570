import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getMarkers, addSelection, handleSelection } from "../../../functions/Editor";
import { Language } from "../../../enums/DropdownValues";
import { ThemeContext } from "../../../contexts/ThemeContext";
import AceEditor from "react-ace";
import $ from "jquery";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-plain_text";
import "ace-builds/src-noconflict/theme-crimson_editor";
import "ace-builds/src-noconflict/theme-idle_fingers";

let ignoreSelectionChanges = true;

const Editor = (props) => {
    const { id, selectionQueryParam } = props;
    const { darkMode } = useContext(ThemeContext);
    const history = useHistory();
    const location = useLocation();
    const [editor, setEditor] = useState(null);

    // Context state
    const context = useContext(props.context);
    const {
        caseSensitive,
        content,
        fontSize,
        language,
        markers,
        ref,
        search,
        setContent,
        setSearch,
    } = context;

    $(`#${id}`).on("mousedown", (e) => {
        e.stopImmediatePropagation();
        e.stopPropagation();
        ignoreSelectionChanges = false;
    });

    $(`#${id}`).on("mouseup", (e) => {
        e.stopImmediatePropagation();
        e.stopPropagation();
        if (editor) {
            handleSelection({
                editor,
                history,
                location,
                selectionQueryParam,
                ignoreSelectionChanges,
            });
        }
        ignoreSelectionChanges = true;
    });

    useEffect(() => {
        getMarkers({ keyword: search, context });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, caseSensitive, content]);

    useEffect(() => {
        const editor = ref.current.editor;
        editor.commands.addCommand({
            name: "quicksearch",
            bindKey: { win: "Ctrl-f", mac: "Command-f" },
            exec: () => {
                setSearch(editor.getSelectedText());
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSearch]);

    return (
        <React.Fragment>
            <AceEditor
                id={id}
                ref={ref}
                name={id}
                mode={Language.format(language)}
                width="100%"
                height="100%"
                value={content && String(content)}
                markers={markers}
                maxlines="infinity"
                theme={editorTheme(darkMode)}
                fontSize={`${fontSize}px`}
                firstLineNumber="1"
                enableLiveAutocompletion={true}
                setOptions={{
                    useWorker: false,
                    highlightSelectedWord: true,
                }}
                onLoad={(editor) => {
                    editor.scrollToLine(1, true, true, () => {});
                    editor.gotoLine(1, 0, true);
                    setEditor(editor);

                    // Timeout prevents markers from overriding selection
                    setTimeout(() => addSelection(editor, { ...props }));
                }}
                onChange={setContent}
            />
        </React.Fragment>
    );
};

export default Editor;

/*
 * =========================
 *      EXTRA FUNCTIONS
 * =========================
 */
function editorTheme(darkMode) {
    return darkMode ? "idle_fingers" : "crimson_editor";
}
