import React, { useState, useRef, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { ApiKeyContent } from "./form-inputs/ApiKey";
import { BasicAuthContent } from "./form-inputs/Basic";
import { BearerAuthContent } from "./form-inputs/Bearer";
import SaveCancelButtons from "src/components/reusable/SaveCancelButtons";
import { AreYouSureAlert } from "src/components/reusable/Alerts";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
import Widget from "src/widgets/Widget";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
import { useMutateAuthSchemas } from "src/hooks/react-query/accounts/useAuthSchemas";
import { ErrorAlert } from "src/components/reusable/Alerts";
import { SuccessAlert } from "src/components/reusable/Alerts";
import { PutPayload, DeletePayload } from "src/functions/Http";
import { useWebhooks } from "src/hooks/react-query/accounts/useWebhooks";
import { Alert } from "react-bootstrap";
import { ThemeContext } from "src/contexts/ThemeContext";
import InfoIcon from "src/components/reusable/InfoIcon";
const { styled } = StyledComponentContext._currentValue;

const ButtonWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    margin: 5px 0;
    column-gap: 10px;
`;

export const AlertWrapper = styled.div`
    .alert {
        margin-top: 10px;

        ${({ darkMode }) =>
            darkMode &&
            `opacity: 0.8;
            background: #404c58;
            border-color: #4f6d8c;
            color: white !important;`}
    }

    p {
        margin-bottom: 5px;
    }

    ul {
        margin-bottom: 0;
        padding-left: 22px;
        font-family: monospace;
    }
`;

export const PostbackType = {
    KEY: "KEY",
    BASIC: "BASIC",
    BEARER: "BEARER",
};

export default function AuthSchemaListItemContent(props) {
    const { account, schema, refresh, permissions } = props;
    const postbackType = schema?.type;

    const { darkMode } = useContext(ThemeContext);
    const [unsavedWork, setUnsavedWork] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const isMounted = useRef(null);

    const formMethods = useForm({ mode: "onChange", defaultValues: schema });
    const { register, handleSubmit, reset } = formMethods;

    const { data } = useWebhooks(account.name);
    const updateSchema = useMutateAuthSchemas({
        accountName: account.name,
        onSuccess: () =>
            refresh()
                .then(SuccessAlert)
                .then(() => {
                    if (!isMounted.current) return;
                    setEditMode(false);
                    setSaveBtnDisabled(true);
                    setUnsavedWork(false);
                }),
        onError: () => ErrorAlert(),
    });

    const Content = {
        [PostbackType.KEY]: ApiKeyContent,
        [PostbackType.BASIC]: BasicAuthContent,
        [PostbackType.BEARER]: BearerAuthContent,
    }[postbackType];

    const webhooksUsingSchema = data?.webhooks?.filter((w) => w.authSchemaId === schema.id);
    const schemaInUse = !!webhooksUsingSchema?.length;
    const listItemOptions = [
        {
            name: "Edit",
            title: `Edit this configuration`,
            variant: "secondary",
            handler: () => setEditMode(true),
        },
        {
            name: "Delete",
            title: `Delete this configuration`,
            variant: "danger",
            handler: schemaInUse ? () => {} : onDelete,
            disabled: schemaInUse,
            disabledTitle: "One or more URL patterns are currently using this configuration",
        },
    ];

    if (!Content) return null;
    return (
        <div ref={isMounted}>
            {schemaInUse && (
                <AlertWrapper darkMode={darkMode}>
                    <Alert
                        variant="primary"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto",
                            alignItems: "center",
                        }}>
                        <div>
                            <p>Currently used by:</p>
                            <ul>
                                {webhooksUsingSchema?.map((w, idx) => (
                                    <li key={idx}>{w.urlPattern}</li>
                                ))}
                            </ul>
                        </div>
                        <InfoIcon
                            info={currentlyBeingUsedInfo(webhooksUsingSchema)}
                            style={{ fontSize: 20 }}
                        />
                    </Alert>
                </AlertWrapper>
            )}
            <FormProvider {...formMethods}>
                <input type="hidden" name="authId" value={schema.id} ref={register()} />
                <input type="hidden" name="authType" value={postbackType} ref={register()} />
                <Content
                    visible
                    account={account}
                    schema={schema}
                    readOnly={!editMode}
                    updatePermissions={permissions}
                    currentPostbackType={postbackType}
                    selectedPostbackType={postbackType}
                    setUnsavedWork={setUnsavedWork}
                    setSaveBtnVisible={() => {}}
                    setSaveBtnDisabled={setSaveBtnDisabled}
                    disableSaveWhenUnchanged
                />
            </FormProvider>
            <ButtonWrapper>
                {editMode ? (
                    <SaveCancelButtons
                        visible={true}
                        onSave={handleSubmit(onSave)}
                        onCancel={onCancel}
                        showSpinner={false}
                        permissions={permissions}
                        saveDisabled={saveBtnDisabled}
                    />
                ) : (
                    withAuthorizationCheck(
                        permissions,
                        listItemOptions
                            .filter(Boolean)
                            .map((option, idx) => (
                                <Widget.Button
                                    key={idx}
                                    variant={option.variant}
                                    onClick={option.handler}
                                    title={option.disabled ? option.disabledTitle : option.title}
                                    text={option.name}
                                    disabled={option.disabled}
                                />
                            ))
                    )()
                )}
            </ButtonWrapper>
        </div>
    );

    /*
     * ===================
     *      Functions
     * ===================
     */

    function onSave(values) {
        const text = "You are about to change the values for this authentication configuration.";
        AreYouSureAlert({ text }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            updateSchema.mutate((token) => {
                return PutPayload(token, { ...values, authId: parseInt(values.authId) });
            });
        });
    }

    function onDelete() {
        const text = "You are about to remove this authentication configuration.";
        AreYouSureAlert({ text }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            updateSchema.mutate((token) => DeletePayload(token, { authId: parseInt(schema.id) }));
        });
    }

    function onCancel() {
        const cancel = () => {
            reset(schema);
            setUnsavedWork(false);
            setSaveBtnDisabled(true);
            setEditMode(false);
        };
        if (!unsavedWork) return cancel();
        const confirmText = "Unsaved changes will be lost.";
        AreYouSureAlert({ text: confirmText }).then(({ isConfirmed }) => isConfirmed && cancel());
    }
}

function currentlyBeingUsedInfo(webhooksUsingSchema) {
    const s = webhooksUsingSchema.length > 1 ? "s" : "";
    return (
        <span>
            This authentication setup is currently being used by the listed pattern{s}. Whenever
            results are sent back to the customer, if the webhook provided with the order matches
            the pattern{s} listed here, this authentication will be used.
        </span>
    );
}
