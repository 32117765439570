import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import * as Colors from "../_Colors";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

/*
 * ====================
 *      Search Bar
 * ====================
 */
const SearchBarElem = styled.input`
    border: 1px solid ${({ darkMode }) => Colors.ListItemBorder(darkMode)};
    border-radius: 25px;
    font-size: 14px;
    padding: 4px 8px;
    outline: none;
    position: relative;
    width: 100%;
`;

const ClearSearchButton = styled.span`
    display: grid;
    place-items: center center;
    position: absolute;
    right: 5px;
    bottom: 0;
    height: 25px;
    width: 25px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
    &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        content: "\\f00d";
    }
`;

export const SearchBar = React.forwardRef((props, ref) => {
    const { search, setSearch, placeholder, onSubmit, inputAttributes } = props;
    const { darkMode } = useContext(ThemeContext);
    const showClearButton = ref.current && !!ref.current.value;
    const handleOnEnter = ({ key }) =>
        /^enter$/i.test(key) && onSubmit && onSubmit(ref.current.value);

    const { marginRight, marginLeft, ...style } = props.style || {};
    return (
        // The relative position is for the alignment of the "Clear search" button
        <div
            style={{
                position: "relative",
                marginRight,
                marginLeft,
            }}>
            <SearchBarElem
                ref={ref}
                type="text"
                darkMode={darkMode}
                value={search}
                placeholder={placeholder || "Search..."}
                onFocus={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onChange={() => setSearch(ref.current.value)}
                onKeyDown={handleOnEnter}
                style={style}
                {...inputAttributes}
            />
            {showClearButton && (
                <ClearSearchButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setSearch("");
                    }}
                />
            )}
        </div>
    );
});
