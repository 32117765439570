/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

function jsxElement(Element, props) {
    return typeof Element === "function" ? <Element {...props} /> : Element;
}

const ErrorWrapper = styled.div`
    display: grid;
    place-content: center;
    height: 80%;
`;

/**
 *
 * @param {*} permissions an array of string permissions the user needs to have in order to view this component
 * @param {*} Component the JSX component to render if user has appropriate permissions
 * @param {*} Alternate (optional) an alternate component to render (default is null)
 */
const withAuthorizationCheck = (permissions = [], Component, Alternate) => {
    return function WithPermissionCheck(props) {
        try {
            const { authenticated, hasPermissions } = useContext(UserContext);
            if (!authenticated) return Alternate ? jsxElement(Alternate, props) : null;

            const authorized = hasPermissions(permissions);
            if (!authorized) return Alternate ? jsxElement(Alternate, props) : null;

            return jsxElement(Component, props);
        } catch (error) {
            console.log("ERROR:", error);
            return (
                <ErrorWrapper>
                    <p>ERROR: {error.message}</p>
                </ErrorWrapper>
            );
        }
    };
};

export default withAuthorizationCheck;
