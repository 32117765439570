import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { FilterObjectCollection } from "../../../../../functions/SearchFunctions";
import Widget from "../../../../../widgets/Widget";
import AuthorizationsWidget from "./AuthorizationsWidget";
import { AccessLevels } from "./AccessLevels";

// const BaseExclusions = [
//     "AK",
//     "CA",
//     "CO",
//     "GA",
//     "IN",
//     "MD",
//     "NH",
//     "NJ",
//     "NM",
//     "OH",
//     "OR",
//     "PA",
//     "UT",
//     "WA",
// ];
// const SupplementalExclusions = ["CA", "GA", "PA"];

const Shortcuts = (authorizations) => {
    const MVRs = authorizations.filter((a) => a.authType === "MVR");
    return [
        {
            name: "None",
            authorizationIds: [],
        },
        // {
        //     name: "Basic",
        //     authorizationIds: MVRs.filter((auth) => {
        //         const authState = auth.authName.split("-")[1];
        //         return !BaseExclusions.includes(authState);
        //     }).map((auth) => auth.authId),
        // },
        // {
        //     name: "Supplemental",
        //     authorizationIds: MVRs.filter((auth) => {
        //         const authState = auth.authName.split("-")[1];
        //         return !SupplementalExclusions.includes(authState);
        //     }).map((auth) => auth.authId),
        // },
        {
            name: "Default",
            authorizationIds: MVRs.filter((auth) => {
                const authState = auth.authName.split("-")[1];
                return AccessLevels.Default.includes(authState);
            }).map((auth) => auth.authId),
        },
    ];
};

const MvrJurisdictionsWidget = ({ account, products }) => {
    const [authorizations, setAuthorizations] = useState(
        products.filter((a) => a.authType === "MVR")
    );
    const [search, setSearch] = useState("");
    const [filtered, setFiltered] = useState(authorizations);
    const searchRef = useRef(null);

    useEffect(() => {
        const filtered = FilterObjectCollection({
            search,
            collection: authorizations,
            objProperty: "authDescription",
        });
        setFiltered(filtered);
    }, [search, authorizations]);

    const SearchBar = <Widget.SearchBar ref={searchRef} search={search} setSearch={setSearch} />;

    return (
        <AuthorizationsWidget
            key={JSON.stringify(authorizations)}
            label="MVRs"
            account={account}
            authorizations={authorizations}
            setAuthorizations={setAuthorizations}
            filteredAuths={filtered}
            searchBar={SearchBar}
            collapsible={true}
            shortcuts={Shortcuts(authorizations)}
            permissions={["account:state-access"]}
        />
    );
};

export default MvrJurisdictionsWidget;
