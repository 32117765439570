import React, { useState } from "react";
import Widget from "../../../widgets/Widget";
import SaveCancelButtons from "../../reusable/SaveCancelButtons";
import { useForm } from "react-hook-form";
import AccountType, { DeverusCredential } from "./sections/AccountType";
import AccountName from "./sections/AccountName";
import AccountAddress from "./sections/AccountAddress";
import AccountContact from "./sections/AccountContact";
import { ContentHeader } from "../../styled_components/ContentHeader";
import { RequiredHint } from "../../styled_components/FormComponents";
import { PostPayload } from "../../../functions/Http";
import { useHistory } from "react-router-dom";
import { refreshAccounts } from "../../../functions/LocalStorage";
import { AreYouSureAlert, CustomReactAlert, ErrorAlert, SuccessAlert } from "../../reusable/Alerts";
import { Breadcrumb } from "react-bootstrap";
import { StyledComponentContext } from "../../../contexts/StyledComponentsContext";
import { useAuth0 } from "@auth0/auth0-react";
import { Unauthorized } from "../../authentication/SigninPrompt";
import withAuthorizationCheck from "../../HOC/withAuthorizationCheck";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";
const { styled } = StyledComponentContext._currentValue;

export const Error = styled.div`
    position: relative;
    top: -5px;
    left: 5px;
    font-size: 12px;
    font-style: italic;
    color: orangered;
`;

export const TwoColumnLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`;

function NewAccount() {
    const { getAccessTokenSilently } = useAuth0();
    const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { baseUrl } = useCurrentEnvironment();

    const onSubmit = (values) => {
        const addAccount = async () => {
            setLoading(true);
            fetch(`${baseUrl}/accounts`, PostPayload(await getAccessTokenSilently(), { ...values }))
                .then((res) => {
                    if (!res.ok) throw Error("Failed to add new account");
                    return res.json();
                })
                .then(async (json) => {
                    const { accountName } = json;
                    await refreshAccounts();
                    SuccessAlert().then(() => history.push(`/accounts/${accountName}`));
                })
                .catch((err) => {
                    ErrorAlert();
                    setLoading(false);
                    console.log("Error saving account", err);
                });
            // console.log({ ...values });
        };

        AreYouSureAlert({
            text: "Are you sure you want to submit this form with these values?",
        }).then(async ({ isConfirmed }) => {
            const { deverusUUID } = values.credential || {};
            if (!deverusUUID) isConfirmed && (await addAccount());
            else {
                CustomReactAlert({
                    title: "Dont forget this!",
                    icon: "warning",
                    html: DeverusCredential(),
                }).then(({ isConfirmed }) => {
                    if (isConfirmed) addAccount();
                });
            }
        });
    };

    return (
        <div className="center-content">
            <Breadcrumb>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => history.push("/accounts")}>
                        Accounts
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>New Account</Breadcrumb.Item>
                </Breadcrumb>
            </Breadcrumb>
            <ContentHeader>New Account</ContentHeader>
            <form>
                <Widget>
                    <AccountType register={register} errors={errors} />
                    <AccountName register={register} errors={errors} />
                    <AccountAddress register={register} errors={errors} />
                    <AccountContact register={register} errors={errors} />
                    <RequiredHint />
                </Widget>
                <SaveCancelButtons
                    visible={true}
                    saveText="Add account"
                    hideCancel={true}
                    onSave={handleSubmit(onSubmit)}
                    showSpinner={loading}
                    permissions={["account:create"]}
                />
            </form>
        </div>
    );
}

export default withAuthorizationCheck(
    ["account:create"],
    NewAccount,
    <Unauthorized subtext="You are not authorized to create new accounts" />
);
