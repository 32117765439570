/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";

const Environments = {
    PRODUCTION: {
        apiPath: "prod",
        label: "Production",
    },
    SANDBOX: {
        apiPath: "sdbx",
        label: "Sandbox",
    },
    DEVELOPMENT: {
        apiPath: "dev",
        label: "Development",
    },
};

const HIVEMIND_BASE_URI = "https://hivemind.tmlinfo.com";

const setEnvironment = (environment) => {
    localStorage.setItem("env", JSON.stringify(environment));
    window.location.reload();
};

export const useEnvironment = () => {
    const env = getStoredEnv();
    if (!env) setEnvironment(Environments.PRODUCTION);

    const data = {
        environment: env,
        setEnvProd: () => setEnvironment(Environments.PRODUCTION),
        setEnvSdbx: () => setEnvironment(Environments.SANDBOX),
        setEnvDev: () => setEnvironment(Environments.DEVELOPMENT),
    };

    return useQuery({
        queryKey: ["environment"],
        queryFn: () => data,
    });
};

export const useCurrentEnvironment = () => {
    const env = getStoredEnv();
    const apiPath = env?.apiPath ? env.apiPath : Environments.PRODUCTION.apiPath;
    return { baseUrl: `${HIVEMIND_BASE_URI}/${apiPath}` };
};

function getStoredEnv() {
    let parsed = null;
    try {
        const cached = localStorage.getItem("env");
        parsed = JSON.parse(cached);
    } catch (e) {}
    return parsed;
}
