/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Widget from "src/widgets/Widget";
import { Badge } from "react-bootstrap";
import WebhookListItemContent from "./WebhookListItemContent";
import NewWebhookModal from "./NewWebhookModal";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
import { useWebhooks } from "src/hooks/react-query/accounts/useWebhooks";
import { patternMatchesPattern } from "src/hooks/react-query/accounts/useWebhooks";

// const UpdatePermissions = ["account:webhook"];

export default function WebhookList({ account, permissions }) {
    const [showModal, setShowModal] = useState(false);

    const { data, error, isLoading, refetch } = useWebhooks(account.name);
    const webhooks = data?.webhooks;
    const schemas = data?.schemas;

    const webhookList = webhooks?.map((webhook, idx) => {
        const { urlPattern, authSchemaId } = webhook;
        const schemeIdx = schemas?.findIndex((s) => s.id === authSchemaId);
        const currentSchema = schemas?.[schemeIdx];
        const badgeColor = currentSchema ? "success" : "danger";

        const conflicts = webhooks?.filter((w) => {
            if (urlPattern === w.urlPattern) return false;
            return (
                patternMatchesPattern(w.urlPattern, urlPattern) ||
                patternMatchesPattern(urlPattern, w.urlPattern)
            );
        });

        const badge = (
            <div style={{ display: "flex", gap: 10 }}>
                {conflicts.length ? (
                    <Badge pill variant="danger">
                        CONFLICT
                    </Badge>
                ) : null}
                <Badge pill variant={badgeColor}>
                    {currentSchema ? "SECURED" : "NO AUTH"}
                </Badge>
            </div>
        );

        return (
            <Widget.ListItem
                key={idx}
                label={<div className="monospace">{urlPattern}</div>}
                value={badge}>
                <WebhookListItemContent
                    key={JSON.stringify(webhook)}
                    account={account}
                    currentWebhook={webhook}
                    schemas={schemas}
                    permissions={permissions}
                    refresh={refetch}
                />
            </Widget.ListItem>
        );
    });

    const AddWebhookButton = withAuthorizationCheck(
        permissions,
        <Widget.Button text="Add" onClick={() => setShowModal(true)} />
    );

    return (
        <React.Fragment>
            <Widget
                loading={isLoading}
                header={
                    <Widget.Header info={headerInfo()} button={<AddWebhookButton />}>
                        Allowed Patterns
                    </Widget.Header>
                }>
                {error && <span>ERROR: Could not fetch webhooks</span>}
                {!error && webhookList?.length && webhookList}
            </Widget>
            {!isLoading && (
                <NewWebhookModal
                    account={account}
                    schemas={schemas}
                    visible={showModal}
                    onSave={refetch}
                    onHide={() => setShowModal(false)}
                    permissions={permissions}
                />
            )}
        </React.Fragment>
    );

    function headerInfo() {
        return (
            <span>
                Patterns determine what URLs this customer is allowed to use for order postback.
                These can be exact URLs or they can contain wildcard (<code>*</code>) characters
                that will allow dynamic values to appear in the URL.
                <br />
                For instance, <code>https://company.com</code> will allow the customer to use their
                own domain, while <code>https://company.com/*</code> will allow them to submit
                dynamic values in addition to their domain such as:{" "}
                <span className="monospace">https://company.com/my-ref-id</span>.
                <hr />
                <b>Note:</b>&nbsp;wildcard characters are not allowed in the domain portion (
                <code>https://company.com</code>) of the pattern. These can only be used after the
                domain portion of the pattern.
            </span>
        );
    }
}
