import React from "react";
import { Button } from "react-bootstrap";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, max-content);
    column-gap: 10px;
    justify-content: ${({ alignLeft }) => (alignLeft ? "start" : "end")};
`;

export default function EditCloseButtons(props) {
    const { onEdit, onClose, hideEdit, hideClose } = props;

    const closeButton = hideClose ? null : (
        <Button key="close" variant="outline-secondary" size="sm" onClick={onClose}>
            Close
        </Button>
    );
    const editButton = hideEdit ? null : (
        <Button key="edit" variant="secondary" size="sm" onClick={onEdit}>
            Edit
        </Button>
    );

    const buttons = [closeButton, editButton].filter((b) => b !== null);

    return (
        <Container alignLeft={props.alignLeft} columns={buttons.length}>
            {buttons}
        </Container>
    );
}
