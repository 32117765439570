import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Col, ColsWrapper, Row, RowsWrapper } from "react-grid-resizable";
import { resizeEditor } from "../../../functions/ResizeEditor";
import { GetUserSettings, EditorLayout, State } from "../../../models/Settings";
import { LeftEditorContext, LeftEditorProvider } from "../../../contexts/LeftEditorContext";
import { RightEditorContext, RightEditorProvider } from "../../../contexts/RightEditorContext";
import Editor from "./Editor";
import ControlGroup from "./ControlGroup";
import { NoData } from "./Fillers";

export default function EditorGroup({ noData, transactionId }) {
    /*
     * =======================
     *      PAGE SETTINGS
     * =======================
     */
    const userSettings = GetUserSettings();
    const [searchMirror, setSearchMirror] = useState(userSettings.searchMirror === State.ENABLED);
    const [viewVertical, setViewVertical] = useState(
        userSettings.editorLayout === EditorLayout.VERTICAL
    );

    /*
     * ===================
     *      LEFT SIDE
     * ===================
     */
    const left = {
        controls: (
            <ControlGroup context={LeftEditorContext} readOnly={false} queryParamPrefix={"l"} />
        ),
        editor: <Editor id="left-editor" context={LeftEditorContext} selectionQueryParam={"ls"} />,
    };

    /*
     * ====================
     *      RIGHT SIDE
     * ====================
     */
    const right = {
        controls: (
            <ControlGroup
                context={RightEditorContext}
                readOnly={searchMirror}
                queryParamPrefix={"r"}
            />
        ),
        editor: (
            <Editor id="right-editor" context={RightEditorContext} selectionQueryParam={"rs"} />
        ),
    };

    /*
     * =====================
     *      SEARCH LINK
     * =====================
     */
    const searchLinkButton = () => {
        const broken = searchMirror ? "" : "-broken";
        const title = searchMirror ? "Disable search mirroring" : "Enable search mirroring";
        return (
            <span
                id="search-link"
                className={`ptr hov-blue tr-fast chain${broken}`}
                title={title}
                onClick={() => {
                    setSearchMirror(!searchMirror);
                }}
            />
        );
    };

    /*
     * ===================
     *      VIEW MODE
     * ===================
     */
    const viewModeButton = () => {
        const orientation = viewVertical ? "" : " rot-90";
        const title = `Switch to ${viewVertical ? "horizontal" : "vertical"} layout`;
        return (
            <span
                id="view-mode"
                className={`ptr hov-blue tr-fast${orientation}`}
                title={title}
                onClick={() => {
                    setViewVertical(!viewVertical);
                    resizeEditor(200);
                }}
            />
        );
    };

    const editors = viewVertical ? (
        <ColsWrapper>
            <Col>{left.editor}</Col>
            <Col>{right.editor}</Col>
        </ColsWrapper>
    ) : (
        <RowsWrapper>
            <Row>{left.editor}</Row>
            <Row>{right.editor}</Row>
        </RowsWrapper>
    );

    // Resize the editor when the vertical layout is adjusted
    const row = document.getElementsByClassName("dUUMQs")[0];
    row && new ResizeObserver(resizeEditor).observe(row);

    /*
     * ===================
     *      COMPONENT
     * ===================
     */
    return noData ? (
        <NoData />
    ) : (
        <LeftEditorProvider transactionId={transactionId}>
            <RightEditorProvider transactionId={transactionId} searchMirror={searchMirror}>
                <div id="editor-group">
                    <Form.Row>
                        {left.controls}
                        <span id="vm-wrapper">
                            {searchLinkButton()}
                            {viewModeButton()}
                        </span>
                        {right.controls}
                    </Form.Row>
                    {editors}
                </div>
            </RightEditorProvider>
        </LeftEditorProvider>
    );
}
