import React from "react";
// import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Alert } from "react-bootstrap";
import { Loading } from "../reusable/Loading";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
import { REDIRECT_URI } from "../../enums/Endpoints";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useLocation } from "react-router-dom";
const { styled } = StyledComponentContext._currentValue;

const SigninPromptWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-radius: 10px;
    padding: 60px;

    & > i {
        font-size: 8em;
    }

    & > h1 {
        margin-bottom: 0px;
    }

    button {
        margin-top: 10px;
    }
`;

const Disclaimer = (
    <Alert
        variant="danger"
        style={{
            textAlign: "center",
            position: "fixed",
            width: "100%",
            top: 60,
        }}>
        <div>
            <div>
                <b>DISCLAIMER:</b> System usage may be monitored, recorded, and subject to audit.
                Unauthorized use of the system is prohibited and subject to administrative,
                criminal, and civil penalties.
            </div>
        </div>
    </Alert>
);

/**
 * This is effectively the exact same as the AccessDenied
 * component, however, it doesn't say access denied. Honestly,
 * this component should probably handle both. Perhaps a boolean
 * flag can be added to the props that determines whether or not
 * this displays "Sign In" or "Access Denied".
 *
 * @param props.header the heading to display
 * @param props.subtext the text to display below the header
 * @param props.buttonText the text to display on the button that will redirect to Auth0
 * @param props.icon the icon to display above the heading
 */
const SigninPrompt = ({
    header = "Sign-in Required",
    subtext = "",
    buttonText = "Sign in with Auth0",
    icon = <i className="fas fa-user-shield" />,
}) => {
    const location = useLocation();
    const { isLoading, logout, loginWithRedirect } = useAuth0();
    const { darkMode } = useContext(ThemeContext);

    const switchAccount = () => {
        logout();
        loginWithRedirect({
            redirectUri: `${REDIRECT_URI}?referrer='${location.pathname}?${location.search}'`,
            prompt: "select_account",
            // appState: { returnUrl: window.location.href },
            appState: { returnUrl: location.pathname + location.search },
        });
    };

    if (isLoading) return <Loading />;
    return (
        <React.Fragment>
            {Disclaimer}
            <div className="no-content">
                <SigninPromptWrapper darkMode={darkMode}>
                    {icon}
                    <h1>{header}</h1>
                    <p>{subtext}</p>
                    <div>
                        <Button onClick={switchAccount} variant="primary">
                            {buttonText}
                        </Button>
                    </div>
                </SigninPromptWrapper>
            </div>
        </React.Fragment>
    );
};

export default SigninPrompt;

// SigninPrompt.propTypes = {
//     icon: PropTypes.element,
//     header: PropTypes.string,
//     subtext: PropTypes.string,
//     buttonText: PropTypes.string,
// };

export const Unauthorized = (props) => (
    <SigninPrompt
        header="Unauthorized"
        buttonText="Use Another Account"
        icon={<i className="fas fa-lock" />}
        {...props}
    />
);
