import React, { useState, useContext, useEffect } from "react";
import { Table, DropdownButton, Dropdown } from "react-bootstrap";
import { ThemeContext } from "../../contexts/ThemeContext";
import { FilterObjectCollection } from "../../functions/SearchFunctions";
import Widget from "../../widgets/Widget";
import { GridLayout } from "../styled_components/Layouts";
import { ErrorPrompt, NoResults, SearchPrompt } from "../transactions/transaction/Fillers";
import { Loading } from "./Loading";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

const FilterInputGroup = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr auto auto;
    column-gap: 0;
    justify-content: end;

    & > span > input {
        border-radius: 4px 0 0 4px;
        height: 36px;
    }

    & > .dropdown {
        & > button {
            border-color: ${({ darkMode }) => (darkMode ? "#5e5e5e" : "#e6e6e6")};
            color: ${({ darkMode }) => (darkMode ? "#a0a0a0" : "#afafaf")};
            height: 37px;
            font-size: inherit;

            &:hover {
                color: ${({ darkMode }) => (darkMode ? "whitesmoke" : "var(--gray-dark)")};
            }
        }

        &:first-of-type {
            & > button {
                border-radius: 0;
                border-left: none !important;
                border-right: none !important;
            }
        }
        &:last-of-type {
            & > button {
                border-radius: 0 4px 4px 0;
            }
        }
    }
`;

/**
 *  This component turns object arrays into a sortable and filterable table.
 *
 * @param {Array} params.collection an array of objects
 * @param {Array} params.columns an array of column names and their respective object properties.
 *      The list of properties for each column object is as follows:
 *          1. tableHeading (String): the display value for the column
 *          2a. objProperty (String): the name of the object property corresponding to this column
 *          2b. objPropertyRegex (String): regex to match against the name of the object property instead of specifying the object property directly
 *          3. valueFunction (Function): a function that provides the intended value if the specified object property does not yield the desired result
 *          4. valueFormatter (Function): function to transform the format of the value
 *          5. style (Object): style to apply to cells in this column
 *      NOTE: Parameters 1 & 2 are required, all others are optional;
 * @param {Array} params.indexed will add index to left-most column if set to true
 * @param {Array} params.onClickRow called with the object associated with the row when the row is clicked
 * @param {Array} params.onFilter function called with the filtered results
 */
function ResultsTable({
    collection,
    columns,
    indexed,
    onClickRow,
    onFilter,
    filterPlaceholder,
    loading,
    error,
    search: defaultSearch,
    searchRef,
}) {
    const { darkMode } = useContext(ThemeContext);
    const [search, setSearch] = useState(defaultSearch || "");
    const [oppositeSearch, setOppositeSearch] = useState(false);
    const [filterColumnIdx, setFilterColumnIdx] = useState(null);
    const [filtered, setFiltered] = useState(collection);
    const [reverseSort, setReverseSort] = useState(false);
    const [sortType, setSortType] = useState(columns[0]);

    const sortFunction = (sortType) => (a, b) => {
        const sortValue = sortType.objProperty
            ? sortType.objProperty
            : Object.keys(a).find((k) => sortType.objPropertyRegex.test(k));

        switch (typeof a[sortValue]) {
            case "number":
                return a[sortValue] - b[sortValue];
            default:
                // Keep falsy values on top of acending order
                if (!a[sortValue]) return !b[sortValue] ? 0 : -1;
                if (!b[sortValue]) return !a[sortValue] ? 0 : 1;
                // Compare truthy values
                return String(a[sortValue]).localeCompare(String(b[sortValue]));
        }
    };

    const handleSort = (columnName) => {
        if (sortType === columnName) return setReverseSort(!reverseSort);
        setSortType(columnName);
        setReverseSort(false);
    };

    useEffect(() => {
        const filtered = FilterObjectCollection({
            search,
            columns,
            collection,
            column: columns[filterColumnIdx],
            oppositeSearch,
        });
        setFiltered(filtered);
        onFilter && onFilter(filtered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, setFiltered, collection, oppositeSearch, filterColumnIdx]);

    const matchOptions = ["Matches", "Doesn't match"];
    const columnDropdownOptions = columns.map((c) => c.tableHeading);

    if (collection?.length > 0) {
        return (
            <div style={{ position: "relative", width: "100%" }}>
                <GridLayout columns="2" marginBottom="5px" marginTop="8px">
                    <span>
                        <span>RESULTS:&nbsp;</span>
                        <b>{filtered?.length}</b>
                    </span>
                    <FilterInputGroup darkMode={darkMode}>
                        <Widget.Input
                            ref={searchRef}
                            type="text"
                            placeholder={filterPlaceholder || "Filter results"}
                            value={search}
                            onChange={setSearch}
                            style={{ marginBottom: "5px" }}
                        />
                        <DropdownButton
                            onSelect={(value) => setOppositeSearch(JSON.parse(value))}
                            variant={darkMode ? "outline-secondary" : "outline-light"}
                            title={oppositeSearch ? matchOptions[1] : matchOptions[0]}>
                            <Dropdown.Item eventKey={false}>{matchOptions[0]}</Dropdown.Item>
                            <Dropdown.Item eventKey={true}>{matchOptions[1]}</Dropdown.Item>
                        </DropdownButton>
                        <DropdownButton
                            onSelect={setFilterColumnIdx}
                            variant={darkMode ? "outline-secondary" : "outline-light"}
                            title={columnDropdownOptions[filterColumnIdx] || "Any"}>
                            <Dropdown.Item eventKey={-1}>Any</Dropdown.Item>
                            <Dropdown.Divider />
                            {columnDropdownOptions.map((heading, idx) => (
                                <Dropdown.Item key={idx} eventKey={idx}>
                                    {heading}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </FilterInputGroup>
                </GridLayout>

                <div className="custom-scroll" style={{ overflowX: "scroll" }}>
                    <Table striped bordered hover variant={darkMode ? "dark" : "light"} size="sm">
                        <TableHeadings
                            columns={columns}
                            sortType={sortType}
                            handleSort={handleSort}
                            reverseSort={reverseSort}
                            indexed={indexed}
                        />
                        <TableRows
                            columns={columns}
                            collection={filtered}
                            sortFunction={sortFunction(sortType)}
                            reverseSort={reverseSort}
                            onClick={onClickRow}
                            indexed={indexed}
                        />
                    </Table>
                </div>
            </div>
        );
    } else {
        let content = <SearchPrompt />;

        if (loading) content = <Loading />;
        else if (error) content = <ErrorPrompt />;
        else if (collection?.length === 0) content = <NoResults />;

        const divStyle = { position: "relative", marginTop: "50px" };
        return <div style={divStyle}>{content}</div>;
    }
}

export default ResultsTable;

const TableHeadings = ({ columns, sortType, handleSort, reverseSort, indexed }) => {
    const columnHeadings = columns.map((column, index) => {
        const isActiveColumn = sortType.tableHeading === column.tableHeading;
        const activeStyle = isActiveColumn && { textDecoration: "underline" };
        const caretType = reverseSort ? "caret-down" : "caret-up";
        return (
            <th key={index} className="ptr" onClick={() => handleSort(column)}>
                <span style={{ ...activeStyle, whiteSpace: "nowrap" }}>
                    {column.tableHeading.replace(/_/g, " ").toUpperCase()}
                </span>
                {isActiveColumn && (
                    <span className={`${caretType}`} style={{ marginLeft: "5px" }} />
                )}
            </th>
        );
    });
    return (
        <thead>
            <tr>
                {indexed && <th>#</th>}
                {columnHeadings}
            </tr>
        </thead>
    );
};

const TableRows = ({ columns, collection, sortFunction, reverseSort, onClick, indexed }) => {
    if (!collection) return null;

    collection.sort(sortFunction);
    if (reverseSort) collection.reverse();

    const rows = collection.map((obj, collectionIdx) => {
        const cells = columns.map((column, columnIdx) => {
            const { objProperty, objPropertyRegex, valueFunction, valueFormatter, style } = column;
            // prettier-ignore
            let value = valueFunction
                ? valueFunction(obj)
                : objProperty
                    ? obj[objProperty]
                    : Object.entries(obj).find(([k]) => objPropertyRegex.test(k))?.[1];
            if (valueFormatter) value = valueFormatter(value);
            return (
                <td key={`${collectionIdx}${columnIdx}`} style={style}>
                    {value}
                </td>
            );
        });

        return (
            <tr
                key={collectionIdx}
                onClick={() => onClick && onClick(obj)}
                style={{ cursor: onClick ? "pointer" : "default" }}>
                {indexed && <td>{++collectionIdx}</td>}
                {cells}
            </tr>
        );
    });

    return <tbody>{rows}</tbody>;
};
