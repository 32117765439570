/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { DateTime } from "luxon";
import { FilterObjectCollection } from "../../../../../functions/SearchFunctions";
import Widget from "../../../../../widgets/Widget";
import { DeletePayload, PostPayload, PutPayload } from "../../../../../functions/Http";
import { AreYouSureAlert, ErrorAlert, SuccessAlert } from "../../../../reusable/Alerts";
import NoteModal from "./NoteModal";
import withAuthorizationCheck from "../../../../HOC/withAuthorizationCheck";
import { useNotes } from "src/hooks/react-query/accounts/useNotes";
import { useMutateNotes } from "src/hooks/react-query/accounts/useNotes";
import { useAuth0 } from "@auth0/auth0-react";

export const FormatDate = (date) => DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);

const Notes = ({ account }) => {
    // const { user, token } = useContext(UserContext);
    const { user } = useAuth0();
    // const [notes, setNotes] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [showEditModal, setShowEditModal] = useState(null);
    const [currentNote, setCurrentNote] = useState(null);
    const [saving, setSaving] = useState(false);
    const [search, setSearch] = useState("");
    const searchRef = useRef(null);

    const { data, isLoading, error, refetch } = useNotes(account.name);
    const notes = data?.notes;

    const updateNotes = useMutateNotes({
        accountName: account.name,
        onSuccess: () =>
            SuccessAlert().then(() => {
                refetch();
                setShowEditModal(false);
                setSaving(false);
            }),
        onError: () => {
            ErrorAlert();
            setSaving(false);
        },
    });

    /**
     * Filters the displayed notes when the user
     * types something in the search bar.
     */
    useEffect(() => {
        const filtered = FilterObjectCollection({
            search,
            collection: notes,
        });
        setFiltered(filtered);
    }, [search, notes]);

    const searchBar = <Widget.SearchBar ref={searchRef} search={search} setSearch={setSearch} />;
    const noteList = filtered?.map((note) => (
        <Widget.ListItem
            key={note.id}
            label={note.title}
            subtext={getMostRecentDate(note)}
            value={<small>{note.author}</small>}
            onClick={async () => showModal(note)}
            // options={noteOptions(note)}
        />
    ));

    const AddNoteButton = withAuthorizationCheck(
        ["account:note:create"],
        <Widget.Button text="Add note" onClick={() => showModal({})} />
    );

    return (
        <Widget
            loading={isLoading}
            label="Notes"
            searchBar={notes?.length > 0 && searchBar}
            button={<AddNoteButton />}>
            {error && (
                <p>
                    <b>Error:</b> Unable to load notes
                </p>
            )}
            {noteList?.length ? noteList : null}
            {showEditModal && getModal()}
        </Widget>
    );

    /*
     * ===================
     *      Functions
     * ===================
     */

    /**
     * Shows the most recent date between the insert date and updated date
     * and formats the date to be readable.
     *
     * @param {Object} note object containing all note fields
     */
    function getMostRecentDate(note) {
        if (note.timeUpdated)
            return (
                <span>
                    {FormatDate(note.timeUpdated)}
                    <i>&nbsp;(updated)</i>
                </span>
            );
        else return FormatDate(note.timeInserted);
    }

    /**
     * This handles both saving and updating a note. This function
     * returns another function that actually handles the saving that
     * must be passed the title and text of the new or current note.
     *
     * If it is a new note that is being added, the noteId should be
     * null, otherwise, the noteId being passed in will be updated with
     * the title and text provided.
     *
     * @param {Number} noteId the id of the note being saved
     */
    function handleSave(noteId) {
        return ({ title, text }) => {
            setSaving(true);
            const payload = noteId ? PutPayload : PostPayload;
            updateNotes.mutate((token) => {
                return payload(token, {
                    id: noteId,
                    title,
                    text,
                    accountId: account.id,
                    email: user.email,
                });
            });
        };
    }

    /**
     * Marks a note as deleted in the database.
     *
     * @param {Object} note object containing all note fields
     */
    async function handleDelete(note) {
        const { isConfirmed } = await AreYouSureAlert({
            html: (
                <div>
                    <span>
                        Mark note <i>'{note.title}'</i> as
                        <span className="disable"> deleted</span>?
                    </span>
                </div>
            ),
        });
        if (!isConfirmed) return;
        updateNotes.mutate((token) => {
            return DeletePayload(token, {
                noteId: note.id,
                email: user.email,
            });
        });
    }

    /**
     * The modal is directly linked to the current note, therefore,
     * this function ensures that the correct note will be displayed
     * in the modal when it is shown.
     *
     * @param {Object} note object containing all note fields
     */
    function showModal(note) {
        setCurrentNote(note);
        setShowEditModal(true);
    }

    /**
     * Returns a modal populated with the current note's title and text.
     */
    function getModal() {
        return (
            <NoteModal
                key={currentNote}
                note={currentNote}
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                saving={saving}
                onSave={handleSave(currentNote?.id)}
                onDelete={() => handleDelete(currentNote)}
            />
        );
    }
};

export default Notes;

/**
 * Array of options that will appear under each note.
 * Tha name is the display, the title is shown on hover,
 * and the handler is called when clicked.
 *
 * @param {Object} note object containing all note fields
 */
// function noteOptions(note) {
//     return [
//         {
//             name: "Edit",
//             title: `Edit "${note.title}"`,
//             handler: async () => (await ConfirmUser()) && showModal(note),
//         },
//         {
//             name: "Hide",
//             title: `Hide "${note.title}"`,
//             handler: () => handleDelete(note),
//         },
//         {
//             name: "More...",
//             title: "See options related to this note",
//             handler: async () => (await ConfirmUser()) && showModal(note),
//         },
//     ];
// }
