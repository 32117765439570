import React, { useState, useContext } from "react";
import { Badge, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { ZonesByState, ConvertTimeZone } from "../../../enums/TimeZones";
import { DropdownActiveClass } from "../../../enums/DropdownValues";
import { ThemeContext } from "../../../contexts/ThemeContext";
import InfoSectionAction from "./InfoSectionAction";
import { AreYouSureAlert, SuccessAlert, RefreshAlert } from "../../reusable/Alerts";
import { PostPayload, DeletePayload } from "../../../functions/Http";
import { useCommand } from "src/hooks/react-query/transactions/useCommand";
import { useMutateVerification } from "src/hooks/react-query/transactions/useVerification";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorAlert } from "src/components/reusable/Alerts";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

const ActionsWrapper = styled.div`
    display: grid;
    align-items: start;
    gap: 15px;
    width: 130px;
`;

/**
 * Represents `null` or `undefined` values as "N/A"
 *
 * @param value the value
 * @returns "N/A" if the value is null or undefined, otherwise just the value
 */
const valuify = (value) => value || "N/A";

export const StatusInfo = ({ disposition, time_inserted, time_completed, time_modified }) => {
    const completed = /^completed$/i.test(disposition);
    const error = /(stuck|error)/i.test(disposition);
    const variant = completed ? "success" : error ? "danger" : "warning";
    const { outlineVariant } = useContext(ThemeContext);
    const [tz, setTz] = useState("UTC");

    return (
        <div>
            <div style={{ marginBottom: "1rem" }}>
                <b>Status:</b>
                <Badge id="status" variant={variant} className="mr-3 ml-0">
                    {disposition}
                </Badge>
                <b>Time Zone:</b>
                <span id="tz-span">
                    <DropdownButton
                        as={InputGroup.Append}
                        id="tz-dropdown"
                        variant={outlineVariant}
                        title={tz}
                        size="sm"
                        onSelect={setTz}>
                        {Object.keys(ZonesByState).map((zone, index) => (
                            <Dropdown.Item
                                key={index}
                                className={tz === zone ? DropdownActiveClass : ""}
                                eventKey={zone}>
                                {zone}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </span>
            </div>
            <p>
                <b>Started:</b>
                <span id="started">{ConvertTimeZone(time_inserted, tz)}</span>
            </p>
            <p>
                <b>Ended:</b>
                <span id="ended">{ConvertTimeZone(time_completed, tz)}</span>
            </p>
            <p>
                <b>Modified:</b>
                <span id="modified">{ConvertTimeZone(time_modified, tz)}</span>
            </p>
        </div>
    );
};

export const CustomerInfo = ({
    request_state,
    account,
    customers_customer,
    customers_identifier,
    request_license,
    customers_reason,
}) => {
    return (
        <div>
            <p>
                <b>State:</b>
                <span id="state" className="mr-4">
                    {valuify(request_state)}
                </span>
                <b>Account:</b>
                <span id="customer">
                    {valuify(account)}
                    {customers_customer && ` / ${customers_customer}`}
                </span>
            </p>
            <p>
                <b>Customer's Reference:</b>
                <span id="cust-ref">{valuify(customers_identifier)}</span>
            </p>
            <p>
                <b>License Number:</b>
                <span id="licenseNo" className="mr-4">
                    {valuify(request_license)}
                </span>
            </p>
            <p>
                <b>DPPA Reason:</b>
                <span id="reason">{valuify(customers_reason)}</span>
            </p>
        </div>
    );
};

export const Actions = ({ verification, transaction, refresh }) => {
    const { trans_uuid } = transaction;
    const CoreCommands = {
        UNSTICK: "UNSTICK",
        RESTART: "RESTART",
    };

    const { user } = useAuth0();
    const verify = useMutateVerification({
        uuid: trans_uuid,
        onSuccess: () => SuccessAlert().then(refresh),
        onError: () => ErrorAlert(),
    });
    const { canVerify, verified } = verification.data ?? {};

    const unstickCommand = useCommand({
        uuid: trans_uuid,
        command: CoreCommands.UNSTICK,
        onSuccess: () => SuccessAlert().then(() => RefreshAlert(refresh)),
    });

    return (
        <div>
            <p>
                <b>Actions:</b>
            </p>
            <ActionsWrapper>
                <InfoSectionAction
                    permissions={["transaction:basic-unstick"]}
                    btnText="Unstick"
                    infoText={`This action will queue this transaction to be re-run
                        through the system (starting with the normalization process)
                        and be sent to the customer again.`}
                    loading={unstickCommand.isLoading}
                    iconClassName="fas fa-redo-alt"
                    onClick={sendUnstick}
                />
                {canVerify && (verified ? unverifyButton() : verifyButton())}
            </ActionsWrapper>
        </div>
    );

    function sendUnstick() {
        const text = "This transaction will be retried and sent to the customer again.";
        AreYouSureAlert({ text }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            unstickCommand.mutate((token) =>
                PostPayload(token, {
                    uuid: trans_uuid,
                    command: CoreCommands.UNSTICK,
                })
            );
        });
    }

    function sendVerified(verified) {
        const text = "This transaction will not be validated after the next 'unstick'.";
        AreYouSureAlert({ text }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            const payload = verified ? PostPayload : DeletePayload;
            verify.mutate((token) =>
                payload(token, {
                    uuid: trans_uuid,
                    email: user.email,
                })
            );
        });
    }

    // function refresh() {
    //     transaction.refetch().then(() => console.log("Refetched transaction"));
    //     verification.refetch().then(() => console.log("Refetched verification"));
    // }
    function verifyButton() {
        const infoText = `Stop validation from happening the next time an "Unstick"
                is performed on this record.`;
        const subText = `WARNING: be absolutely certain that a record is fit for
                sending before doing this.`;
        return (
            <InfoSectionAction
                permissions={["transaction:verify"]}
                btnText={"Verify"}
                title={"Verify this record"}
                infoText={infoText}
                infoSubText={subText}
                loading={verification.isLoading}
                iconClassName="fas fa-check"
                btnVariant={"success"}
                onClick={() => sendVerified(true)}
            />
        );
    }

    function unverifyButton() {
        const infoText = `Remove verification from this transaction so that it will
                not skip validation the next time it is processed for postback.`;
        return (
            <InfoSectionAction
                permissions={["transaction:verify"]}
                btnText={"Unverify"}
                title={"Remove verification for this record"}
                infoText={infoText}
                loading={verification.isLoading}
                iconClassName="fas fa-ban"
                btnVariant={"danger"}
                onClick={() => sendVerified(false)}
            />
        );
    }
};
