import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import {
    InitSettings,
    InitPresets,
    GetUserSettings,
    Themes,
    DefaultSettings,
} from "../../models/Settings";
import { SearchPresetsContext } from "../../contexts/SearchPresetsContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import { setHtmlDarkTheme } from "../../functions/ColorTheme";

/**
 * Buttons on the settings page that reset defaults and clear search presets.
 */
export const ResetButtons = () => {
    const { setSettings } = useContext(SettingsContext);
    const [, setPresets] = useContext(SearchPresetsContext);
    const { setDarkMode } = useContext(ThemeContext);

    /**
     * Resets all default settings (does not affect search presets).
     */
    const resetSettings = () => {
        window.confirm(`Are you sure you want to reset the default settings?
                        \nThis will not affect your search presets.`) &&
            (() => {
                InitSettings();
                setSettings(GetUserSettings());
                setDarkMode(Themes.DARK === DefaultSettings.theme);
                setHtmlDarkTheme(Themes.DARK === DefaultSettings.theme);
            })();
    };

    /**
     * Removes all search presets (does not affect default settings).
     */
    const clearPresets = () => {
        window.confirm(`Are you sure you want to remove all search presets?
                        \nThis will not affect your other settings.`) &&
            window.confirm("Are you really sure? This can't be undone.") &&
            (() => {
                InitPresets();
                setPresets([]);
            })();
    };

    return (
        <span id="reset-buttons">
            <Button
                id="reset-button"
                pill="true"
                variant="outline-danger"
                size="sm"
                onClick={() => resetSettings()}>
                Reset Defaults
            </Button>
            <Button
                id="clear-presets-button"
                pill="true"
                variant="outline-danger"
                size="sm"
                onClick={() => clearPresets()}>
                Clear Presets
            </Button>
        </span>
    );
};
