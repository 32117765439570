import React from "react";
import { Button } from "react-bootstrap";

const ClearButton = ({ onClick, text, title }) => {
    return (
        <Button
            onClick={onClick}
            title={title || "Clear results"}
            pill="true"
            variant="outline-warning"
            size="sm">
            <i className="fas fa-eraser" />
            <span>&nbsp;{text || "Clear"}</span>
        </Button>
    );
};

export default ClearButton;

// FilterTransactions title: "Clear results and filters"
