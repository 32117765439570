import React from "react";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

const StyledExternalLink = styled.div`
    a {
        color: inherit;

        &:hover {
            color: inherit;
        }

        i {
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            content: "\f35d";
            margin-left: 0.5rem;
            position: relative;
            top: -1px;
            text-decoration: none !important;
        }
    }
`;

export default function ExternalLink({ children, href, style }) {
    return (
        <StyledExternalLink style={style}>
            <a href={href} target="_blank" rel="noopener noreferrer">
                <span>{children}</span>
                <i className="fas fa-external-link-alt" />
            </a>
        </StyledExternalLink>
    );
}
