import React from "react";
import { useState } from "react";
import Widget from "../../../../widgets/Widget";
import { ErrorMessage } from "../../../reusable/ReactFormComponents";
import { v4 as uuid } from "uuid";
import { StyledComponentContext } from "../../../../contexts/StyledComponentsContext";
import { ValidURLRegex } from "../../../../enums/RegularExpressions";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
const { styled } = StyledComponentContext._currentValue;

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const AccountTypes = (permissions) => {
    const types = [];

    const typesByPermission = {
        "account:create:accio": { value: "ACCIO", label: "Accio" },
        "account:create:deverus": { value: "DEVERUS", label: "Deverus" },
        "account:create:kyd": { value: "KYD", label: "KYD" },
    };

    permissions.forEach((p) => typesByPermission[p] && types.push(typesByPermission[p]));
    types.push({ value: "DIRECT", label: "Direct" });

    return types;
};

const DeverusUUID = uuid();

export const CredentialWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    border: 2px solid;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 3px;
`;

const rootObjectName = "credential";

const AccountType = ({ register, errors }) => {
    const { decodedToken } = useContext(UserContext);
    const accountTypes = AccountTypes(decodedToken.permissions);
    const defaultAccountType = accountTypes[0];
    const [accountType, setAccountType] = useState(defaultAccountType.value);

    const AccioContent = (
        <React.Fragment>
            <Widget.Input
                type="text"
                name={`${rootObjectName}.accioId`}
                label="Accio Platform ID"
                placeholder="CD1234AB"
                required={true}
                inputAttributes={{ maxLength: 8 }}
                ref={register({
                    ...required,
                    pattern: {
                        value: /^\w{8}$/,
                        message: "Must be 8 alphanumeric characters and must not contain spaces",
                    },
                })}
                style={inputStyle}
            />
            {getErrorMessage("accioId")}
            <Widget.Input
                type="text"
                name={`${rootObjectName}.accioPass`}
                label="TML-Assigned Password"
                placeholder="*********"
                required={true}
                ref={register({
                    ...required,
                    pattern: {
                        value: /^\S{8}$/,
                        message: "Must be at least 8 characters and must not contain spaces",
                    },
                })}
                style={inputStyle}
            />
            {getErrorMessage("accioPass")}
            <Widget.Input
                type="text"
                name={`${rootObjectName}.accioWebhook`}
                label="Webhook"
                placeholder="https://customer.acciodata.com/c/p/researcherxml"
                required={true}
                ref={register({
                    ...required,
                    pattern: {
                        value: ValidURLRegex,
                        message: "Must be a valid URL",
                    },
                })}
                style={inputStyle}
            />
            {getErrorMessage("accioWebhook")}
        </React.Fragment>
    );

    const KYDContent = (
        <div style={{ margin: "10px 5px", fontStyle: "italic" }}>
            <b>NOTE:</b> The 'Account name' in the next section should match the name entered on the
            KYD platform.
        </div>
    );

    const DeverusContent = DeverusCredential(
        <input
            type="hidden"
            name={`${rootObjectName}.deverusUUID`}
            value={DeverusUUID}
            ref={register()}
        />
    );

    return (
        <span>
            <Widget.Spacer height={5} />
            <Widget.Header info={getInfo()}>Account Type</Widget.Header>
            <Widget.Select
                name={"accountType"}
                label="Type"
                options={accountTypes}
                defaultValue={defaultAccountType}
                optionValueProp={"value"}
                optionLabelProp={"label"}
                required={true}
                ref={register({
                    ...required,
                    validate: (value) => !!value,
                })}
                onSelect={setAccountType}
            />
            {getErrorMessage("type")}
            {accountType === "ACCIO" && AccioContent}
            {accountType === "KYD" && KYDContent}
            {accountType === "DEVERUS" && DeverusContent}
        </span>
    );

    function getErrorMessage(fieldName) {
        if (!errors[rootObjectName]) return;
        if (!errors[rootObjectName][fieldName]) return;
        return <ErrorMessage>{errors[rootObjectName][fieldName].message}</ErrorMessage>;
    }

    function getInfo() {
        return (
            <span>
                This informs how this client will be accessing TML's system. Depending on where the
                client is coming from, different information might be required to ensure proper
                setup.
                <hr />
                <b>Note:</b>&nbsp;only use the 'Direct' type if none of the others apply.
            </span>
        );
    }
};

export default AccountType;

export function DeverusCredential(input) {
    return (
        <div style={{ margin: "10px 5px" }}>
            <CredentialWrapper>{DeverusUUID}</CredentialWrapper>
            {input}
            <i>
                <b>Important:</b> Copy this credential to send to the client as this
                credential&nbsp;
                <b>cannot be viewed again</b> once this form is submitted.
            </i>
        </div>
    );
}
