import { useQuery } from "@tanstack/react-query";
import { GetPayload } from "src/functions/Http";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useSecureMutate } from "src/hooks/useSecureMutate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

export const usePendingList = () => {
    const { baseUrl } = useCurrentEnvironment();
    const { secureFetch } = useSecureFetch(`${baseUrl}/transactions/pending`);

    return useQuery({
        queryKey: ["transactions", "pending-list"],
        queryFn: async () => await secureFetch((token) => GetPayload(token)),
    });
};

export const useBulkUnstick = ({ onSuccess, onError }) => {
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({
        url: `${baseUrl}/transactions/bulk-unstick`,
        onSuccess,
        onError,
    });
};
