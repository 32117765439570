import React, { useContext } from "react";
import CredentialGroups from "./credential-groups/CredentialGroups";
import CredentialList from "./credential-list/CredentialList";
import { ThemeContext } from "src/contexts/ThemeContext";
import { Tabs, Tab } from "react-bootstrap";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
import WebhookList from "./webhook/webhook-list/WebhookList";
import AuthSchemaList from "./webhook/auth-schema-list/AuthSchemaList";
const { styled } = StyledComponentContext._currentValue;

const TabsWrapper = styled.div`
    nav {
        // display: grid;
        // grid-auto-flow: column;
        // grid-template-columns: 1fr 1fr;
        // text-align: center;
        width: 50%;
        margin: 10px auto 25px;
        // border: 1px solid ${({ darkMode }) => (darkMode ? "var(--light)" : "var(--secondary)")};
        // border-radius: 5px;
    }
`;

export const ListItemContentWrapper = styled.div`
    margin: 0 auto;
    padding: 0 20px 8px;
`;

export default function RestApiWidget(props) {
    const { darkMode } = useContext(ThemeContext);
    const requiredPermissions = ["rest:credential:manage"];
    return (
        <TabsWrapper darkMode={darkMode}>
            <Tabs
                defaultActiveKey="access"
                id="rest-api-tabs"
                // variant="pills"
                // className="nested-nav"
                justify>
                <Tab eventKey="access" title="Credentials">
                    <CredentialList account={props.account} permissions={requiredPermissions} />
                    <CredentialGroups account={props.account} permissions={requiredPermissions} />
                </Tab>
                <Tab eventKey="webhook" title="Webhooks">
                    <AuthSchemaList account={props.account} permissions={requiredPermissions} />
                    <WebhookList account={props.account} permissions={requiredPermissions} />
                </Tab>
            </Tabs>
        </TabsWrapper>
    );
}
