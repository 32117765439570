export const LoadingMessages = [
    // Movies & TV
    "Calling Johnny English",
    "Enlisting Ethan Hunt",
    "Enlisting Liam Neeson",
    "Dispatching from Elsewhere",
    "Lost the keys to the Bat-Mobile",
    "Invading the Death Star",
    "Escaping the Empire",
    "Joining the Rebels",
    "Loading into the Matrix",
    "Approaching 88mph",
    "Changing the Flux Capacitor",
    "Leaving the Overlook Hotel",
    "Discovering what makes Jack a dull boy",
    "Finding out what's in the case",
    "Learning what they call a quarter pounder with cheese in Paris",
    "Getting Baby out of the corner",
    "Getting to the chopper",
    "I'll be back",
    "Looking for a glass slipper",
    "Taking the red pill",

    //Misc
    "Asking Jeeves",
    "Lobbying Congress",
    "Asking for donations",
    "Investigating",
    "Doing a rain dance",
    "Avoiding detection",
    "Intimidating the Librarian",
    "Watching paint dry",
    "Psychoanalyzing Aurora",
    "Breaking the sound barrier",
    "Working on it",
    "Took the wrong exit",
    "Getting the A Team together",
    "The Geniuses weren't helpful",
    "Storming the data center",
    "Raiding the compound",
    "HALO jumping",
    "Petitioning the mayor",
    "Contacting an agent",
    "Looking for my sunglasses",
    "Getting rid of the evidence",
    "Going undercover",
    "Loading up the delivery vehicle",
    "Looking for the packaging tape",
    "Keeping a lookout",
    "Questioning the authorities",
    "Crafting a grilled cheese",
    "Not eating sushi",
    "Grabbing a disguise",
    "Creating a backstory",
    "Tuning the algorithm",
    "Preparing for lift off",
    "Navigating deep space",
    "Finding the center of a black hole",
    "Traveling through time",
    "Re-entering the atmosphere",
    "Bracing for impact",
    "Grasping at straws",
    "Practicing my swing",
    "Re-inventing the wheel",
    "Clearing a path",
    "Tunneling underground",
    "Taking a shortcut",
    "Using the secret passage",
    "Sneaking through the back door",
    "Dusting off the lab coat",
    "Planning a Tea Party in Boston",
    "Adjusting for inflation",
    "Calculating center mass",
    "Checking supply levels",
    "Establishing a connection",
    "Tipping the cows",
    "The turkey is in the oven",
    "Negotiating aggressively",
    "Pedal is to the metal",
    "Gearing up",
    "Asking a friend of a friend",
    "Documenting the experience",
    "Shaving the cat",
    "Getting the mayo",
    "Fixing up the wiring",
    "Jimmying the lock",
    "Beep boop bop",
    "Gathering intel",

    // Video games
    "Giving the covenant back their bomb",
    "Finishing the fight",
    "Rescuing Cortana",
    "Flying pretty good...for a brick",
    "Boarding High Charity...BOO",
    "Riding the never-ending gondola",
    "Mixing things up a little",
    "Becoming the dragonborn",
    "Harnessing my thuum",
    "Slaying a dragon",
    "Taking an arrow to the knee",
    "Climbing to High Hrothgar",
    "FUS RO DAH!",
    "Asking Paarthurnax",
    "Acquiring the Oghma Infinium",
    "Scavenging the Commonwealth",
    "Holding off the Super Mutants",
    "Clearing out the Rad Roaches",
    "Running from a Death Claw",
    "Boarding the Prydwen",
    "Doing chores for the Minute Men",
    "Helping the Railroad",
    "Searching for fusion cores",
    "Asking Nick Valentine",
    "Putting foolish ambitions to rest",
    "Panic rolling",
];
