import React from "react";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

const ErrorMessageWrapper = styled.div`
    position: relative;
    top: -5px;
    left: 5px;
    font-size: 12px;
    font-style: italic;
    color: #ff875c;
`;

export const ErrorMessage = ({ message, children }) => {
    if (!message && !children) return null;
    return <ErrorMessageWrapper>{message || children}</ErrorMessageWrapper>;
};
