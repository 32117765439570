import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { GetPayload } from "src/functions/Http";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useSecureMutate } from "src/hooks/useSecureMutate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";
import { ErrorAlert } from "src/components/reusable/Alerts";

const fetchUrl = (baseUrl, accountName) => `${baseUrl}/accounts/${accountName}/authorizations`;
const updateUrl = (baseUrl, accountName) => `${baseUrl}/accounts/${accountName}/details`;

export const useAccount = (accountName) => {
    const history = useHistory();
    const { baseUrl } = useCurrentEnvironment();
    const { secureFetch } = useSecureFetch(fetchUrl(baseUrl, accountName));

    const query = useQuery({
        queryKey: ["accounts", accountName],
        queryFn: async () => await secureFetch((token) => GetPayload(token)),
        onError: () => ErrorAlert("Account not found").then(() => history.push("/accounts")),
    });

    return query;
};

export const useMutateAccount = ({ accountName, onSuccess, onError }) => {
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({ url: updateUrl(baseUrl, accountName), onSuccess, onError });
};
