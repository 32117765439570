import React, { useState, useContext, useEffect } from "react";
import ResultsTable from "../../reusable/ResultsTable";
import ResultsTableHeader from "../../reusable/ResultsTableHeader";
import { FormatDate } from "../../../functions/FormatDate";
import TransactionFilters from "./TransactionFilters";
import { useForm } from "react-hook-form";
import { buildQueryString } from "../../../functions/QueryStrings";
import { CustomReactAlert } from "../../reusable/Alerts";
import withAuthorizationCheck from "../../HOC/withAuthorizationCheck";
import { Unauthorized } from "../../authentication/SigninPrompt";
import { useAuth0 } from "@auth0/auth0-react";
import { GetPayload } from "../../../functions/Http";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { Settings, LinkStyle } from "../../../models/Settings";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

/**
 * Custom alert message for transaction searches that failed.
 *
 * @param {string} message a detailed error message to display
 * @returns a popup alert
 */
const searchErrorAlert = (message) => {
    const msgStyle = {
        display: "inline-block",
        marginTop: 20,
        overflowWrap: "anywhere",
    };
    return CustomReactAlert({
        icon: "error",
        title: "Oops...",
        html: (
            <p>
                <span style={{ display: "block" }}>That search didn't work.</span>
                {message ? <span style={msgStyle}>{message}</span> : null}
            </p>
        ),
    });
};

/**
 * Configuration for <ResultsTable> element.
 */
const ResultsTableColumns = [
    { tableHeading: "Account", objProperty: "account" },
    { tableHeading: "End User", objProperty: "endUser" },
    { tableHeading: "Disposition", objProperty: "disposition" },
    { tableHeading: "State", objProperty: "state" },
    { tableHeading: "F. Name", objProperty: "firstName" },
    { tableHeading: "L. Name", objProperty: "lastName" },
    { tableHeading: "License", objProperty: "license" },
    {
        tableHeading: "Time Inserted",
        objProperty: "timeInserted",
        valueFormatter: FormatDate,
        style: { whiteSpace: "nowrap" },
    },
    {
        tableHeading: "Time Modified",
        objProperty: "timeModified",
        valueFormatter: FormatDate,
        style: { whiteSpace: "nowrap" },
    },
    {
        tableHeading: "Retries",
        objProperty: "retries",
        valueFormatter: (number) => (number ? number.toLocaleString() : 0),
    },
];

/**
 * Component for filtering through transactions in the database.
 *
 * @param {Object} props.location the react-router location
 * @param {Object} props.history the react-router history
 */
const FilterTransactions = ({ location, history }) => {
    const { baseUrl } = useCurrentEnvironment();
    const { settings } = useContext(SettingsContext);
    const { register, handleSubmit, getValues, errors } = useForm();
    const { getAccessTokenSilently } = useAuth0();
    const cachedFilters = location.state?.cachedFilters || [];

    const [transactions, setTransactions] = useState(location.state?.transactions || null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(location.state?.error || false);

    useEffect(() => {
        const envChanged = location.state?.baseUrl !== baseUrl;
        if (envChanged) setTransactions(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseUrl]);

    /**
     * Endpoint for filtering transactions.
     *
     * Takes the values submitted through the 'handleSubmit'
     * function and creates the necessary query string for
     * filtering the transactions and appends it to the url.
     *
     * @param {Object} values form values from 'handleSubmit'
     * @returns the full url for submitting a transaction query
     */
    const API_ENDPOINT = (values) => `${baseUrl}/transactions/filter${buildQueryString(values)}`;

    /*
     * ==============================
     *      Apply Search Filters
     * ==============================
     */
    /**
     * Handler for a user clicking the search button.
     *
     * When the search is completed, this function will push
     * a new history object on the stack with the filters and
     * results stored in 'location.state'.
     *
     * This way, the search results and the filters that were
     * used are stored in the routing history which allows
     * the user to go back to previous search results.
     *
     * Otherwise, the search would need to be run again and,
     * even with the same filters applied, different results
     * would likely be returned.
     *
     * @param {Object} values form values from 'handleSubmit'
     */
    const search = async (values) => {
        setLoading(true);
        setError(false);
        setTransactions(null);
        console.log(values);

        const saveResults = (transactions, searchFailed) =>
            history.push(location.pathname, {
                cachedFilters: { ...values },
                transactions,
                error: !!searchFailed,
                baseUrl,
            });

        fetch(API_ENDPOINT(values), GetPayload(await getAccessTokenSilently()))
            .then(async (res) => {
                const json = await res.json();
                if (res.status !== 200) throw json;
                return json;
            })
            .then(({ transactions }) => saveResults(transactions))
            .catch((err) => {
                let customMsg = err.errors?.[0].details.message.replace(/queryString\./g, "");
                searchErrorAlert(err.message || customMsg);
                console.log(err);
                setLoading(false);
                setError(true);
                saveResults(null, true);
            });
    };

    /**
     * Function specifically for automatic refreshing of data
     * that does not add state to history and does not remove
     * elements from the UI while fetching.
     *
     * @param {Object} values form values from 'handleSubmit'
     */
    const refresh = async (values) => {
        await fetch(API_ENDPOINT(values), GetPayload(await getAccessTokenSilently()))
            .then((res) => {
                const json = res.json();
                if (res.status !== 200) throw json;
                return json;
            })
            .then(({ transactions }) => setTransactions(transactions))
            .catch((err) => {
                console.log("Error fetching transactions", err);
                setError(true);
                setTransactions([]);
            });
    };

    /**
     * Handler for when a row is clicked in the results table.
     *
     * This function captures the state of this page before routing.
     * This is important because of how the auto-refreshing feature
     * works. When searches complete that are being auto-refreshed,
     * their state is not saved due to the component needing to
     * re-load as well as to save on memory.
     *
     * With this solution, the state of the page just before a record
     * is clicked is saved which allows the page to be returned to
     * even when a row was clicked during an auto-refresh cycle. For
     * the manual searching, this function will simply re-add the
     * same state to the current object in the history stack.
     *
     * @param {String} obj.trans_uuid the transaction uuid of the row
     */
    const onClickRow = ({ uuid }) => {
        settings[Settings.LINK_STYLE] === LinkStyle.CURRENT_TAB
            ? handleSubmit((values) => {
                  history.replace({
                      state: {
                          cachedFilters: { ...values },
                          transactions,
                          error,
                      },
                  });
                  history.push(`/transactions?id=${uuid}`, {
                      breadcrumbReturnsToPreviousPage: true,
                  });
              })()
            : window.open(`${window.location.origin}/transactions?id=${uuid}`);
    };

    /*
     * ===================
     *      Component
     * ===================
     */
    return (
        <div className="center-content">
            <ResultsTableHeader
                headerText="Transactions"
                hideActionButtons={loading}
                displayClearButton={transactions || error}
                onSearch={handleSubmit(search)}
                onClear={() => history.push(location.pathname)}
                refreshFunction={handleSubmit(refresh)}
                refreshDelay={10}
            />
            <TransactionFilters
                defaultValues={cachedFilters}
                register={register}
                getValues={getValues}
                errors={errors}
            />
            <ResultsTable
                indexed={true}
                columns={ResultsTableColumns}
                collection={transactions}
                loading={loading}
                error={error}
                onClickRow={onClickRow}
            />
        </div>
    );
};

export default withAuthorizationCheck(
    ["transaction:read"],
    FilterTransactions,
    <Unauthorized subtext="You are not authorized to view transactions" />
);
