import $ from "jquery";

export const setHtmlDarkTheme = (bool) => {
    $("html").attr("theme", bool ? "dark" : "light");
};

export const isDarkTheme = () => {
    return $("html").attr("theme") === "dark";
};

export const lightDarkOutline = () => {
    return isDarkTheme() ? "outline-light" : "outline-secondary";
};

export const lightDark = () => {
    return isDarkTheme() ? "light" : "secondary";
};

export const editorTheme = () => {
    return isDarkTheme() ? "idle_fingers" : "crimson_editor";
};
