import React from "react";
import Widget from "../../../../widgets/Widget";
import { TwoColumnLayout } from "../../../styled_components/Layouts";
import { ErrorMessage } from "../../../reusable/ReactFormComponents";
import { ValidEmailRegex, ValidPhoneRegex } from "../../../../enums/RegularExpressions";

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const AccountContact = ({ register, errors }) => {
    const errorMsg = (fieldName) => {
        if (!errors.contact || !errors.contact[fieldName]) return;
        return <ErrorMessage>{errors.contact[fieldName].message}</ErrorMessage>;
    };

    return (
        <span>
            <Widget.Spacer height={10} />
            <Widget.Header>Primary Contact</Widget.Header>
            <TwoColumnLayout>
                <span>
                    <Widget.Input
                        type="text"
                        name="contact.firstName"
                        label="First name"
                        placeholder="John"
                        required={true}
                        ref={register({ ...required })}
                        style={inputStyle}
                    />
                    {errorMsg("firstName")}
                </span>
                <span>
                    <Widget.Input
                        type="text"
                        name="contact.lastName"
                        label="Last name"
                        placeholder="Smith"
                        required={true}
                        ref={register({ ...required })}
                        style={inputStyle}
                    />
                    {errorMsg("lastName")}
                </span>
            </TwoColumnLayout>
            <TwoColumnLayout>
                <span>
                    <Widget.Input
                        type="text"
                        name="contact.email"
                        label="Email address"
                        placeholder="john.smith@company.com"
                        required={true}
                        ref={register({
                            ...required,
                            pattern: {
                                value: ValidEmailRegex,
                                message: "Invalid email address",
                            },
                        })}
                        style={inputStyle}
                    />
                    {errorMsg("email")}
                </span>
                <TwoColumnLayout>
                    <span>
                        <Widget.Input
                            type="tel"
                            name="contact.phone"
                            label="Phone number"
                            placeholder="704-555-8800"
                            inputAttributes={{ pattern: ValidPhoneRegex }}
                            required={true}
                            ref={register({
                                ...required,
                                pattern: {
                                    value: ValidPhoneRegex,
                                    message:
                                        "Must be in format 123-456-7890 or left completely blank",
                                },
                            })}
                            style={inputStyle}
                        />
                        {errorMsg("phone")}
                    </span>
                    <span>
                        <Widget.Input
                            type="text"
                            name="contact.role"
                            label="Role"
                            placeholder="Sales associate"
                            required={true}
                            ref={register({ ...required })}
                            style={inputStyle}
                        />
                    </span>
                </TwoColumnLayout>
            </TwoColumnLayout>
        </span>
    );
};

export default AccountContact;
