import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { LoadingMessages } from "../../enums/LoadingMessages";
import $ from "jquery";

const keys = [...Array(LoadingMessages.length).keys()];
let unusedIndexes = [...keys];

/**
 * Gets a random index for the loading messages list.
 */
const randomIndex = () => Math.floor(Math.random() * LoadingMessages.length);

/**
 * Gets a new message from the list and removes the index
 * of the message form the `unusedIndexes` array so that
 * it cannot be reused.
 *
 * @returns the index of a loading message that hasn't been used yet
 */
const getNextMessage = (pageVisible) => {
    const unusedIndex = Math.floor(Math.random() * unusedIndexes.length);
    const messageIndex = unusedIndexes[unusedIndex];
    if (pageVisible && document.hasFocus()) unusedIndexes.splice(unusedIndex, 1);
    else unusedIndexes = [...keys];
    return messageIndex;
};

/**
 * Initial index for continuous loading screen.
 */
const initialIndex = getNextMessage(true);

export const Loading = ({ style, text, continuous }) => {
    const [index, setIndex] = useState(continuous ? initialIndex : randomIndex());
    const [reachedTheEnd, setReachedTheEnd] = useState(false);
    const [pageVisible, setPageVisible] = useState(true);

    /**
     * Listen for changes to the document visibility.
     */
    useEffect(() => {
        if (text || !continuous) return;

        /**
         * Sets the page visibility to reflect whether or not
         * the user can actually see the page currently.
         */
        const setVisibility = () => {
            const visible = !document.hidden;
            if (!visible) {
                setReachedTheEnd(false);
                unusedIndexes = [...keys];
            }
            setPageVisible(visible);
        };

        /**
         * Turns the listener on or off for checking to see
         * if the page is in view.
         * @param {*} onOff
         * @returns
         */
        const toggleListeners = (onOff) =>
            $(window)[onOff]("focus blur visibilitychange", setVisibility);

        toggleListeners("on");
        return () => toggleListeners("off");
    }, [text, continuous]);

    /**
     * Set an interval to rotate the loading message every few
     * seconds. Prevent duplicate messages from being shown as
     * long as the screen is actively being viewed.
     *
     * Additionally, if the screen goes out of view, reset the
     * indexes so that the secret message cannot be found by
     * just leaving a tab open with the loading messages screen
     * running in the background. Also, reset the flag for
     * whether or not the user has reached the end of the
     * messages so that the secret message can only be viewed
     * when it's directly observed.
     */
    useEffect(() => {
        if (text) return;

        const interval = setInterval(
            () => {
                if (!pageVisible) return;
                if (unusedIndexes.length === 0) {
                    if (document.hasFocus()) return setReachedTheEnd(true);
                    return (unusedIndexes = [...keys]);
                }
                setIndex(getNextMessage(pageVisible));
            },
            continuous ? 3000 : 1500
        );

        if (reachedTheEnd || !pageVisible) clearInterval(interval);
        return () => clearInterval(interval);
    }, [pageVisible, index, continuous, text, reachedTheEnd]);

    /**
     * Only display loading message if the user has reached the end
     * while their page is visible and in focus.
     */
    const displaySecretMessage = reachedTheEnd && pageVisible && document.hasFocus();

    return (
        <div className="no-content loading" style={style}>
            <div>
                {!continuous && <Spinner animation="border" size="lg" />}
                <br />
                <br />
                {displaySecretMessage && (
                    <span
                        style={{
                            width: 600,
                            marginBottom: 20,
                            whiteSpace: "normal",
                            display: "inline-block",
                        }}>
                        Wow, you really sat through the entire list of messages? Impressive. I can't
                        believe you made it all the way to the end. For your dedication, you've now
                        earned a reward! You get to be one of the lucky few who know this super
                        secret message exists. Feel free to ask your coworkers if they've seen the
                        'secret message' but don't tell them how to find it. You are now part of a
                        super secret inner circle and, if others are going to join, they'll need to
                        find their own way.
                    </span>
                )}
                <p>
                    {(() => {
                        if (text) return text;
                        if (displaySecretMessage)
                            return "Enjoy the perks of being in the inner circle! You've earned it";
                        return LoadingMessages[index];
                    })()}
                </p>
                {/* {`${keys.length - unusedIndexes.length + 1} / ${keys.length}`} */}
            </div>
        </div>
    );
};
