import React, { useState, createContext } from "react";
import { GetUserSettings } from "../models/Settings";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState(GetUserSettings());
    const updateSettings = () => setSettings(GetUserSettings());

    return (
        <SettingsContext.Provider value={{ settings, updateSettings, setSettings }}>
            {children}
        </SettingsContext.Provider>
    );
};
