import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function InfoIcon({ info, style }) {
    const popover = (
        <Popover>
            <Popover.Title as="h3">What's this?</Popover.Title>
            <Popover.Content>{info}</Popover.Content>
        </Popover>
    );
    return (
        <small style={{ marginLeft: "8px", ...style }}>
            <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popover}>
                <i className="fas fa-info-circle" />
            </OverlayTrigger>
        </small>
    );
}
