import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb, Tab, Tabs, Alert } from "react-bootstrap";
import { NoResults } from "../../transactions/transaction/Fillers";
import { Loading } from "../../reusable/Loading";
import PurposesWidget from "./account_widgets/authorizations/PurposesWidget";
import MvrJurisdictionsWidget from "./account_widgets/authorizations/MvrJurisdictionsWidget";
import DetailsWidget from "./account_widgets/details/DetailsWidget";
import ContactsWidget from "./account_widgets/contacts/ContactsWidget";
import NotesWidget from "./account_widgets/notes/NotesWidget";
import MonitoringWidget from "./account_widgets/authorizations/MonitoringWidget";
import { Unauthorized } from "../../authentication/SigninPrompt";
import withAuthorizationCheck from "../../HOC/withAuthorizationCheck";
import RestApiWidget from "./account_widgets/credentials/RestApiWidget";
import { useCredentials } from "src/hooks/react-query/accounts/useCredentials";
import { DateTime } from "luxon";
import { AlertWrapper } from "./account_widgets/credentials/webhook/auth-schema-list/AuthSchemaListItemContent";
import { ThemeContext } from "src/contexts/ThemeContext";
import InfoIcon from "src/components/reusable/InfoIcon";
import { useAccount } from "src/hooks/react-query/accounts/useAccount";

function Account({ account }) {
    const { darkMode } = useContext(ThemeContext);
    const history = useHistory();
    const { data: accountData, isLoading } = useAccount(account);
    const { data: credentialData } = useCredentials(account);
    const activeCreds = credentialData?.credentials?.filter((c) => c.active);
    const expiringCreds = activeCreds?.filter((c) => {
        const daysRemaining = DateTime.fromISO(c.expiresOn).diffNow("days").days;
        return daysRemaining <= 30;
    });
    const hasNewCred = activeCreds?.length > expiringCreds?.length;

    if (isLoading) return <Loading />;
    if (accountData) {
        const categ_data = categorizeData(accountData);
        const displayName = String(categ_data.account.companyName || categ_data.account.name);

        const credentialAlert = !hasNewCred && expiringCreds?.length && (
            <AlertWrapper darkMode={darkMode}>
                <Alert
                    variant="danger"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto",
                        alignItems: "center",
                    }}>
                    <div>
                        <p>REST API credentials expiring soon:</p>
                        <ul>
                            {expiringCreds?.map((c, idx) => (
                                <li key={idx}>
                                    {c.nickname} (expires {timestampToDate(c.expiresOn)})
                                </li>
                            ))}
                        </ul>
                    </div>
                    <InfoIcon info={expiringCredsInfo()} style={{ fontSize: "20px" }} />
                </Alert>
            </AlertWrapper>
        );

        return (
            <div className="center-content">
                <Breadcrumb>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => history.push("/accounts")}>
                            Accounts
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{categ_data.account.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Breadcrumb>
                {credentialAlert || null}
                <h1>{displayName}</h1>
                <Tabs defaultActiveKey="details" id="account-tabs" justify>
                    <Tab eventKey="details" title="Details">
                        <DetailsWidget account={accountData.account} />
                    </Tab>
                    <Tab eventKey="dppa" title="DPPA">
                        <PurposesWidget
                            account={categ_data.account}
                            purposes={categ_data.purposes}
                        />
                    </Tab>
                    <Tab eventKey="mvrs" title="Products">
                        <MvrJurisdictionsWidget
                            account={categ_data.account}
                            products={categ_data.products}
                        />
                        <MonitoringWidget
                            account={categ_data.account}
                            products={categ_data.products}
                        />
                    </Tab>
                    {/* <Tab eventKey="address" title="Address">
                    </Tab> */}
                    <Tab eventKey="contacts" title="Contacts">
                        <ContactsWidget account={categ_data.account} />
                    </Tab>
                    <Tab eventKey="notes" title="Notes">
                        <NotesWidget account={categ_data.account} />
                    </Tab>
                    <Tab eventKey="rest-api" title="REST API">
                        <RestApiWidget account={categ_data.account} />
                    </Tab>
                </Tabs>
            </div>
        );
    }

    return <NoResults />;
}

export default withAuthorizationCheck(
    ["account:list", "account:read"],
    Account,
    <Unauthorized subtext="You are not authorized to view account information" />
);

/**
 * Breaks the data into subcategories and then combines the subcategories
 * with the original data in one object.
 *
 * @param {Object} data should contain "account" and "authorizations"
 */
function categorizeData(data) {
    const purposes = data.authorizations.filter((a) => a.authName.startsWith("DPPA"));
    const products = data.authorizations.filter((a) => !a.authName.startsWith("DPPA"));
    return {
        ...data,
        purposes,
        products,
    };
}

function timestampToDate(timestamp) {
    return DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_MED);
}

function expiringCredsInfo() {
    return (
        <span>
            These credentials will be expiring soon. Please create new credentials and provide them
            to the customer before the expiration date to ensure service will not be interrupted.
        </span>
    );
}
