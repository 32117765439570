import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import EditCloseButtons from "../../reusable/EditCloseButtons";
import StaticFields from "./StaticFields";
import EditableFields from "./EditableFields";
import { AreYouSureAlert, SuccessAlert, ErrorAlert } from "../../reusable/Alerts";
import { PostPayload } from "../../../functions/Http";
import withAuthorizationCheck from "../../HOC/withAuthorizationCheck";
import { useAuth0 } from "@auth0/auth0-react";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const ACDModal = ({ acd: currentAcd, table, show, setShow, refresh }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [editMode, setEditMode] = useState(false);
    const [saving, setSaving] = useState(false);
    const [acd, setAcd] = useState({ ...currentAcd });
    const { baseUrl } = useCurrentEnvironment();

    const onCancel = (valuesChanged) =>
        valuesChanged
            ? AreYouSureAlert().then(({ isConfirmed }) => isConfirmed && setEditMode(false))
            : setEditMode(false);

    const onSave = async (values) => {
        const payload = PostPayload(await getAccessTokenSilently(), {
            id: acd.id,
            table: table.table,
            columns: values, // mapped_x columns as an object
        });
        setSaving(true);
        fetch(`${baseUrl}/mvrformats`, payload)
            .then((res) => {
                const json = res.json();
                if (res.status !== 200) throw new Error(json);
                return json;
            })
            .then(() =>
                SuccessAlert().then(() => {
                    setSaving(false);
                    setEditMode(false);
                    setAcd({ ...acd, ...values });
                    refresh({ acdId: acd.id });
                })
            )
            .catch((err) => {
                console.log("Error saving ACD mapping", err);
                ErrorAlert();
                setSaving(false);
            });
    };

    const onHide = () => {
        return editMode
            ? AreYouSureAlert().then(({ isConfirmed }) => {
                  if (isConfirmed) {
                      setShow(false);
                      //   refresh();
                  }
              })
            : setShow(false);
    };

    return (
        <Modal show={show} onHide={onHide} id="acd-modal" size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {table?.label} {acd?.id}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StaticFields acd={acd} table={table} showReadOnlyMappedFields={!editMode} />
                <EditableFields
                    acd={acd}
                    table={table}
                    editMode={editMode}
                    onSave={onSave}
                    onCancel={onCancel}
                    saving={saving}
                />
                {!editMode &&
                    withAuthorizationCheck(
                        ["acd:update"],
                        <EditCloseButtons onClose={onHide} onEdit={() => setEditMode(true)} />,
                        <EditCloseButtons onClose={onHide} hideEdit />
                    )()}
            </Modal.Body>
        </Modal>
    );
};

export default ACDModal;
