import { useEffect, useRef } from "react";

/**
 * Custom hook replacing setInterval that works much better with React.
 *
 * For documentation, follow this link:
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {Function} callback the function to call on an interval
 * @param {Number} delay the time between function calls (will not call function if null)
 */
export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
