import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useEnvironment } from "src/hooks/useEnvironment";

const isProdMint = window.location.origin === "https://mint.tmlinfo.com";

const EnvironmentDropdown = () => {
    // const { hasPermissions } = useContext(UserContext);
    const { data } = useEnvironment();
    const { environment, setEnvProd, setEnvSdbx, setEnvDev } = data ?? {};

    const Routes = [
        {
            label: "Production",
            onClick: setEnvProd,
        },
        {
            label: "Sandbox",
            onClick: setEnvSdbx,
        },
        {
            label: "Development",
            onClick: setEnvDev,
            condition: !isProdMint,
        },
    ];

    if (!environment) return null;

    const MappedRoutes = Routes.filter(Boolean).map(({ label, onClick, condition }, idx) => {
        const conditionExists = condition !== null && condition !== undefined;
        const dropdownItem = (
            <NavDropdown.Item
                key={idx}
                onClick={onClick}
                style={environment.label === label ? { color: "#73a9cf" } : undefined}>
                {label}
            </NavDropdown.Item>
        );
        return conditionExists ? condition && dropdownItem : dropdownItem;
    });

    return (
        <NavDropdown
            title={
                // <span className="monospace" style={{ fontSize: 15, color: "#ffb618" }}>
                <span className="monospace" style={{ fontSize: 15, color: "white" }}>
                    <b>[</b> {environment.label} <b>]</b>
                </span>
            }
            id="environment-dropdown">
            {MappedRoutes}
        </NavDropdown>
    );
};

export default EnvironmentDropdown;
