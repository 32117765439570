import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserContext } from "src/contexts/UserContext";
import { SIGNIN_URL } from "src/enums/Endpoints";
import { SettingsModal } from "./Modals";

const MenuDropdown = () => {
    const history = useHistory();
    const { hasPermissions } = useContext(UserContext);
    const { user, logout } = useAuth0();
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const Routes = [
        {
            label: "Settings",
            onClick: () => setShowSettingsModal(true),
        },
        {
            label: "Warning Logs",
            onClick: () => history.push("/developer/log"),
            condition: hasPermissions(["warn-logs:read"]),
        },
        {
            label: "Loading Messages",
            onClick: () => history.push("/loading-messages"),
        },
    ];

    const MappedRoutes = Routes.map(({ label, onClick, condition }, idx) => {
        const conditionExists = condition !== null && condition !== undefined;
        const dropdownItem = (
            <NavDropdown.Item key={idx} onClick={onClick}>
                {label}
            </NavDropdown.Item>
        );
        return conditionExists ? condition && dropdownItem : dropdownItem;
    });

    const LogoutRoute = user && (
        <React.Fragment>
            <NavDropdown.Divider />
            <NavDropdown.Header>{`${user.given_name} ${user.family_name}`}</NavDropdown.Header>
            <NavDropdown.Item onClick={() => logout({ returnTo: SIGNIN_URL })}>
                Logout
            </NavDropdown.Item>
        </React.Fragment>
    );

    return user ? (
        <NavDropdown title="" id="more-options" className="right-align-menu">
            <SettingsModal show={showSettingsModal} setShow={setShowSettingsModal} />
            {MappedRoutes}
            {LogoutRoute}
        </NavDropdown>
    ) : null;
};

export default MenuDropdown;
