import React, { useContext } from "react";
import { StyledComponentContext } from "../../../contexts/StyledComponentsContext";
import { DateTime } from "luxon";
import { GlobalColumnNames } from "../filter/TableSchema";
import { ThemeContext } from "../../../contexts/ThemeContext";
import ExternalLink from "../../reusable/ExternalLink";
const { styled } = StyledComponentContext._currentValue;

/*
 * =========================
 *      Data Formatting
 * =========================
 */
const formatSqlColumnName = (columnName) => {
    switch (columnName) {
        case "time_inserted":
        case "dateinsert":
            return "Time Inserted";

        case "time_modified":
        case "time_edited":
        case "dateused":
            return "Time Modified";

        default:
            return "Unknown Column";
    }
};
const FormatDate = (date) => DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);

/*
 * ================
 *      Styles
 * ================
 */
const FieldGroupContainer = styled.div`
    border: 1px solid ${({ darkMode }) => (darkMode ? "#6e6e6e" : "#bbb")};
    border-radius: 3px;
    padding: 10px;
    margin-bottom: ${({ marginBottom }) => marginBottom || "25px"};
    position: relative;
    display: grid;
    row-gap: 20px;

    label {
        position: absolute;
        left: 16px;
        top: -16px;
        padding: 3px 10px 5px;
        // color: #aeaeae;
        color: #8c8c8c;

        & + .field-group {
            margin-top: 5px;
        }
    }

    .field-group {
        display: grid;
        row-gap: 10px;
        column-gap: 10px;
        grid-template-columns: 1fr 1fr;

        b {
            display: block;
        }
    }

    #last_uuid,
    div[id$="_text"] {
        grid-column: 1 / span 3;
    }
`;

/*
 * ===================
 *      Component
 * ===================
 */
export default function StaticFields({ acd, table, showReadOnlyMappedFields }) {
    const mappedFieldRegex = /mapped/;
    const timeFieldRegex = /^(time|date)/i;
    const mappedFields = Object.entries(acd).filter(([key]) => mappedFieldRegex.test(key));
    const staticFields = Object.entries(acd).filter(([key]) => !mappedFieldRegex.test(key));
    const globalFields = staticFields.filter(([key]) => GlobalColumnNames.includes(key));
    const timeFields = staticFields.filter(([key]) => timeFieldRegex.test(key));
    const stateFields = staticFields.filter(
        (f) => !globalFields.includes(f) && !timeFields.includes(f)
    );

    const { darkMode } = useContext(ThemeContext);

    return (
        <div style={{ marginTop: "15px" }}>
            <GlobalFields globalFields={globalFields} timeFields={timeFields} />
            <StateFields stateFields={stateFields} />
            {showReadOnlyMappedFields && <ReadOnlyMappedFields mappedFields={mappedFields} />}
        </div>
    );

    /*
     * ========================
     *      Sub Components
     * ========================
     */
    /**
     * Fields shared by all ACD categories in the database.
     */
    function GlobalFields({ globalFields, timeFields }) {
        return (
            <FieldGroupContainer darkMode={darkMode}>
                <label className="theme-bg">Global Properties</label>
                <FieldGroup fields={globalFields} className="field-group" />
                <FieldGroupContainer marginBottom="0" darkMode={darkMode}>
                    <FieldGroup fields={timeFields} className="field-group" />
                </FieldGroupContainer>
            </FieldGroupContainer>
        );
    }

    /**
     * Fields containing values sent by the state.
     */
    function StateFields({ stateFields }) {
        return (
            <FieldGroupContainer darkMode={darkMode}>
                <label className="theme-bg">State Properties</label>
                <FieldGroup fields={stateFields} className="field-group" />
            </FieldGroupContainer>
        );
    }

    /**
     * Fields with values assigned by TML.
     */
    function ReadOnlyMappedFields({ mappedFields }) {
        return (
            <FieldGroupContainer darkMode={darkMode}>
                <label className="theme-bg">Mapped Properties</label>
                <FieldGroup fields={mappedFields} className="field-group" />
            </FieldGroupContainer>
        );
    }

    /**
     * Generic function for mapping fields and their values
     * to a group of elements.
     *
     * @param {*} param0
     */
    function FieldGroup({ fields }) {
        const fieldElements = fields.map(([key, val], idx) => {
            const schemaColumn = table.columns.find((c) => c.column === key);
            const label = schemaColumn?.label || formatSqlColumnName(key);
            const id = label.replace(" ", "_").toLowerCase();
            const value = (() => {
                if (timeFieldRegex.test(key)) return FormatDate(val);
                if (/usedcount/.test(key)) return val?.toLocaleString() || 0;
                return val;
            })();

            const displayValue = (() => {
                if (value) {
                    const isLastUuidField = /uuid$/i.test(key);
                    if (isLastUuidField)
                        return (
                            <ExternalLink href={`/transactions?id=${value}`}>{value}</ExternalLink>
                        );
                    else return <span>{value}</span>;
                }
                return <i style={{ color: "darkgray" }}>None</i>;
            })();

            return (
                <div key={idx} id={id}>
                    <b>{label}</b>
                    {displayValue}
                </div>
            );
        });

        return <div className="field-group">{fieldElements}</div>;
    }
}
