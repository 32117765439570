import React, { useState, useEffect, useContext } from "react";
import { useInterval } from "../../hooks/useInterval";
import { Button, Spinner } from "react-bootstrap";
import { ContentHeader } from "../styled_components/ContentHeader";
import { ThemeContext } from "../../contexts/ThemeContext";
import Swal from "sweetalert2";
import ClearButton from "./ClearButton";
import Toggle from "./Toggle/Toggle";
import $ from "jquery";

/**
 * Header that provides common functionality across components designed to
 * use the ResultsTable component.
 *
 * @param {string} props.headerText the enlarged h1 text
 * @param {boolean} props.hideActionButtons if true, will not display search/clear/etc. buttons
 *      (useful when in a loading state so users cannot overload component)
 * @param {boolean} props.displayClearButton if true, will display a "Clear" button to use for resetting the page
 * @param {function} props.onSearch the function to call when the "Search" button is clicked
 * @param {function} props.onClear the function to call when the "Clear" button is clicked
 * @param {function} props.refreshFunction async function that, if provided, will add an "Auto Refresh" toggle switch
 *      to the page and, if the toggle switch is enabled, will be called on an interval
 * @param {number} props.refreshDelay (default = 30s) the delay, in seconds, between calls to the "refreshFunction"
 */
export default function ResultsTableHeader(props) {
    const {
        headerText,
        hideActionButtons,
        displayClearButton,
        onSearch,
        onClear,
        refreshFunction,
    } = props;

    const { outlineVariant } = useContext(ThemeContext);

    // ============================
    //      Individual Buttons
    // ============================
    /**
     * If refresh function is provided, displays "Auto refresh" toggle switch.
     */
    const AutoRefreshSwitch = refreshFunction && <AutoRefreshToggleSwitch {...props} />;

    /**
     * Button labeled "Clear".
     */
    const clearButton = !!displayClearButton && <ClearButton onClick={onClear} />;

    /**
     * Button labeled "Search".
     */
    const searchButton = (
        <Button
            title="Apply search filters"
            pill="true"
            variant={outlineVariant}
            size="sm"
            onClick={onSearch}>
            <i className="fas fa-search" />
            <span>&nbsp;Search</span>
        </Button>
    );

    // ======================
    //      Button Group
    // ======================
    const ActionButtons = !hideActionButtons && (
        <span className="content-header-actions">
            {AutoRefreshSwitch}
            {clearButton}
            {searchButton}
        </span>
    );

    // ===================
    //     Component
    // ===================
    return (
        <ContentHeader>
            <span>{headerText}</span>
            {ActionButtons}
        </ContentHeader>
    );
}

/*
 * ====================================
 *      Auto Refresh Sub-component
 * ====================================
 */

/**
 * Converts refresh interval (in seconds) into milliseconds.
 *
 * @param {number} seconds number of seconds between refreshes
 * @returns the number of milliseconds contained in the 'seconds' parameter
 */
const toMillis = (seconds) => (seconds ? seconds * 1000 : 0);

/**
 * Default refresh delay (30 seconds)
 */
const DEFAULT_DELAY = toMillis(30);

/**
 * Default refresh timeout (1 hour)
 */
const DEFAULT_TIMEOUT = toMillis(60 * 60);

/**
 * Toggle switch that allows data sets to be automatically refreshed.
 *
 * @param {Function} props.refreshFunction the function to call for refreshing
 * @param {Number} props.refreshDelay the amount of time (in seconds) to wait between refreshes
 */
function AutoRefreshToggleSwitch(props) {
    const { refreshFunction, refreshDelay } = props;
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [pageVisible, setPageVisible] = useState(!document.hidden);
    const [timer, setTimer] = useState(null);

    // ==========================
    //      Refresh Interval
    // ==========================
    const canRefresh = autoRefresh && pageVisible;
    const delay = refreshDelay ? toMillis(refreshDelay) : DEFAULT_DELAY;
    useInterval(handleRefresh, canRefresh ? delay : null);

    // ================================
    //      Listeners and Timeouts
    // ================================
    /**
     * Disables the calls to the refresh function if the page is not in plain sight.
     */
    useEffect(() => {
        let mounted = true;
        // Timeout used because document.hidden does not update instantly
        const setVisibility = () =>
            setTimeout(() => mounted && setPageVisible(!document.hidden), 250);
        const toggleListeners = (onOff) => $(window)[onOff]("focus blur", setVisibility);

        toggleListeners("on");
        return () => {
            mounted = false;
            toggleListeners("off");
        };
    }, [pageVisible]);

    /**
     * Disable the auto-refresh after one hour and notify the user.
     */
    useEffect(() => {
        let mounted = true;
        const handleTimeout = async () => {
            if (!mounted) return;
            setAutoRefresh(false);
            await handleRefresh(); // Final refresh
            const { isConfirmed } = await ReEnableRefreshNotice();
            if (isConfirmed) {
                handleRefresh();
                setAutoRefresh(true);
            }
        };

        if (autoRefresh) {
            setTimer(setTimeout(handleTimeout, DEFAULT_TIMEOUT));
        } else {
            clearTimeout(timer);
            setTimer(null);
        }

        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefresh]);

    // ===================
    //      Component
    // ===================
    return (
        <>
            {isRefreshing && <Spinner animation="border" size="sm" style={{ fontSize: 14 }} />}
            <Toggle
                size={14}
                borderRight
                label="Auto refresh:"
                onChange={handleToggle}
                checked={autoRefresh}
            />
        </>
    );

    // ===========================
    //      Handler Functions
    // ===========================
    async function handleRefresh() {
        setIsRefreshing(true);
        await refreshFunction();
        setIsRefreshing(false);
    }

    async function handleToggle() {
        // Going from disabled -> enabled
        if (!autoRefresh) await EnableRefreshNotice(refreshDelay).then(handleRefresh);
        setAutoRefresh(!autoRefresh);
    }
}

/*
 * ====================================
 *      Auto Refresh Notifications
 * ====================================
 */
function EnableRefreshNotice(refreshDelay) {
    return Swal.fire({
        title: "About this feature",
        text: `
            The data on this page will be automatically refreshed every 
            ${refreshDelay || DEFAULT_DELAY / 1000} seconds for the next hour.
        `,
        icon: "info",
        confirmButtonText: "Ok, got it!",
    });
}

function ReEnableRefreshNotice() {
    return Swal.fire({
        title: "Refresh disabled",
        text: "Would you like to enable automatic refresh for another hour?",
        icon: "info",
        confirmButtonText: "Yes, please!",
        cancelButtonText: "No, thanks.",
        showCancelButton: true,
        reverseButtons: true,
    });
}
