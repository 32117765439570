import { useQuery } from "@tanstack/react-query";
import { GetPayload } from "src/functions/Http";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useSecureMutate } from "src/hooks/useSecureMutate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const url = (baseUrl, accountName) => `${baseUrl}/accounts/${accountName}/webhooks`;

export const useWebhooks = (accountName) => {
    const { baseUrl } = useCurrentEnvironment();
    const { secureFetch } = useSecureFetch(url(baseUrl, accountName));

    return useQuery({
        queryKey: ["accounts", accountName, "webhooks"],
        queryFn: async () => await secureFetch((token) => GetPayload(token)),
    });
};

export const useMutateWebhook = ({ accountName, onSuccess, onError }) => {
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({ url: url(baseUrl, accountName), onSuccess, onError });
};

export function patternMatchesPattern(pattern1, pattern2) {
    let p = pattern2;
    const tempWildcard = "%%";
    const nonSlashWildcards = /\*/g;
    const truthyNonSlashChars = "[^/]+";
    const noDoubleSlashChars = "([^/](?!//)|[/](?![/]))*";
    const optionalSlashOrParams = `(([/])?|([?]${truthyNonSlashChars})*)`;

    if (p.endsWith("/*")) p = p.slice(0, -2) + tempWildcard;
    else if (p.endsWith("*")) p = p.slice(0, -1) + tempWildcard;
    else if (p.endsWith("/")) p = p.slice(0, -1) + optionalSlashOrParams;
    else p += optionalSlashOrParams;
    if (!p.startsWith("^")) p = `^${p}`;
    if (!p.endsWith("$")) p += "$";

    p = p.replace(nonSlashWildcards, truthyNonSlashChars);
    p = p.replace(tempWildcard, noDoubleSlashChars);
    return new RegExp(p).test(pattern1);
}
