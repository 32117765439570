import React from "react";
import { useState } from "react";
import AuthorizationsWidget from "./AuthorizationsWidget";

const PurposesWidget = ({ account, purposes }) => {
    const [authorizations, setAuthorizations] = useState(purposes);
    return (
        <AuthorizationsWidget
            key={JSON.stringify(authorizations)}
            label="DPPA Reasons"
            account={account}
            authorizations={authorizations}
            setAuthorizations={setAuthorizations}
            permissions={["account:dppa"]}
        />
    );
};

export default PurposesWidget;
