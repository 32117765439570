import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { parseQueryParams } from "./functions/QueryStrings";
import Navbar from "./components/navigation/Navbar";
import Transaction from "./components/transactions/transaction/Transaction";
import "./styles/main.scss";
import { TransactionProvider } from "./contexts/TransactionContext";
import AccountList from "./components/accounts/account_list/AccountList";
import Account from "./components/accounts/account/Account";
import NewAccount from "./components/accounts/new_account/NewAccountForm";
import FilterTransactions from "./components/transactions/filter/FilterTransactions";
import FilterACDs from "./components/acd/filter/FilterACDs";
import FilterWarnings from "./components/developer/WarningLogs/filter/FilterWarnings";
import { Loading } from "./components/reusable/Loading";
import PendingList from "./components/pending-list/PendingList";

function AppRoutes({ returnUrl }) {
    return (
        <React.Fragment>
            {PermanentNavbar}
            <Switch>
                {ACDRoute}
                {AccountRoutes}
                {DeveloperRoutes}
                {TransactionRoutes}
                {PendingListRoutes}
                {LoadingMessagesRoute}
                {returnUrl && <Redirect to={returnUrl} />}
                <Redirect to={"/transactions"} />
                {/* <Route exact path="/">
                    <Redirect to={"/transactions"} />
                </Route> */}
            </Switch>
        </React.Fragment>
    );
}

export default AppRoutes;

const PermanentNavbar = <Route render={({ location }) => <Navbar location={location} />} />;

const ACDRoute = (
    <Route
        path="/mappings"
        render={({ location, history }) => (
            <FilterACDs key={location.key} location={location} history={history} />
        )}
    />
);

const AccountRoutes = (
    <Route path="/accounts">
        <Switch>
            <Route path="/accounts/new-account" component={NewAccount} />
            <Route
                path="/accounts/:account"
                render={({ match, location }) => {
                    const { account } = match.params;
                    return <Account key={account} account={account} location={location} />;
                }}
            />
            <Route path="/accounts" component={AccountList} />
        </Switch>
    </Route>
);

const TransactionRoutes = (
    <Route
        path="/transactions"
        render={({ location, history }) => {
            const transaction_id = parseQueryParams(location.search).id;
            return transaction_id ? (
                <TransactionProvider>
                    <Transaction key={transaction_id} location={location} />
                </TransactionProvider>
            ) : (
                <FilterTransactions key={location.key} location={location} history={history} />
            );
        }}
    />
);

const PendingListRoutes = <Route path="/pending" component={PendingList} />;

// const ComingSoon = () => <div className="center-content">Coming soon...</div>;
const DeveloperRoutes = (
    // <Route path="/developer" component={DevDashboard}>
    //     <div className="center-content">
    //         <DevNavigation />
    //     </div>
    //     <Switch>
    <Route
        path="/developer/log"
        render={({ location, history }) => (
            <FilterWarnings key={location.key} location={location} history={history} />
        )}
    />
    //         <Route path="/developer/product-config" render={ComingSoon} />
    //         <Route path="/developer/other" render={ComingSoon} />
    //         <Route>
    //             {/* No match */}
    //             <Redirect to="/developer/log" />
    //         </Route>
    //     </Switch>
    // </Route>
);

const LoadingMessagesRoute = (
    <Route path="/loading-messages" render={() => <Loading continuous />} />
);
