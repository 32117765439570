import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { Settings, State } from "../../models/Settings";
import DualButtonGroup from "./DualButtonGroup";
import { SettingsContext } from "../../contexts/SettingsContext";

/**
 *
 */
export const SearchMirrorSettings = () => {
    const { settings, updateSettings } = useContext(SettingsContext);
    const storedValue = localStorage.getItem(Settings.SEARCH_MIRROR) === State.ENABLED;
    const [searchMirror, setSearchMirror] = useState(storedValue);
    const setDefaultSearchMirror = (bool) => {
        localStorage.setItem(Settings.SEARCH_MIRROR, bool ? State.ENABLED : State.DISABLED);
        setSearchMirror(bool);
        updateSettings();
    };

    /**
     * Ensures accuracy of this setting on screen when other settings change.
     */
    useEffect(() => {
        if (searchMirror !== storedValue) setSearchMirror(storedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <div className="dual-btn-option">
            <Form.Label style={{ display: "inline-block" }}>Mirror searches:</Form.Label>
            <DualButtonGroup
                leftActive={!searchMirror}
                leftText="Disabled"
                rightText="Enabled"
                leftClick={() => setDefaultSearchMirror(false)}
                rightClick={() => setDefaultSearchMirror(true)}
            />
        </div>
    );
};
