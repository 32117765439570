import { useAuth0 } from "@auth0/auth0-react";

/**
 * Handles loading and caching webhooks and auth schemas
 * for an account.
 *
 * @param {*} accountName the name of the account
 */
export const useSecureFetch = (url) => {
    const { getAccessTokenSilently } = useAuth0();

    /**
     * Makes a request to the endpoint with an Auth0
     * access token using the provided payload.
     *
     * @param {*} payloadFunc a function whose only
     * parameter is an access token and should return
     * a fetch payload with the token in the headers.
     *
     * @see src/functions/Http.js for payload helpers
     */
    async function makeRequest(payloadFunc) {
        return getAccessTokenSilently().then(async (token) => {
            const res = await fetch(url, payloadFunc(token));
            const json = await res.json();
            if (!res.ok) throw json;
            return json;
        });
    }

    return { secureFetch: makeRequest };
};
