import React, { useContext, useEffect } from "react";
import { UserContext } from "./contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import SigninPrompt from "./components/authentication/SigninPrompt";
import { Loading } from "./components/reusable/Loading";
import AppRoutes from "./AppRoutes";
import { GetUserSettings, InitSettings } from "./models/Settings";
import $ from "jquery";
import "./styles/main.scss";
import { Redirect, useLocation } from "react-router-dom";

function App({ returnUrl }) {
    const { theme } = GetUserSettings();
    if (!theme) InitSettings();
    $("html").attr("theme", theme || "dark");

    const { authenticated, authenticating, decodedToken } = useContext(UserContext);
    const { isLoading } = useAuth0();
    const location = useLocation();

    useEffect(() => {}, [authenticated, authenticating /*, isLoading*/]);

    // if (/*isLoading || */ authenticating) return <Loading />;
    if (isLoading) return <Loading text="Authenticating" />;
    if (decodedToken && !decodedToken.permissions.length) {
        if (location.pathname !== "/") return <Redirect to="/" />;
        return (
            <SigninPrompt
                header="Unauthorized"
                subtext="No Permissions Assigned"
                buttonText="Sign Out"
                icon={<i className="fas fa-user-lock" />}
            />
        );
    }

    return authenticated ? <AppRoutes returnUrl={returnUrl} /> : <SigninPrompt />;
}

export default App;
