import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Loading } from "../../reusable/Loading";
import { lightDarkOutline } from "../../../contexts/ThemeContext";
import { ContentHeader } from "../../styled_components/ContentHeader";
import ResultsTable from "../../reusable/ResultsTable";
import withAuthorizationCheck from "../../HOC/withAuthorizationCheck";
import { Unauthorized } from "../../authentication/SigninPrompt";
import Toggle from "../../reusable/Toggle/Toggle";
import { useAccounts } from "src/hooks/react-query/account-list/useAccounts";

const ResultsTableColumns = [
    {
        tableHeading: "ID",
        objProperty: "id",
    },
    {
        tableHeading: "Account Name",
        objProperty: "name",
    },
    {
        tableHeading: "Company Name",
        objProperty: "companyName",
    },
    {
        tableHeading: "Billing ID",
        objProperty: "billingId",
    },
    {
        tableHeading: "Status",
        objProperty: "status",
    },
    {
        tableHeading: "Account Type",
        objProperty: "type",
    },
];

function AccountList() {
    const history = useHistory();
    const [showInactive, setShowInactive] = useState(false);
    const { data: accountList, isFetching, error, refetch } = useAccounts();
    const accounts = accountList?.accounts;

    const SyncingIndicator = isFetching && (
        <span
            style={{
                fontSize: "14px",
                fontWeight: "normal",
                display: "grid",
                gridAutoFlow: "column",
                columnGap: "5px",
            }}>
            <Spinner animation="border" size="sm" />
            <span>&nbsp;Syncing...</span>
        </span>
    );

    const NewAccountButton = withAuthorizationCheck(
        ["account:create"],
        <Button
            pill="true"
            variant="success"
            size="sm"
            onClick={() => history.push("/accounts/new-account")}>
            <i className="fas fa-plus" />
            <span>&nbsp;New</span>
        </Button>,
        <Button
            pill="true"
            variant="success"
            size="sm"
            disabled
            title="User not authorized"
            style={{ cursor: "not-allowed" }}>
            <i className="fas fa-plus" />
            <span>&nbsp;New</span>
        </Button>
    );

    return (
        <div className="center-content" style={{ rowGap: 0 }}>
            <ContentHeader>
                <span>Accounts</span>
                <span className="content-header-actions">
                    {SyncingIndicator}
                    <Toggle
                        borderRight
                        label="Active only:"
                        checked={!showInactive}
                        onChange={() => setShowInactive(!showInactive)}
                    />
                    <NewAccountButton />
                    <Button pill="true" variant={lightDarkOutline()} size="sm" onClick={refetch}>
                        <i className="fas fa-sync-alt" />
                        <span>&nbsp;Refresh</span>
                    </Button>
                </span>
            </ContentHeader>
            {accounts ? (
                <ResultsTable
                    columns={ResultsTableColumns}
                    collection={
                        showInactive ? accounts : accounts.filter((a) => a.status !== "INACTIVE")
                    }
                    filterPlaceholder="Filter accounts"
                    onClickRow={({ name }) => history.push(`/accounts/${name.toLowerCase()}`)}
                />
            ) : error ? (
                <span>ERROR: Could not fetch accounts</span>
            ) : (
                <Loading />
            )}
        </div>
    );
}

export default withAuthorizationCheck(
    ["account:list"],
    AccountList,
    <Unauthorized subtext="You are not authorized to list accounts" />
);
