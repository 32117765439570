import { useQuery } from "@tanstack/react-query";
import { GetPayload } from "src/functions/Http";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useSecureMutate } from "src/hooks/useSecureMutate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const url = (baseUrl, id) => `${baseUrl}/transactions?id=${id}`;

export const useTransaction = (id) => {
    const { baseUrl } = useCurrentEnvironment();
    const { secureFetch } = useSecureFetch(url(baseUrl, id));

    return useQuery({
        queryKey: ["transactions", id],
        queryFn: async () => await secureFetch((token) => GetPayload(token)),
    });
};

export const useMutateContacts = ({ accountName, onSuccess, onError }) => {
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({ url: url(baseUrl, accountName), onSuccess, onError });
};
