/**
 * When the DOM changes, sometimes the AceEditor will need to be resized.
 * Ace only listens for window resize events to resize itself, so after
 * a DOM change event occurs, it will not automatically resize itself.
 *
 * Therefore, this function should be called whenever a DOM change occurs
 * and the editor needs to be resized.
 *
 * The wait ensures that the DOM has sufficient time to be updated before
 * the resize event is triggered.
 *
 * @param {number} after the number of milliseconds to wait before resizing
 *                       (Default: 100)
 */
export const resizeEditor = (after) => {
    setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
    }, after || 100);
};
