export const Routes = {
    LeftSide: [
        {
            menuOption: "Accounts",
            path: "/accounts",
            pattern: /^\/accounts\/?$/,
        },
        {
            menuOption: "Transactions",
            path: "/transactions",
            pattern: /^\/transactions\/?$/,
        },
        {
            menuOption: "Pending",
            path: "/pending",
            pattern: /^\/pending\/?$/,
        },
        {
            menuOption: "Mappings",
            path: "/mappings",
            pattern: /^\/mappings\/?$/,
        },
    ],
    RightSide: [],
};
