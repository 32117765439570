import React, { useContext } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { ThemeContext } from "../../contexts/ThemeContext";

const DualButtonGroup = ({ leftActive, leftText, rightText, leftClick, rightClick }) => {
    const { color } = useContext(ThemeContext);

    const outline = (bool) => {
        return bool ? "" : "outline-";
    };

    return (
        <ButtonGroup>
            <Button
                variant={`${outline(leftActive)}${color}`}
                onClick={() => {
                    leftClick(leftText);
                }}>
                {leftText}
            </Button>
            <Button
                variant={`${outline(!leftActive)}${color}`}
                onClick={() => {
                    rightClick(rightText);
                }}>
                {rightText}
            </Button>
        </ButtonGroup>
    );
};

export default DualButtonGroup;
