/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { Badge } from "react-bootstrap";
import Widget from "../../../../../widgets/Widget";
import { ThemeContext } from "../../../../../contexts/ThemeContext";
import EditContactModal from "./ContactModal";
import { DeletePayload, PostPayload, PutPayload } from "../../../../../functions/Http";
import { AreYouSureAlert, ErrorAlert, SuccessAlert } from "../../../../reusable/Alerts";
import { useForm } from "react-hook-form";
import { useContacts } from "src/hooks/react-query/accounts/useContacts";
import { useMutateContacts } from "src/hooks/react-query/accounts/useContacts";

const Contacts = ({ account }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentContact, setCurrentContact] = useState(null);
    const [savingContact, setSavingContact] = useState(false);
    const { color } = useContext(ThemeContext);
    const { register, handleSubmit, errors } = useForm();

    const { data, isLoading, error, refetch } = useContacts(account.name);
    const contacts = data?.contacts;

    const updateContacts = useMutateContacts({
        accountName: account.name,
        onSuccess: () =>
            SuccessAlert().then(() => {
                refetch();
                setShowModal(false);
                setSavingContact(false);
            }),
        onError: () => {
            ErrorAlert();
            setSavingContact(false);
        },
    });

    const toListItem = (contact) => {
        const primaryBadge = contact.primary ? (
            <Badge variant={color} pill="true">
                Primary
            </Badge>
        ) : null;
        return (
            <Widget.ListItem
                key={contact.id}
                label={`${contact.firstName} ${contact.lastName}`}
                value={primaryBadge}
                subtext={contact.role}
                onClick={async () => handleShowModal(contact)}
            />
        );
    };

    const primaryContact = contacts?.filter((c) => c.primary).map(toListItem);
    const sortedContacts = contacts && [
        ...primaryContact,
        ...contacts
            .filter((c) => !c.primary)
            .sort((a, b) => a.firstName?.localeCompare(b.firstName))
            .map(toListItem),
    ];

    return (
        <Widget
            // collapsible={true}
            loading={isLoading}
            label="Contacts"
            button={<Widget.Button text="Add contact" onClick={() => handleShowModal({})} />}>
            {error && (
                <p>
                    <b>Error:</b> Unable to load contacts
                </p>
            )}
            {sortedContacts?.length ? sortedContacts : null}
            {showModal && getModal()}
        </Widget>
    );

    /**
     * This handles both saving and updating a contact.
     *
     * If it is a new contact that is being added, the contactId should be
     * null, otherwise, the contactId being passed in will be updated with
     * the information provided.
     *
     * @param {Number} contact the id of the contact being saved
     */
    function handleSave(contactId) {
        return async (values) => {
            await AreYouSureAlert().then(({ isConfirmed }) => {
                if (!isConfirmed) return;
                setSavingContact(true);
                const payload = contactId ? PutPayload : PostPayload;
                updateContacts.mutate((token) => {
                    return payload(token, {
                        ...values,
                        contactId,
                        accountId: account.id,
                    });
                });
            });
        };
    }

    /**
     * Marks a note as deleted in the database.
     *
     * @param {Object} note object containing all note fields
     */
    async function handleDelete(contact) {
        const confirmationMessage = (
            <div>
                <Widget.Spacer />
                <span className="disable">Remove this contact?</span>
                <Widget.Spacer />
                <div>
                    <span>{`${contact.firstName} ${contact.lastName}`}</span>

                    {contact.role && (
                        <>
                            <br /> <small>[ {contact.role} ]</small>
                        </>
                    )}
                </div>
            </div>
        );

        await AreYouSureAlert({
            html: confirmationMessage,
        }).then(async ({ isConfirmed }) => {
            if (!isConfirmed) return;
            setSavingContact(true);
            updateContacts.mutate((token) => DeletePayload(token, { contactId: contact.id }));
        });
    }

    /**
     * The modal is directly linked to the current contact, therefore,
     * this function ensures that the correct contact will be displayed
     * in the modal when it is shown.
     *
     * @param {Object} contact object containing all contact fields
     */
    async function handleShowModal(contact) {
        setCurrentContact(contact);
        setShowModal(true);
    }

    /**
     * Returns a modal populated with the current contact's info.
     */
    function getModal() {
        return (
            <EditContactModal
                key={currentContact}
                account={account}
                contact={currentContact}
                showModal={showModal}
                setShowModal={setShowModal}
                onSave={handleSubmit(handleSave(currentContact?.id))}
                saving={savingContact}
                onDelete={handleDelete}
                formControls={{ register, errors }}
            />
        );
    }
};

export default Contacts;
