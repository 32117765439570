import React, { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { ErrorMessage } from "src/components/reusable/ReactFormComponents";
import { SecureURLRegex } from "src/enums/RegularExpressions";
import Widget from "src/widgets/Widget";
import { PostbackType } from "../NewAuthSchemaModal";
import { TwoColumnLayout } from "src/components/styled_components/Layouts";
import { useAuthSchemas } from "src/hooks/react-query/accounts/useAuthSchemas";
import { DuplicateAlert } from "./_DuplicateAlert";

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const validInputRegex = /^[A-Za-z0-9!@#$%^&*()[\]?_-]{1,}$/;
const invalidMessage =
    "Must only contain letters, numbers, and the following symbols: !@#$%^&*()[]?_-";

export function BearerAuthContent(props) {
    const {
        account,
        visible,
        readOnly,
        schema,
        setUnsavedWork,
        setSaveBtnVisible,
        setSaveBtnDisabled,
        disableSaveWhenUnchanged,
        updatePermissions,
        currentPostbackType,
        selectedPostbackType,
    } = props;
    const {
        username: currentUsername,
        password: currentPassword,
        tokenEndpoint: currentTokenEndpoint,
        tokenHttpMethod: currentTokenHttpMethod,
        tokenParamName: currentTokenParamName,
    } = schema?.bearer || {};

    const { register, errors, control } = useFormContext();
    const username = useWatch({ control, name: "bearer.username" });
    const password = useWatch({ control, name: "bearer.password" });
    const tokenEndpoint = useWatch({ control, name: "bearer.tokenEndpoint" });
    const tokenHttpMethod = useWatch({ control, name: "bearer.tokenHttpMethod" });
    const tokenParamName = useWatch({ control, name: "bearer.tokenParamName" });
    const changesMade =
        username !== currentUsername ||
        password !== currentPassword ||
        tokenEndpoint !== currentTokenEndpoint ||
        tokenHttpMethod !== currentTokenHttpMethod ||
        tokenParamName !== currentTokenParamName;

    const { detectDuplicate } = useAuthSchemas(account.name);
    const identicalAuth = detectDuplicate({
        id: schema?.id,
        type: PostbackType.BEARER,
        bearer: { username, password, tokenEndpoint, tokenHttpMethod, tokenParamName },
    });

    const handleChange = () => setUnsavedWork(changesMade);

    useEffect(() => {
        if (!visible) return;
        const fromOtherAuthType = currentPostbackType !== PostbackType.BEARER;
        setSaveBtnVisible(fromOtherAuthType || changesMade);
        if (disableSaveWhenUnchanged && !changesMade) return setSaveBtnDisabled(true);
        if (identicalAuth) return setSaveBtnDisabled(true);
        setSaveBtnDisabled(!username || !password || !tokenEndpoint || !!errors.bearer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        username,
        password,
        tokenEndpoint,
        tokenHttpMethod,
        tokenParamName,
        currentPostbackType,
        selectedPostbackType,
    ]);

    if (!visible) return null;
    return (
        <div>
            {identicalAuth && DuplicateAlert}
            <TwoColumnLayout>
                <div>
                    <Widget.Input
                        readOnly={readOnly}
                        permissions={updatePermissions}
                        type="text"
                        name="bearer.username"
                        label="Username"
                        value={username}
                        placeholder="username"
                        required={true}
                        ref={register({
                            ...required,
                            validate: (value) => {
                                if (!validInputRegex.test(value)) return invalidMessage;
                                if (value.length < 4) return "Must be 4 or more characters";
                            },
                        })}
                        style={inputStyle}
                        onChange={handleChange}
                    />
                    <ErrorMessage message={errors.bearer?.username?.message} />
                </div>
                <div>
                    <Widget.Input
                        readOnly={readOnly}
                        permissions={updatePermissions}
                        type={readOnly ? "password" : "text"}
                        name="bearer.password"
                        label="Password"
                        value={password}
                        placeholder="password"
                        required={true}
                        ref={register({
                            ...required,
                            pattern: {
                                value: /^[\w\d_-]{1,}$/,
                                message:
                                    "Must be alphanumeric characters and must not contain spaces",
                            },
                            validate: (value) => {
                                if (!validInputRegex.test(value)) return invalidMessage;
                                if (value.length < 8) return "Must be 8 or more characters";
                            },
                        })}
                        style={inputStyle}
                        onChange={handleChange}
                    />
                    <ErrorMessage message={errors.bearer?.password?.message} />
                </div>
            </TwoColumnLayout>
            <Widget.Input
                readOnly={readOnly}
                permissions={updatePermissions}
                type="text"
                name="bearer.tokenEndpoint"
                label="Token Endpoint URL"
                value={tokenEndpoint}
                placeholder="https://customer.domain.com/api/authenticate"
                required={true}
                ref={register({
                    ...required,
                    validate: (value) => {
                        if (!SecureURLRegex.test(value)) return "Must be a valid HTTPS URL";
                    },
                })}
                style={inputStyle}
                onChange={handleChange}
            />
            <ErrorMessage message={errors.bearer?.tokenEndpoint?.message} />
            <TwoColumnLayout>
                <Widget.Select
                    readOnly={readOnly}
                    permissions={updatePermissions}
                    name="bearer.tokenHttpMethod"
                    label="Token Endpoint HTTP Method"
                    options={["GET", "POST"]}
                    ref={register()}
                    defaultValue={currentTokenHttpMethod}
                    onSelect={handleChange}
                />
                <div>
                    <Widget.Input
                        readOnly={readOnly}
                        permissions={updatePermissions}
                        type="text"
                        name="bearer.tokenParamName"
                        label="Token Parameter Name"
                        value={tokenParamName}
                        placeholder="access_token"
                        ref={register()}
                        style={inputStyle}
                        onChange={handleChange}
                    />
                    <ErrorMessage message={errors.bearer?.tokenParamName?.message} />
                </div>
            </TwoColumnLayout>
        </div>
    );
}
