import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

export const GridLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns || "4"}, 1fr);
    grid-gap: ${(props) => props.gridGap || "10px"};
    margin-top: ${(props) => props.marginTop || "0"};
    margin-bottom: ${(props) => props.marginBottom || "0"};
    align-items: ${(props) => props.alignItems || "center"};
`;

export const TwoColumnLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${({ columnGap }) => columnGap || "10px"};
    // align-items: center;
`;
