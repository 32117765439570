import React, { useState, useRef, useContext } from "react";
import $ from "jquery";
import { StatusInfo, CustomerInfo, Actions } from "./InfoSections";
import { resizeEditor } from "../../../functions/ResizeEditor";
import { GetUserSettings, InfoPanelLayout } from "../../../models/Settings";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Widget from "src/widgets/Widget";
import { ThemeContext } from "src/contexts/ThemeContext";
import { useVerification } from "src/hooks/react-query/transactions/useVerification";
import { useTransaction } from "src/hooks/react-query/transactions/useTransaction";

export default function InfoPanel({ searchId, transaction, location }) {
    const goBack = location.state?.breadcrumbReturnsToPreviousPage;
    const history = useHistory();
    const [hidden, setHidden] = useState(
        GetUserSettings().infoPanelLayout === InfoPanelLayout.COLLAPSED
    );
    const infoRef = useRef();
    const { darkMode } = useContext(ThemeContext);
    const verification = useVerification(transaction.trans_uuid);
    const { refetch, isRefetching } = useTransaction(searchId);
    const refresh = () => verification.refetch().then(refetch);

    const handleToggle = () => {
        setTimeout(() => {
            setHidden(!hidden);
            resizeEditor();
        }, 110);
        $(infoRef.current).slideToggle(100);
    };

    return (
        <div id="info-panel">
            <div style={{ display: "grid", gridTemplateColumns: "1fr auto auto", gap: 15 }}>
                <Breadcrumb style={{ marginBottom: "10px" }}>
                    <Breadcrumb.Item
                        onClick={() => (goBack ? history.goBack() : history.push("/transactions"))}>
                        Transactions
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{transaction.trans_uuid}</Breadcrumb.Item>
                </Breadcrumb>
                <Widget.Button
                    variant={darkMode ? "light" : "secondary"}
                    onClick={refresh}
                    title={"Refresh transaction data"}
                    text={isRefetching ? "Refreshing..." : "Refresh"}
                    disabled={isRefetching}
                    icon={
                        !isRefetching && (
                            <i className={"fas fa-sync-alt"} style={{ marginRight: 5 }} />
                        )
                    }
                    style={{ width: 100 }}
                />
                <Widget.Button
                    variant={darkMode ? "light" : "secondary"}
                    onClick={handleToggle}
                    title={hidden ? "Expand section" : "Collapse section"}
                    text={hidden ? "Expand" : "Collapse"}
                    icon={
                        <i
                            className={`fas fa-${hidden ? "expand" : "compress"}`}
                            style={{ marginRight: 5 }}
                        />
                    }
                    style={{ width: 100 }}
                />
            </div>
            <div ref={infoRef} style={{ display: hidden ? "none" : "block" }}>
                <hr />
                <div className="grid-tri-column">
                    <StatusInfo {...transaction} />
                    <CustomerInfo {...transaction} />
                    <Actions
                        searchId={searchId}
                        transaction={transaction}
                        verification={verification}
                        refresh={refresh}
                    />
                </div>
                <hr />
            </div>
        </div>
    );
}
