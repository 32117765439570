import { useQuery } from "@tanstack/react-query";
import { GetPayload } from "src/functions/Http";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useSecureMutate } from "src/hooks/useSecureMutate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const url = (baseUrl, accountName) => `${baseUrl}/accounts/${accountName}/notes`;

export const useNotes = (accountName) => {
    const { baseUrl } = useCurrentEnvironment();
    const { secureFetch } = useSecureFetch(url(baseUrl, accountName));

    const query = useQuery({
        queryKey: ["accounts", accountName, "notes"],
        queryFn: async () => await secureFetch((token) => GetPayload(token)),
    });

    return query;
};

export const useMutateNotes = ({ accountName, onSuccess, onError }) => {
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({ url: url(baseUrl, accountName), onSuccess, onError });
};
