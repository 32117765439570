import React, { useState, useContext, useRef } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Loading } from "../reusable/Loading";
import { lightDarkOutline } from "../../contexts/ThemeContext";
import { ContentHeader } from "../styled_components/ContentHeader";
import ResultsTable from "../reusable/ResultsTable";
import withAuthorizationCheck from "../HOC/withAuthorizationCheck";
import { PostPayload } from "../../functions/Http";
import { Unauthorized } from "../authentication/SigninPrompt";
import { FormatDate } from "../../functions/FormatDate";
import { ErrorAlert, AreYouSureAlert, SuccessAlert } from "../reusable/Alerts";
import { NoContent } from "../transactions/transaction/Fillers";
import { SettingsContext } from "../../contexts/SettingsContext";
import { Settings, LinkStyle } from "../../models/Settings";
import { useHistory } from "react-router-dom";
import { usePendingList } from "src/hooks/react-query/transactions/usePendingList";
import { useBulkUnstick } from "src/hooks/react-query/transactions/usePendingList";

const ResultsTableColumns = [
    {
        tableHeading: "ID",
        objProperty: "id",
    },
    {
        tableHeading: "Account",
        objProperty: "account",
    },
    {
        tableHeading: "State",
        objProperty: "state",
    },
    {
        tableHeading: "Disposition",
        objProperty: "disposition",
    },
    {
        tableHeading: "Retries",
        objProperty: "retries",
    },
    {
        tableHeading: "Time Inserted",
        objProperty: "timeInserted",
        valueFormatter: FormatDate,
        style: { whiteSpace: "nowrap" },
    },
];

function PendingList() {
    const [filteredUUIDs, setFilteredUUIDs] = useState(null);
    const { settings } = useContext(SettingsContext);
    const history = useHistory();
    const searchRef = useRef();

    const { data, isLoading, error, refetch, isRefetching } = usePendingList();
    const pendingList = data?.transactions;

    const { mutate: unstick, isLoading: isUnsticking } = useBulkUnstick({
        onSuccess: () => SuccessAlert().then(refetch),
        onError: () => ErrorAlert(),
    });

    /*
     * ==========================
     *      Button Functions
     * ==========================
     */
    const bulkUnstick = () => {
        AreYouSureAlert({
            html: (
                <span>
                    Unstick <b>{filteredUUIDs.length}</b> transactions?
                </span>
            ),
        }).then(async ({ isConfirmed }) => {
            if (!isConfirmed) return;
            unstick((token) => PostPayload(token, { uuids: filteredUUIDs }));
        });
    };

    const downloadUUIDs = () => {
        AreYouSureAlert({
            html: (
                <span>
                    Download <b>{filteredUUIDs.length}</b> UUIDs?
                </span>
            ),
        }).then(async ({ isConfirmed }) => {
            if (!isConfirmed) return;
            const element = document.createElement("a");
            const file = new Blob([filteredUUIDs], { type: "text/plain" });
            element.href = URL.createObjectURL(file);
            element.download = "pending-uuids.txt";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        });
    };

    /*
     * =================
     *      Buttons
     * =================
     */
    const DownloadUUIDsButton = (
        <Button pill="true" variant="success" size="sm" onClick={downloadUUIDs}>
            <i className="fas fa-download" />
            <span>&nbsp;Download UUIDs</span>
        </Button>
    );
    const UnstickButton = withAuthorizationCheck(
        ["transaction:advanced-unstick"],
        <Button pill="true" variant="primary" size="sm" onClick={bulkUnstick}>
            <i className="fas fa-redo-alt" />
            <span>&nbsp;&nbsp;Unstick ({filteredUUIDs?.length || 0})</span>
        </Button>
    );
    const RefreshButton = (
        <Button
            pill="true"
            variant={lightDarkOutline()}
            size="sm"
            style={{ zIndex: 1 }}
            onClick={refetch}>
            {isRefetching ? (
                <span>Refreshing...</span>
            ) : (
                <>
                    <i className="fas fa-sync-alt" />
                    <span>&nbsp;Refresh</span>
                </>
            )}
        </Button>
    );

    /*
     * ===================
     *      Component
     * ===================
     */
    return (
        <div className="center-content" style={{ rowGap: 0 }}>
            <ContentHeader>
                <span>Pending List</span>
                <span className="content-header-actions">
                    {isLoading ? null : isUnsticking ? (
                        <Spinner animation="border" size="sm" style={{ fontSize: "14px" }} />
                    ) : (
                        <React.Fragment>
                            {pendingList?.length ? DownloadUUIDsButton : null}
                            {pendingList?.length ? <UnstickButton /> : null}
                            {isLoading ? null : RefreshButton}
                        </React.Fragment>
                    )}
                </span>
            </ContentHeader>
            {(() => {
                if (isLoading) return <Loading />;
                if (!error && !pendingList?.length) {
                    return (
                        <NoContent
                            icon={<i className="fas fa-clipboard-check fa-10x" />}
                            text="No transactions pending at this time."
                        />
                    );
                }
                return (
                    <ResultsTable
                        // search={search}
                        searchRef={searchRef}
                        columns={ResultsTableColumns}
                        collection={pendingList}
                        onClickRow={({ uuid }) => {
                            settings[Settings.LINK_STYLE] === LinkStyle.CURRENT_TAB
                                ? history.push(`/transactions?id=${uuid}`)
                                : window.open(`${window.location.origin}/transactions?id=${uuid}`);
                        }}
                        onFilter={(pending) => setFilteredUUIDs(pending.map(({ uuid }) => uuid))}
                    />
                );
            })()}
        </div>
    );
}

export default withAuthorizationCheck(
    ["transaction:read"],
    PendingList,
    <Unauthorized subtext="You are not authorized to view the pending list" />
);
