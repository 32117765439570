import { useQuery } from "@tanstack/react-query";
import { GetPayload } from "src/functions/Http";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useSecureMutate } from "src/hooks/useSecureMutate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const url = (baseUrl, uuid) => `${baseUrl}/transactions/${uuid}/verify`;

export const useVerification = (uuid) => {
    const { baseUrl } = useCurrentEnvironment();
    const { secureFetch } = useSecureFetch(url(baseUrl, uuid));

    const query = useQuery({
        queryKey: ["transactions", uuid, "verification"],
        queryFn: async () => await secureFetch((token) => GetPayload(token)),
        enabled: !!uuid,
    });

    return query;
};

export const useMutateVerification = ({ uuid, onSuccess, onError }) => {
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({ url: url(baseUrl, uuid), onSuccess, onError });
};
