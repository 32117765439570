import React, { useState, useEffect } from "react";
import ACDFilters from "./ACDFilters";
import { ACD_Tables, GlobalColumnNames } from "./TableSchema";
import { PostPayload } from "../../../functions/Http";
import ACDModal from "../modal/ACDModal";
import { ErrorAlert } from "../../reusable/Alerts";
import ResultsTable from "../../reusable/ResultsTable";
import ResultsTableHeader from "../../reusable/ResultsTableHeader";
import withAuthorizationCheck from "../../HOC/withAuthorizationCheck";
import { Unauthorized } from "../../authentication/SigninPrompt";
import { useAuth0 } from "@auth0/auth0-react";
import { FormatDate } from "../../../functions/FormatDate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const stateTextRegex = /^.+(?<!(mapped_)|(regex_))text$/i;

const ResultsTableColumns = (currentTable) =>
    [
        {
            tableHeading: "ID",
            objProperty: "id",
            style: { width: "4em" },
        },
        {
            tableHeading: "State",
            objProperty: "abrev",
            style: { width: "4em" },
        },
        {
            tableHeading: "Description",
            objPropertyRegex: stateTextRegex,
            style: { width: "25em" },
        },
        ...currentTable.columns.map((col) => {
            if (!/^mapped_/i.test(col.column)) return null;
            return {
                tableHeading: col.label,
                objProperty: col.column,
            };
        }),
        {
            tableHeading: "Times Used",
            objProperty: "usedcount",
            valueFormatter: (number) => (number ? number.toLocaleString() : 0),
            style: { width: "7em", textAlign: "right" },
        },
        {
            tableHeading: "Time Modified",
            objPropertyRegex: /(time_modified|time_edited|dateused)/,
            valueFormatter: FormatDate,
            style: { whiteSpace: "nowrap", textAlign: "right" },
        },
    ].filter(Boolean);

const FilterACDs = ({ location, history }) => {
    const { baseUrl } = useCurrentEnvironment();
    const { getAccessTokenSilently } = useAuth0();
    const filters = location.state?.filters || [];
    const acdId = location.state?.acdId;
    const [acds, setAcds] = useState(location.state?.acds || null);
    const [currentTable, setCurrentTable] = useState(
        location.state?.currentTable || ACD_Tables.ACCIDENT
    );

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentAcd, setCurrentAcd] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [clearRefs, setClearRefs] = useState(false);
    const [globalRefs, setGlobalRefs] = useState([]);
    const [tableRefs, setTableRefs] = useState([]);
    const refs = [...globalRefs, ...tableRefs];

    /*
     * ==============================
     *      Apply Search Filters
     * ==============================
     */
    /**
     * Searches for ACD codes based on applied filters. If an ACD is being saved, to update the UI correctly,
     * the search needs to be performed again. When this happens, however, the ACD that was saved may not be
     * returned in the refreshed search due to the changes that were made. Thus, if the ACD needs to be
     * included in the results upon refresh, including the ID of the ACD will add that particular ACD to the
     * search results when the page reloads.
     *
     * TODO: Can this be done better? Somehow remove the need to re-search?
     * - Possible solution:
     *      Retrieve just the one ACD and update the modal and results list?
     *
     * @param {Number} param.acdId the id of a specific ACD to include additionally in the search results.
     */
    const search = async ({ acdId }) => {
        setLoading(true);
        setError(false);
        setAcds(null);

        const columnValuePairs = currentTable.columns.reduce((prev, { column }, idx) => {
            let value = refs[idx]?.current?.value;
            const valueAsInteger = Number.parseInt(value);
            const isInt = Number.isInteger(valueAsInteger);
            if (isInt) return { ...prev, [column]: valueAsInteger };
            if (value) return { ...prev, [column]: value };
            return { ...prev };
        }, {});

        const payload = PostPayload(await getAccessTokenSilently(), {
            table: currentTable.table,
            columns: columnValuePairs,
        });

        fetch(`${baseUrl}/mvrformats-filter`, payload)
            .then(async (res) => {
                const json = await res.json();
                if (res.status !== 200) throw new Error(JSON.stringify(json, null, 4));
                return json;
            })
            .then((json) => {
                const { results: acds } = json;
                console.log({ acds });
                history.push(location.pathname, {
                    filters: columnValuePairs,
                    currentTable,
                    acds,
                    acdId,
                });
            })
            .catch((err) => {
                console.log("Error fetching MVR Mappings:", err);
                ErrorAlert();
                setLoading(false);
                setError(true);
            });
    };

    /*
     * =======================================
     *      Initial Setup (only on mount)
     * =======================================
     */
    useEffect(() => {
        const refArr = (length) => Array(length).fill().map(React.createRef);
        setGlobalRefs([...refArr(GlobalColumnNames.length)]);
        setTableRefs([...refArr(currentTable.columns.length - GlobalColumnNames.length)]);

        if (acdId) {
            const acd = acds?.find((a) => a.id === acdId);
            if (acd) handleShowModal(acd);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
     * ======================
     *      Table Change
     * ======================
     */
    const handleTableChange = (table) => {
        setCurrentTable(table);
        setClearRefs(true);
        history.push(location.pathname, {
            filters,
            currentTable: table,
        });
    };

    useEffect(() => {
        setTableRefs([
            ...Array(currentTable.columns.length - globalRefs.length)
                .fill()
                .map(() => React.createRef()),
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTable]);

    useEffect(() => {
        if (clearRefs) {
            tableRefs.forEach((ref) => {
                if (ref.current) ref.current.value = "";
            });
            setClearRefs(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRefs]);

    /*
     * ===============
     *      Modal
     * ===============
     */
    const handleShowModal = (acd) => {
        setCurrentAcd(acd);
        setShowModal(true);
    };

    const getModal = () => {
        return (
            <ACDModal
                acd={currentAcd}
                table={currentTable}
                show={showModal}
                setShow={setShowModal}
                setAcds={setAcds}
                refresh={search}
            />
        );
    };

    /*
     * ==============================
     *      Filter ACD Component
     * ==============================
     */
    return (
        <div className="center-content">
            <ResultsTableHeader
                headerText="MVR Data Mappings"
                hideActionButtons={loading}
                onSearch={search}
                displayClearButton={!!acds}
                onClear={() => history.push("/mappings")}
            />
            <ACDFilters
                currentTable={currentTable}
                handleTableChange={handleTableChange}
                refs={refs}
                globalRefs={globalRefs}
                tableRefs={tableRefs}
                filters={filters}
            />
            <ResultsTable
                columns={ResultsTableColumns(currentTable)}
                collection={acds}
                onClickRow={handleShowModal}
                loading={loading}
                error={error}
            />
            {showModal && getModal()}
        </div>
    );
};

export default withAuthorizationCheck(
    ["acd:read"],
    FilterACDs,
    <Unauthorized subtext="You are not authorized to view mappings" />
);
