import React, { useState, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import * as Colors from "../_Colors";
import { RequiredStar, InputLabel } from "./_shared";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
import { UserContext } from "../../contexts/UserContext";
const { styled } = StyledComponentContext._currentValue;

/*
 * ====================
 *      Text Input
 * ====================
 */

const getBorderColor = ({ darkMode, error, success }) => {
    if (error) return "orangered";
    if (success) return "var(--success)";
    return Colors.ListItemBorder(darkMode);
};

const StyledInput = styled.input`
    padding: 16px 12px 18px !important;
    font-size: 14px !important;
    border-radius: 3px;

    background: ${({ darkMode }) => Colors.InputBackground(darkMode)} !important;
    color: ${({ darkMode }) => Colors.AltText(darkMode)} !important;
    border: 1px solid ${(props) => getBorderColor(props)} !important;
    ${({ padRight }) => padRight && "padding-right: 30px !important;"}
    ${({ success }) => success && "border: 1px solid var(--success) !important"};
    ${({ error }) => error && "border: 1px solid var(--danger) !important"};

    &::placeholder {
        color: ${({ darkMode }) => Colors.Placeholder(darkMode)} !important;
    }

    &::-webkit-calendar-picker-indicator {
        ${({ darkMode }) => (darkMode ? "filter: invert(1)" : "")};
    }
    #picker {
        background: white;
    }

    &[readonly] {
        background: ${({ darkMode }) => Colors.WidgetBackground(darkMode)} !important;
    }
`;

const ShowPasswordButton = styled.span`
    display: grid;
    place-items: center center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 30px;
    width: 30px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
    &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        content: ${({ showPassword }) => (!showPassword ? `"\\f06e"` : `"\\f070"`)};
    }
`;

const InputWrapper = styled.span`
    position: relative;

    .floating-ph + input {
        margin-top: 3px;
    }

    .floating-ph {
        padding: 1px 4px;
        pointer-events: none;
        position: absolute;
        left: 10px;
        top: 6px;
        transition: 0.2s;
        opacity: 0;
    }

    input:not(:placeholder-shown) + .floating-ph {
        opacity: 1;
        font-size: 11px;
        top: -6px;
        top: -9px;
        left: 8px;
    }

    // Selector that might get used later
    .floating-ph + input:not(:placeholder-shown) {
    }

    // Situates text lower when using a floating placeholder
    // to provide adequate space for input.
    ${({ floatingPlaceholder }) =>
        floatingPlaceholder &&
        `input {
            padding-top: 18px !important;
            padding-bottom: 17px !important;
        }
    `}
`;

/**
 * Accessed via <Widget.Button>, this component is to be passed through the "button"
 * parameter on a <Widget> component.
 *
 * @param {obj} obj.text the text displayed on the button
 * @param {obj} obj.onClick the function to call when the button is clicked
 */
export const Input = React.forwardRef((props, ref) => {
    const { darkMode } = useContext(ThemeContext);
    const { hasPermissions } = useContext(UserContext);
    const canChange = hasPermissions(props.permissions);

    const handleOnChange = (event) => {
        if (canChange) props.onChange(event.target.value);
    };

    const label = props.label ? (
        <InputLabel>
            {`${props.label}:`}
            {props.required && <RequiredStar />}
        </InputLabel>
    ) : null;

    const floatingPlaceholder =
        !props.label && props.placeholder ? (
            <label className="floating-ph">
                {props.placeholder}
                {props.required && <RequiredStar />}
            </label>
        ) : null;

    return (
        <InputWrapper darkMode={darkMode} floatingPlaceholder={!!floatingPlaceholder}>
            {label}
            <StyledInput
                className="form-control"
                darkMode={darkMode}
                ref={ref}
                type={props.type}
                name={props.name || `input-${props.label || props.placeholder}`}
                defaultValue={props.value || ""}
                placeholder={props.placeholder}
                error={props.error}
                success={props.success}
                onChange={props.onChange && handleOnChange}
                style={props.style}
                disabled={!canChange}
                readOnly={props.readOnly}
                {...props.inputAttributes}
            />
            {floatingPlaceholder}
            {props.error && props.errorMessage && (
                <span className="input-error-msg">{props.errorMessage}</span>
            )}
        </InputWrapper>
    );
});

export const PasswordInput = React.forwardRef((props, ref) => {
    const { darkMode } = useContext(ThemeContext);
    const { name, value, placeholder, error, success, onChange } = props;
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div style={{ position: "relative" }}>
            <StyledInput
                className="form-control"
                type={showPassword ? "text" : "password"}
                name={name}
                value={value}
                placeholder={placeholder}
                ref={ref}
                onChange={onChange}
                darkMode={darkMode}
                error={error}
                success={success}
                padRight={true}
            />
            <ShowPasswordButton
                onClick={() => setShowPassword(!showPassword)}
                showPassword={!showPassword}
            />
        </div>
    );
});
