import React from "react";
import { Button, Modal } from "react-bootstrap";
import Settings from "../settings/SettingsPage";
import { SettingsProvider } from "../../contexts/SettingsContext";
import { Loading } from "../reusable/Loading";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useContext } from "react";

export const SettingsModal = ({ show, setShow }) => {
    const { outlineVariant } = useContext(ThemeContext);
    return (
        <Modal show={show} onHide={() => setShow(false)} id="settings-modal" size="lg" centered>
            <SettingsProvider>
                <Settings />
            </SettingsProvider>
            <Modal.Footer>
                <Button variant={outlineVariant} onClick={() => setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const TempModal = ({ show, setShow }) => {
    return (
        <Modal show={show} id="acd-modal" size="lg" centered>
            <Modal.Header>
                <Modal.Title>Temp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};
