import React, { useState, useContext, useEffect } from "react";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { DropdownActiveClass, DropdownDefaults, FontSizes } from "../../enums/DropdownValues";
import { ThemeContext } from "../../contexts/ThemeContext";
import { SettingsContext } from "../../contexts/SettingsContext";

/**
 * Controls the default font settings for the editors.
 *
 * @param {Object} props props
 * @param {Object} props.ls_settingName the setting name in LocalStorage (ls)
 */
export const EditorFontSettings = ({ ls_settingName }) => {
    const { outlineVariant } = useContext(ThemeContext);
    const { settings, updateSettings } = useContext(SettingsContext);
    const storedValue = localStorage.getItem(ls_settingName);
    const validValue = FontSizes.includes(storedValue);
    const [fontSize, setFontSize] = useState(validValue ? storedValue : DropdownDefaults.FONT_SIZE);

    const setDefaultFontSize = (size) => {
        localStorage.setItem(ls_settingName, size);
        setFontSize(size);
        updateSettings();
    };

    /**
     * Ensures accuracy of this setting on screen when other settings change.
     */
    useEffect(() => {
        if (fontSize !== storedValue) setFontSize(storedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    const defaultOption = () => {
        return fontSize === "14" ? null : (
            <React.Fragment>
                <Dropdown.Item eventKey="14">Default</Dropdown.Item>
                <Dropdown.Divider />
            </React.Fragment>
        );
    };
    return (
        <div className="dropdown-setting">
            <Form.Label>Font size:</Form.Label>
            <DropdownButton
                as={InputGroup.Append}
                variant={outlineVariant}
                title={`${fontSize} px`}
                onSelect={(size) => {
                    setDefaultFontSize(size);
                }}>
                {defaultOption()}
                {FontSizes.map((size) => (
                    <Dropdown.Item
                        key={size}
                        eventKey={size}
                        className={fontSize === size ? DropdownActiveClass : ""}>
                        {size}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </div>
    );
};
