import { useMutation } from "@tanstack/react-query";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { LoadingAlert } from "src/components/reusable/Alerts";
import { SuccessAlert } from "src/components/reusable/Alerts";

/**
 * Provides consistent behavior for mutation data.
 *
 * @param {string} arg.url the url to use for sending request
 * @param {function} arg.onSuccess function to perform when request is successful
 * @param {function} arg.onError function to perform when request fails
 */
export const useSecureMutate = ({ url, onSuccess, onError }) => {
    const { secureFetch } = useSecureFetch(url);

    return useMutation({
        mutationFn: secureFetch,
        onMutate: () => LoadingAlert(),
        onSuccess: onSuccess || SuccessAlert,
        onError: (error) => {
            console.log(error);
            onError && onError(error);
        },
    });
};
