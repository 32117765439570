import React, { useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SettingsProvider } from "./contexts/SettingsContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SearchPresetsProvider } from "./contexts/SearchPresetsContext";
import { CookiesProvider } from "react-cookie";
import { UserProvider } from "./contexts/UserContext";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Authorize from "./components/authentication/Authorize";
import SigninPrompt from "./components/authentication/SigninPrompt";
import { LOGIN_REDIRECT, REDIRECT_URI } from "./enums/Endpoints";
import LoginWithRedirect from "./components/authentication/LoginWithRedirect";

/**
 * Auth0 is somehow handling getting the user information
 * whenever it redirects back to Mint. This route just
 * moves back to the landing page and gets rid of the URL
 * containing all of the query params that Auth0 sends to
 * the app when it redirects and ensures the session is
 * created successfully.
 */
const AuthorizeRoute = (
    <Route path="/authorize">
        <Authorize />
    </Route>
);
const SigninRoute = (
    <Route path="/prompt">
        <SigninPrompt />
    </Route>
);
const RedirectLoginRoute = (
    <Route path={`/${LOGIN_REDIRECT.split("/").pop()}`}>
        <LoginWithRedirect />
    </Route>
);

const queryClient = new QueryClient();

const Root = () => {
    const [returnUrl, setReturnUrl] = useState(null);
    return (
        <React.StrictMode>
            <Router>
                <Auth0Provider
                    domain="tmlinfo.us.auth0.com"
                    clientId="qmWfEZA6APUWFOqjqhewe4gnfpttjNZK"
                    redirectUri={REDIRECT_URI}
                    scope="openid profile email"
                    audience="https://hivemind.tmlinfo.com/"
                    cacheLocation="localstorage" // Uses memory by default which requires sign-in on page refresh
                    useRefreshTokens
                    onRedirectCallback={(appState) => setReturnUrl(appState?.returnUrl)}>
                    <SettingsProvider>
                        <ThemeProvider>
                            <CookiesProvider>
                                <UserProvider>
                                    <SearchPresetsProvider>
                                        <Switch>
                                            {SigninRoute}
                                            {AuthorizeRoute}
                                            {RedirectLoginRoute}
                                            <Route>
                                                <QueryClientProvider client={queryClient}>
                                                    <App returnUrl={returnUrl} />
                                                    <ReactQueryDevtools />
                                                </QueryClientProvider>
                                            </Route>
                                        </Switch>
                                    </SearchPresetsProvider>
                                </UserProvider>
                            </CookiesProvider>
                        </ThemeProvider>
                    </SettingsProvider>
                </Auth0Provider>
            </Router>
        </React.StrictMode>
    );
};

ReactDOM.render(<Root />, document.getElementById("root"));
