import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { AreYouSureAlert } from "../../../../reusable/Alerts";
import EditNote from "./EditNote";
import ViewNote from "./ViewNote";

/*
 * ===============
 *      Modal
 * ===============
 */
class NoteModal extends Component {
    origValues = {
        title: this.props.note?.title || "",
        text: this.props.note?.text || "",
    };

    state = { ...this.origValues, editable: false };

    render() {
        this.setState.bind(this);
        const { note, showModal, onSave, onDelete, saving } = this.props;
        const { title, text, editable } = this.state;

        const updatedValues = this.updatedValues();
        const modalTitle = note.id ? (editable ? "Edit" : note.title) : "New Note";
        const saveDisabled = note.id ? !Object.keys(updatedValues).length : !title || !text;

        return (
            <Modal
                size="lg"
                show={showModal}
                onHide={() => this.onCancel({ saveDisabled })}
                id="edit-note-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!note.id || editable ? (
                        <EditNote
                            title={title}
                            text={text}
                            onChangeTitle={(title) => this.setState({ title })}
                            onChangeText={(text) => this.setState({ text })}
                            onSave={() => onSave({ title, text })}
                            onCancel={() => this.onCancel({ editable, saveDisabled })}
                            saveDisabled={saveDisabled}
                            saving={saving}
                        />
                    ) : (
                        <ViewNote
                            note={note}
                            title={title}
                            text={text}
                            onEdit={() => this.setState({ editable: true })}
                            onClose={() => this.onCancel({ saveDisabled })}
                            onDelete={() => onDelete()}
                        />
                    )}
                </Modal.Body>
            </Modal>
        );
    }

    /**
     * Cancels the editing of the note and closes the modal.
     * If the note has been changed, will prompt user to confirm
     * they want to finish editing before closing modal.
     *
     * @param {Object} param.saveDisabled whether or not the save button is disabled
     */
    onCancel = async ({ editable, saveDisabled }) => {
        if (!editable) return this.props.setShowModal(false);
        if (saveDisabled) return this.setState({ editable: false });
        const warning = "Are you sure you want to stop editing? All unsaved changes will be lost.";
        const { isConfirmed } = await AreYouSureAlert({ text: warning });
        isConfirmed && this.setState({ editable: false });
    };

    /**
     * Determines which values (between title and text) have been updated and
     * returns them in an object.
     */
    updatedValues = () =>
        Object.entries(this.origValues)
            .filter(([key, val]) => val !== this.state[key])
            .reduce((prev, [key]) => ({ ...prev, [key]: this.state[key] }), {});
}

export default NoteModal;
