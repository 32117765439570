import React from "react";
import { Dropdown } from "react-bootstrap";

export const FontSizes = [...new Array(11)].map((v, index) => String(index * 2 + 4));

export const Language = {
    types: {
        PLAIN_TEXT: "Plain text",
        JSON: "JSON",
        XML: "XML",
        HTML: "HTML",
    },
    PLAIN_TEXT: "Plain text",
    format: function (lang) {
        return lang.replace(/\s+/, "_").toLowerCase();
    },
};

export const DropdownActiveClass = "blue-outline";

export const Data = {
    RAW_DATA: "Raw Data",
    STATE_RAW: "State Raw",
    STATE_CLEAN: "State Clean",
    BRIDGE_ERROR: "Bridge Error",
    NORMALIZER: "Normalizer",
    NORMALIZER_IN: "Normalizer Input",
    NORMALIZER_OUT: "Normalizer Output",
    LOG_DATA: "Log Data",
};

export const DataTypes = [
    Data.RAW_DATA,
    Data.STATE_RAW,
    Data.STATE_CLEAN,
    Data.BRIDGE_ERROR,
    Data.NORMALIZER,
    Data.NORMALIZER_IN,
    Data.NORMALIZER_OUT,
    Data.LOG_DATA,
];

export const DropdownDefaults = {
    CASE_SENSITIVE: false,
    DATA: Data.RAW_DATA,
    LANGUAGE: Language.types.XML,
    FONT_SIZE: "14",
};

export const LanguageDropdownItems = ({ currentLang }) => {
    return Object.entries(Language.types).map(([, lang], index) => (
        <React.Fragment key={index}>
            <Dropdown.Item
                eventKey={lang}
                className={currentLang === lang ? DropdownActiveClass : ""}>
                {lang}
            </Dropdown.Item>
            {index === 0 ? <Dropdown.Divider /> : null}
        </React.Fragment>
    ));
};

export const FontSizeDropdownItems = ({ currentSize }) => {
    return FontSizes.map((size) => (
        <Dropdown.Item
            key={size}
            eventKey={size}
            className={currentSize === size ? DropdownActiveClass : ""}>
            {size}
        </Dropdown.Item>
    ));
};
