import React, { useState } from "react";
import { TwoColumnLayout } from "../../styled_components/Layouts";
import Widget from "../../../widgets/Widget";
import SaveCancelButtons from "../../reusable/SaveCancelButtons";

/**
 * Converts an array where the each element in the array contains two elements
 * (such as:
 *  [
 *      ["key1", "value1"],
 *      ["key2", "value2"]
 *  ]
 * ) into an object in the form of:
 *  {
 *      key1: "value1",
 *      key2: "value2"
 *  }.
 *
 * @param {Array} arr the array to convert to an object
 */
const arrayToObject = (arr) => arr.reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});

export default function EditableFields({ acd, table, editMode, onSave, onCancel, saving }) {
    const mappedFieldRegex = /mapped/;
    const mappedFields = Object.entries(acd).filter(([key]) => mappedFieldRegex.test(key));

    const startingValues = arrayToObject(mappedFields);
    const [values, setValues] = useState(startingValues);
    const valuesChanged = JSON.stringify(values) !== JSON.stringify(startingValues);

    /**
     * Updates the state appropriately when the input changes.
     *
     * @param {String} key the key (field name) whose value is to be updated in the state object
     * @param {String} value the new value to assign to the key
     */
    const handleChange = (key, value) => {
        setValues((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const inputs = mappedFields.map(([key, value], idx) => {
        const label = table.columns.find((c) => c.column === key)?.label;
        return (
            <Widget.Input
                key={idx}
                type="text"
                label={label}
                placeholder={label}
                value={value}
                onChange={(newVal) => handleChange(key, newVal)}
            />
        );
    });

    return editMode ? (
        <div>
            <Widget label="Edit Mappings">
                <TwoColumnLayout>{inputs}</TwoColumnLayout>
            </Widget>
            <SaveCancelButtons
                visible
                showSpinner={saving}
                saveDisabled={!valuesChanged}
                onSave={() => onSave(values)}
                onCancel={() => onCancel(valuesChanged)}
            />
        </div>
    ) : null;
}
