import React, { useState, useContext, useEffect } from "react";
import { Form, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { DropdownActiveClass, DataTypes, DropdownDefaults } from "../../enums/DropdownValues";
import { ThemeContext } from "../../contexts/ThemeContext";
import { SettingsContext } from "../../contexts/SettingsContext";

/**
 * Controls the default data settings for the editors.
 *
 * @param {Object} props props
 * @param {Object} props.ls_settingName the setting name in LocalStorage (ls)
 */
export const EditorDataSettings = ({ editor, ls_settingName }) => {
    const { outlineVariant } = useContext(ThemeContext);
    const { settings, updateSettings } = useContext(SettingsContext);
    const storedValue = localStorage.getItem(ls_settingName) || "";
    const validValue = DataTypes.includes(storedValue) || storedValue === "";
    const [data, setData] = useState(validValue ? storedValue : DropdownDefaults.DATA);

    const setDefaultData = (type) => {
        localStorage.setItem(ls_settingName, type);
        setData(type);
        updateSettings();
    };

    /**
     * Ensures accuracy of this setting on screen when other settings change.
     */
    useEffect(() => {
        if (data !== storedValue) setData(storedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <div className="dropdown-setting">
            <Form.Label>Preferred data:</Form.Label>
            <DropdownButton
                as={InputGroup.Append}
                variant={outlineVariant}
                title={data ? data : "None"}
                onSelect={(type) => {
                    setDefaultData(type);
                }}>
                <Dropdown.Item
                    key={"none"}
                    eventKey={""}
                    className={!data ? DropdownActiveClass : ""}>
                    None
                </Dropdown.Item>
                <Dropdown.Divider />
                {DataTypes.map((type, index) => (
                    <Dropdown.Item
                        key={index}
                        eventKey={type}
                        className={type === data ? DropdownActiveClass : ""}>
                        {type}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </div>
    );
};
