export const API_PATH = "ui";
export const BASE_URI = `https://t45epw9j6j.execute-api.us-east-2.amazonaws.com/${API_PATH}`;

const redirectDomain =
    {
        "https://d37j4be3dn638l.cloudfront.net": "https://mint.tmlinfo.com",
        "https://d3l14vs3k361yz.cloudfront.net": "https://staging.tmlinfo.com",
    }[window.location.origin] || window.location.origin;

export const SIGNIN_URL = `${window.location.origin}/signin`;
export const LOGIN_REDIRECT = `${window.location.origin}/redirect`;
export const REDIRECT_URI = `${redirectDomain}/authorize`;
