import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ReactSwal = withReactContent(Swal);

export const SuccessAlert = () => {
    return Swal.fire({
        icon: "success",
        title: "Success!",
        timer: 1000,
        showConfirmButton: false,
    });
};

export const ErrorAlert = (text) => {
    return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: text || "Something went wrong!",
    });
};

export const AreYouSureAlert = (content = {}) => {
    const { html, text } = content;
    return ReactSwal.fire({
        icon: "question",
        title: "Are you sure?",
        html: html,
        text: text,
        confirmButtonText: "Yes, I'm sure",
        cancelButtonText: "No, nevermind",
        showCancelButton: true,
        reverseButtons: true,
    });
};

export const UnsavedChangesAlert = () => {
    return ReactSwal.fire({
        icon: "warning",
        title: "Continue?",
        text: "Any unsaved changes will be lost.",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        reverseButtons: true,
    });
};

export const RefreshAlert = (onRefresh, delaySeconds) => {
    let timerInterval;
    Swal.fire({
        icon: "info",
        html: `<h3>Refreshing in... <span></span></h3>`,
        timer: (Number.parseInt(delaySeconds, 10) || 3) * 1000,
        confirmButtonText: "Refresh now!",
        didOpen: () => {
            const countdown = Swal.getHtmlContainer().querySelector("span");
            timerInterval = setInterval(() => {
                countdown.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
            }, 100);
        },
        willClose: () => {
            clearInterval(timerInterval);
            onRefresh ? onRefresh() : window.location.reload();
        },
    });
};

export const CustomReactAlert = (content = {}) => ReactSwal.fire({ ...content });

export const LoadingAlert = (text) => {
    Swal.fire({
        title: text === undefined ? "Loading..." : text === null ? "" : text,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
    });
};
