// export const ZonesByCity = {
//     New_York: "America/New_York",
//     Detroit: "America/Detroit",
//     Kentucky_Louisville: "America/Kentucky/Louisville",
//     Kentucky_Monticello: "America/Kentucky/Monticello",
//     Indiana_Indianapolis: "America/Indiana/Indianapolis",
//     Indiana_Vincennes: "America/Indiana/Vincennes",
//     Indiana_Winamac: "America/Indiana/Winamac",
//     Indiana_Marengo: "America/Indiana/Marengo",
//     Indiana_Petersburg: "America/Indiana/Petersburg",
//     Indiana_Vevay: "America/Indiana/Vevay",
//     Chicago: "America/Chicago",
//     Indiana_Tell_City: "America/Indiana/Tell_City",
//     Indiana_Knox: "America/Indiana/Knox",
//     Menominee: "America/Menominee",
//     North_Dakota_Center: "America/North_Dakota/Center",
//     North_Dakota_New_Salem: "America/North_Dakota/New_Salem",
//     North_Dakota_Beulah: "America/North_Dakota/Beulah",
//     Denver: "America/Denver",
//     Boise: "America/Boise",
//     Phoenix: "America/Phoenix",
//     Los_Angeles: "America/Los_Angeles",
//     Anchorage: "America/Anchorage",
//     Juneau: "America/Juneau",
//     Sitka: "America/Sitka",
//     Metlakatla: "America/Metlakatla",
//     Yakutat: "America/Yakutat",
//     Nome: "America/Nome",
//     Adak: "America/Adak",
//     Honolulu: "Pacific/Honolulu",
// };

import moment from "moment-timezone";

export const ZonesByState = {
    UTC: [],
    Eastern: [
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "IN",
        "KY",
        "ME",
        "MD",
        "MA",
        "MI",
        "NH",
        "NJ",
        "NY",
        "NC",
        "OH",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "VT",
        "VA",
        "WV",
    ],
    Central: ["AL", "AR", "IL", "IA", "KS", "LA", "MN", "MS", "MO", "NE", "ND", "OK", "TX", "WI"],
    Mountain: ["AZ", "CO", "ID", "MT", "NM", "UT", "WY"],
    Pacific: ["CA", "NV", "OR", "WA"],
    Alaska: ["AK"],
    Hawaii: ["HI"],
    Atlantic: ["PR", "VI", "USVI"],
    Chamorro: ["GU"],
};

export const TzByState = (abbrev) => {
    let zone = null;
    Object.entries(ZonesByState).forEach(([key, value]) => {
        if (value.includes(abbrev.toUpperCase())) zone = key;
    });
    return zone ? `US/${zone}` : "UTC";
};

export const ConvertTimeZone = (time, zone) => {
    const tz = zone === "UTC" ? zone : `US/${zone}`;
    return moment(time).tz(tz).format("YYYY-MM-DD HH:mm:ss");
};
