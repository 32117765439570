import React from "react";
import { ThemeSettings } from "./ThemeSettings";
import { LayoutSettings } from "./LayoutSettings";
import { EditorSettings, Sides } from "./EditorSettings";
import { ResetButtons } from "./ResetButtons";
import { SearchMirrorSettings } from "./SearchMirrorSettings";
import { SearchPresetSettings } from "./SearchPresetSettings";
import { LinkStyleSettings } from "./LinkStyleSettings";
import { Alert } from "react-bootstrap";

const SettingsInfoAlert = (
    <Alert
        variant="primary"
        style={{
            marginBottom: 50,
            opacity: 0.8,
            display: "flex",
            alignItems: "center",
            gap: "15px",
        }}>
        <i className="fas fa-info-circle fa-2x" />
        <span>Some settings require the page to be refreshed before taking effect.</span>
    </Alert>
);

export default function Settings() {
    return (
        <div id="settings">
            <h2>
                <span>
                    <span className="prev-page"></span>
                    Settings
                </span>
                <ResetButtons />
            </h2>
            <hr />
            <div id="settings-inner">
                <div className="page-settings">
                    {SettingsInfoAlert}
                    <div className="">
                        <ThemeSettings />
                        <LinkStyleSettings />
                        <br />
                        <b>Transaction Page Defaults</b>
                        <hr />
                        <LayoutSettings />
                        <SearchMirrorSettings />
                    </div>
                </div>
                <div className="editor-settings">
                    <EditorSettings side={Sides.LEFT} />
                    <EditorSettings side={Sides.RIGHT} />
                </div>
                <div className="search-presets">
                    <SearchPresetSettings />
                </div>
            </div>
        </div>
    );
}
