import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "../../contexts/ThemeContext";
import * as Colors from "../_Colors";
import { RequiredStar, InputLabel } from "./_shared";
import { UserContext } from "../../contexts/UserContext";
import { StyledComponentContext } from "src/contexts/StyledComponentsContext";
import { Input } from "./WidgetInput";
const { styled } = StyledComponentContext._currentValue;

/*
 * ======================
 *      Select Input
 * ======================
 */
const SelectWrapper = styled.div`
    position: relative;
    margin-bottom: 5px;

    & select {
        background: ${({ darkMode }) => Colors.InputBackground(darkMode)} !important;
        border: 1px solid ${({ darkMode }) => Colors.ListItemBorder(darkMode)} !important;
        color: ${({ darkMode }) => Colors.AltText(darkMode)} !important;
        font-size: 14px !important;
        border-radius: 4px;
        height: 36px;
        width: 100%;
        padding-left: 10px;
        -webkit-appearance: none;
        cursor: pointer;

        &:disabled {
            cursor: default;
        }
    }

    &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        position: absolute;
        content: "\\f0d7";
        right: 15px;
        top: 7px;
        color: ${({ darkMode }) => Colors.AltText(darkMode)} !important;
        pointer-events: none;
    }
`;

export const Select = React.forwardRef((props, ref) => {
    const { darkMode } = useContext(ThemeContext);
    const { hasPermissions } = useContext(UserContext);
    const {
        label,
        name,
        options,
        defaultValue,
        optionValueProp,
        optionLabelProp,
        noValueOption,
        noValueOptionLabel,
        onSelect,
        permissions,
        readOnly,
    } = props;

    const DropdownOptions = options?.map((option, index) => {
        const value = optionValueProp ? option[optionValueProp] : option;
        const label = optionLabelProp ? option[optionLabelProp] : option;
        return (
            <option key={index} index={index} value={value}>
                {label}
            </option>
        );
    });

    const DefaultOption = defaultValue
        ? optionValueProp
            ? defaultValue[optionValueProp]
            : defaultValue
        : "";

    const NoValueOption = noValueOption && (
        <option value="">{noValueOptionLabel || "-- Select --"}</option>
    );

    if (readOnly) {
        const option = DropdownOptions.find(({ props }) => props.value === defaultValue);
        const value = option?.props.children || noValueOptionLabel || "NONE";
        return (
            <Input
                readOnly
                type="text"
                label={label}
                name={name}
                placeholder={value}
                value={value}
                permissions={permissions}
            />
        );
    }

    return (
        <span>
            <InputLabel>
                {`${label ? label + ":" : ""}`}
                {props.required && <RequiredStar />}
            </InputLabel>
            <SelectWrapper darkMode={darkMode}>
                <Dropdown
                    className="custom-scroll-sm"
                    as="select"
                    name={name}
                    ref={ref}
                    disabled={!hasPermissions(permissions)}
                    defaultValue={DefaultOption}
                    onChange={(event) => {
                        if (!onSelect) return;
                        return optionValueProp
                            ? onSelect(event.target.value)
                            : onSelect(options[event.target.index]); // value is index of options array
                    }}>
                    {NoValueOption}
                    {DropdownOptions}
                </Dropdown>
            </SelectWrapper>
        </span>
    );
});
