const Column = (label, column, inputAttributes) => ({ label, column, inputAttributes });

const GlobalColumns = [
    Column("ID", "id"),
    Column("Times Used", "usedcount"),
    Column("State Abbrev.", "abrev", { maxLength: 2 }),
    Column("Last UUID", "last_uuid"),
];

export const ACD_Tables = {
    ACCIDENT: {
        label: "Accident",
        table: "accident",
        columns: [
            ...GlobalColumns,
            Column("Accident Text", "acci_text"),
            Column("Mapped Code", "mapped_code"),
            Column("Mapped Text", "mapped_text"),
        ],
    },
    CLASS: {
        label: "Class",
        table: "class",
        columns: [
            ...GlobalColumns,
            Column("Class Text", "class_text"),
            Column("Mapped Type 1", "mapped_type1"),
            Column("Mapped Type 2", "mapped_type2"),
            Column("Mapped Type 3", "mapped_type3"),
        ],
    },
    CONVICTION: {
        label: "Conviction",
        table: "conviction",
        columns: [
            ...GlobalColumns,
            Column("Conviction ACD", "conv_acd"),
            Column("Conviction Code", "conv_code"),
            Column("Conviction Text", "conv_text"),
            Column("Regex Text", "regex_text"),
            Column("Mapped ACD", "mapped_acd"),
            Column("Mapped Code", "mapped_code"),
            Column("Mapped Text", "mapped_text"),
        ],
    },
    ENDORSEMENTS: {
        label: "Endorsement",
        table: "endo",
        columns: [
            ...GlobalColumns,
            Column("Endorsement Text", "endo_text"),
            Column("Mapped Endorsement", "mapped_endo"),
        ],
    },
    RESTRICTIONS: {
        label: "Restriction",
        table: "rest",
        columns: [
            ...GlobalColumns,
            Column("Restriction Text", "rest_text"),
            Column("Mapped Restriction", "mapped_rest"),
        ],
    },
    STATUS: {
        label: "Status",
        table: "status",
        columns: [
            ...GlobalColumns,
            Column("Mapped Status", "mapped"),
            Column("Mapped Description", "mapped_desc"),
            Column("Status text", "stext"),
        ],
    },
    WITHDRAWAL: {
        label: "Withdrawal",
        table: "withdrawal",
        columns: [
            ...GlobalColumns,
            Column("ACD", "acd"),
            Column("State Code", "scode"),
            Column("Violation Text", "vtext"),
            Column("Regex Text", "regex_text"),
            Column("Mapped ACD", "mapped_acd"),
            Column("Mapped Code", "mapped_code"),
            Column("Mapped Text", "mapped_text"),
            Column("Mapped Group", "mapped_group"),
        ],
    },
};

export const GlobalColumnLabels = GlobalColumns.map((c) => c.label);
export const GlobalColumnNames = GlobalColumns.map((c) => c.column);

// (() => {
// Object.entries(Tables).forEach(([tablename, { columns }]) => {
//     console.log(tablename);
//     columns.map(({ label, column }) => console.log(`Column("${label}", "${column}"),`));
// });
// console.log({ Tables });
// })();
