import React from "react";
import { Loading } from "../../reusable/Loading";
import { FullScreenTip, LearnRegexTip } from "./Tips";
import { parseQueryParams } from "../../../functions/QueryStrings";
import InfoPanel from "./InfoPanel";
import EditorGroup from "./EditorGroup";
import { Redirect } from "react-router-dom";
import withAuthorizationCheck from "../../HOC/withAuthorizationCheck";
import { useTransaction } from "src/hooks/react-query/transactions/useTransaction";
import { ErrorAlert } from "src/components/reusable/Alerts";

const Transaction = ({ location }) => {
    const { id: searchId } = parseQueryParams(location.search);
    const { data, isLoading, error, isRefetching } = useTransaction(searchId);

    if (error) {
        ErrorAlert();
        return null;
    }

    const content = isLoading ? (
        <Loading />
    ) : searchId ? (
        <React.Fragment>
            <InfoPanel
                searchId={searchId}
                transaction={data}
                location={location}
                isRefetching={isRefetching}
            />
            <EditorGroup
                transactionId={searchId}
                noData={Object.keys(data?.records ?? {}).length === 0}
            />
            <div id="tips">
                <FullScreenTip />
                <LearnRegexTip />
            </div>
        </React.Fragment>
    ) : (
        <Redirect to={{ pathname: "/transactions", state: { id: searchId, transactions: [] } }} />
    );

    return <div id="content">{content}</div>;
};

export default withAuthorizationCheck(
    ["transaction:read"],
    Transaction,
    <Redirect to="/transactions" />
);
