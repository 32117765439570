import React from "react";
import Widget from "../../../../widgets/Widget";
import { GridLayout } from "../../../styled_components/Layouts";

/*
 * ========================
 *      Main Component
 * ========================
 */
const WarningFilters = (props) => {
    const { register, defaultValues } = props;
    const { rangeFrom, rangeTo, numResults } = defaultValues;

    return (
        <div>
            <Widget>
                <TextFilters register={register} defaultValues={defaultValues} />
                <GridLayout columns="3">
                    <Widget.Input
                        label="From"
                        type="datetime-local"
                        value={rangeFrom}
                        name="rangeFrom"
                        ref={register()}
                    />
                    <Widget.Input
                        label="To"
                        type="datetime-local"
                        value={rangeTo}
                        name="rangeTo"
                        ref={register()}
                    />
                    <Widget.Select
                        label="Number of Results"
                        options={[25, 50, 100, 250, 500]}
                        defaultValue={numResults}
                        name="limit"
                        ref={register()}
                    />
                </GridLayout>
            </Widget>
        </div>
    );
};

export default WarningFilters;

/*
 * =======================
 *      Subcomponents
 * =======================
 */

function TextFilters(props) {
    const { defaultValues, register } = props;

    const inputs = [
        { inputName: "id", placeholder: "ID" },
        { inputName: "severity", placeholder: "Severity" },
        { inputName: "pname", placeholder: "Process Name" },
        { inputName: "pid", placeholder: "Process ID" },
        { inputName: "ppid", placeholder: "Parent Process ID" },
        { inputName: "uuid_trans", placeholder: "Transaction UUID" },
        { inputName: "uuid_in", placeholder: "UUID In" },
        { inputName: "uuid_out", placeholder: "UUID Out" },
        { inputName: "reviewed_by", placeholder: "Reviewed By" },
    ];

    const toWidgetInput = ({ inputName, placeholder }) => (
        <Widget.Input
            key={inputName}
            type="text"
            placeholder={placeholder}
            value={defaultValues[inputName] || ""}
            name={inputName}
            ref={register()}
        />
    );

    return (
        <React.Fragment>
            <GridLayout columns="5">{inputs.slice(0, 5).map(toWidgetInput)}</GridLayout>
            <GridLayout columns="4">{inputs.slice(5).map(toWidgetInput)}</GridLayout>
        </React.Fragment>
    );
}
