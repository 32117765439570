import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { Settings, GetUserSettings, LinkStyle } from "../../models/Settings";
import DualButtonGroup from "./DualButtonGroup";
import { SettingsContext } from "../../contexts/SettingsContext";

/**
 * Setting to control the behavior of opening transactions.
 */
export const LinkStyleSettings = () => {
    const { settings, updateSettings } = useContext(SettingsContext);
    const storedValue = GetUserSettings().linkStyle;
    const [linkStyle, setLinkStyle] = useState(storedValue);

    const updateLinkStyleSettings = (linkStyle) => {
        localStorage.setItem(Settings.LINK_STYLE, linkStyle);
        setLinkStyle(linkStyle);
        updateSettings();
    };

    useEffect(() => {
        if (linkStyle !== storedValue) setLinkStyle(storedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <div className="dual-btn-option">
            <Form.Label style={{ display: "inline-block" }}>Open transaction links in:</Form.Label>
            <DualButtonGroup
                leftActive={linkStyle === LinkStyle.CURRENT_TAB}
                leftText="Current tab"
                rightText="New tab"
                leftClick={() => updateLinkStyleSettings(LinkStyle.CURRENT_TAB)}
                rightClick={() => updateLinkStyleSettings(LinkStyle.NEW_TAB)}
            />
        </div>
    );
};
