import React, { useState, useContext, useEffect } from "react";
import { Badge } from "react-bootstrap";
import Widget from "../../../../../widgets/Widget";
import { UserContext } from "../../../../../contexts/UserContext";
import DetailsModal from "./DetailsModal";

const Details = ({ account }) => {
    const [showModal, setShowModal] = useState(false);
    const { hasPermissions } = useContext(UserContext);

    const Status = () => {
        const active = /^active$/i.test(account.status);
        return (
            <Badge pill="true" variant={active ? "success" : "danger"}>
                {account.status}
            </Badge>
        );
    };

    const BillTo = () => {
        if (!account.billingId)
            return (
                <Badge pill variant="danger">
                    NONE
                </Badge>
            );
        if (account.id === account.billingId) return <span>SELF</span>;

        return (
            <span
                className="ptr underline"
                onClick={() => window.open(`/accounts/${account.billingName}`)}>
                {account.billingName} <i className="fas fa-external-link-alt"></i>
            </span>
        );
    };

    const editHandler = async () => setShowModal(true);

    useEffect(() => {}, [account]);

    return (
        <React.Fragment>
            <DetailsModal account={account} showModal={showModal} setShowModal={setShowModal} />
            <Widget
                label="Details"
                onEdit={hasPermissions(["account:update"]) ? editHandler : null}>
                <Widget.ListItem label="Account status" value={Status()} />
                <Widget.ListItem label="Account type" value={account.type || <i>Unspecified</i>} />
                <Widget.ListItem label="Account name" value={account.name} />
                <Widget.ListItem label="Account ID" value={account.id} />
                <Widget.ListItem label="Bill to" value={BillTo()} />
            </Widget>
        </React.Fragment>
    );
};

export default Details;
