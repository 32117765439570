import React, { useContext, useRef } from "react";
import Toggle from "../../components/reusable/Toggle/Toggle";
import { ThemeContext } from "../../contexts/ThemeContext";
import * as Colors from "../_Colors";
import { Button } from "./WidgetButton";
import $ from "jquery";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled, css } = StyledComponentContext._currentValue;
/*
 * ====================
 *      List Items
 * ====================
 */
const changedIndicator = `
    border-right: 3px solid #2196f3;
    padding-right: 6px;
`;

const hoverStyles = css`
    cursor: pointer;
    &:hover {
        background: ${({ darkMode }) => (darkMode ? "#686868" : "#d0d0d0")};
    }
`;

const Subtext = styled.p`
    color: ${({ darkMode }) => Colors.Subtext(darkMode)};
    font-size: 13px;
    margin: 0;
    grid-column-start: 1;
    grid-column-end: 3;
`;

const ListItemElem = styled.div`
    background: ${({ darkMode }) => Colors.ListItemBackground(darkMode)};
    border-radius: 2px;
    padding: 8px;
    min-height: 40px;
    border: 1px solid
        ${({ darkMode }) => Colors.ListItemBorder(darkMode)}};
    ${({ changed }) => changed && changedIndicator}
    
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;

    ${({ onClick }) => onClick && hoverStyles}
`;

const ListItemContentElem = styled.div`
    background: ${({ darkMode }) => (darkMode ? "#333" : "white")};
    font-size: 13px;
    margin-top: -10px;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid
        ${({ darkMode }) => Colors.WidgetBorder(darkMode)}};
    color: ${({ darkMode }) => Colors.AltText(darkMode)}};
    white-space: pre-wrap;
`;

const ListItemContentWrapper = styled.div`
    margin: 0 auto;
    padding: 0 40px 8px;
`;

const OptionListWrapper = styled.div`
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    padding: ${({ extraPadding }) => (extraPadding ? "0px 40px" : "2px 5px")};
    margin-bottom: 5px;
    gap: 10px;
`;

/**
 * Accessed via <Widget.ListItem>, this component provides a simple list-style item
 * for use within a <Widget> component. If children are passed to this component, it
 * will automatically become collapsible and change color/cursor when hovered over.
 *
 * @param {String} props.label the primary text to display for this item
 * @param {*} props.value right-aligned value to display
 * @param {String} props.subtext text to display under the label
 * @param {Object} props.options array options for the ListItem
 * @param {String} props.options.name the name of the option (Edit, Delete, etc.)
 * @param {String} props.options.title the value that appears when this option is hovered over
 * @param {Function} props.options.handler the onClick handler for when the option is clicked on
 */
export const ListItem = ({ label, value, subtext, options, onClick, children }) => {
    const { darkMode } = useContext(ThemeContext);
    const collapsibleRef = useRef(null);

    const optionElements = options?.filter(Boolean).map((option, idx) => {
        const { name, title, variant, handler, disabled, disabledTitle } = option;
        const defaultTitle = `${name} this item`;

        return (
            <React.Fragment key={idx}>
                <Button
                    disabled={disabled}
                    variant={variant || (darkMode ? "outline-light" : "outline-secondary")}
                    onClick={handler}
                    title={disabled ? disabledTitle : title || defaultTitle}
                    text={name}
                />
            </React.Fragment>
        );
    });

    const clickHandler =
        children || options?.length ? () => $(collapsibleRef.current).slideToggle(50) : null;

    return (
        <React.Fragment>
            <ListItemElem darkMode={darkMode} onClick={clickHandler || onClick}>
                <span>{label}</span>
                <span>{value}</span>
                {subtext && <Subtext darkMode={darkMode}>{subtext}</Subtext>}
            </ListItemElem>
            {(children || options?.length) && (
                <div style={{ display: "none" }} ref={collapsibleRef}>
                    {children && (
                        <ListItemContentWrapper>
                            {React.Children.map(children, (child) => {
                                return React.cloneElement(child, { darkMode });
                            })}
                        </ListItemContentWrapper>
                    )}
                    <OptionListWrapper extraPadding={!!children}>
                        {optionElements}
                    </OptionListWrapper>
                </div>
            )}
        </React.Fragment>
    );
};

/**
 * Provides a pre-styled div for collapsible content underneath a ListItem.
 * Use is purely for aesthetics and does not provide functionality.
 */
export const ListItemContent = ({ children }) => {
    const { darkMode } = useContext(ThemeContext);
    return (
        <ListItemContentElem darkMode={darkMode} className="custom-scroll custom-scroll-sm">
            {children}
        </ListItemContentElem>
    );
};

/**
 * List item with a toggle switch embedded.
 *
 * @param {Object} props the props for this component
 * @param {String} props.label label to show on the list item
 * @param {Boolean} props.checked boolean value for default state of toggle switch
 * @param {Number} props.toggleSize increases or decreases the overall size of toggle switch. Default is 16
 * @param {Boolean} props.changed if true, will display a small border to the right of the toggle switch to
 *                                indicate that this value has been changed.
 * @param {Function} props.onChange function to execute when the toggle switch is flipped
 *
 */
export const ToggleableListItem = React.forwardRef((props, ref) => {
    const { inputName, label, checked, toggleSize, changed, onChange, disabled } = props;
    const { darkMode } = useContext(ThemeContext);
    return (
        <ListItemElem darkMode={darkMode} changed={changed}>
            {label}
            <Toggle
                ref={ref}
                name={inputName}
                checked={checked}
                size={toggleSize}
                changed={changed}
                onChange={onChange}
                disabled={disabled}
            />
        </ListItemElem>
    );
});
