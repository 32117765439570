import React from "react";
import { ValidAccountNameRegex } from "../../../../enums/RegularExpressions";
import { getTMLAccounts } from "../../../../functions/LocalStorage";
import Widget from "../../../../widgets/Widget";
import { ErrorMessage } from "../../../reusable/ReactFormComponents";

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const AccountName = ({ register, errors }) => {
    return (
        <span>
            <Widget.Spacer height={10} />
            <Widget.Header info={getInfo()}>Account Name</Widget.Header>
            <Widget.Input
                type="text"
                name="name.accountName"
                label="Account name"
                placeholder="Ex: safeguard"
                required={true}
                inputAttributes={{ maxLength: 12 }}
                ref={register({
                    ...required,
                    pattern: {
                        value: ValidAccountNameRegex,
                        message:
                            "Must only contain alphanumeric characters and underscores and be between 4-12 characters",
                    },
                    validate: (value) =>
                        !getTMLAccounts()
                            .map((a) => a.name)
                            .includes(value) || "Name already in use",
                })}
                style={inputStyle}
            />
            {errors.name?.accountName && (
                <ErrorMessage>{errors.name.accountName.message}</ErrorMessage>
            )}
            <Widget.Input
                type="text"
                name="name.companyName"
                label="Company name"
                placeholder="Ex: Safeguard Background Screening LLC"
                required={true}
                ref={register({ ...required })}
                style={inputStyle}
            />
            {errors.name?.companyName && (
                <ErrorMessage>{errors.name.companyName.message}</ErrorMessage>
            )}
        </span>
    );
};

function getInfo() {
    return (
        <>
            The 'Account name' field is used inside the system to refer to this account. The
            'Company name' is to know (more accurately) who the account belongs to.
            <hr />
            <b>Important:</b>
            <br />
            If the account type is KYD, the value of the 'Account name' must match the name entered
            into the KYD platform for this account.
        </>
    );
}

export default AccountName;
