import { DropdownDefaults } from "../enums/DropdownValues";

export const parseQueryParams = (queryString) => {
    return queryString
        .replace("?", "")
        .split("&")
        .map((kv) => ({
            key: kv.split("=")[0],
            value: kv.split("=")[1],
        }))
        .reduce((prev, { key, value }) => ({ ...prev, [key]: value }), {});
};

export const buildQueryString = (params) => {
    let queryString = "?";
    const paramNames = Object.keys(params);
    paramNames.forEach((param, index) => {
        const paramValue = params[param];
        if (paramValue) {
            index > 0 && (queryString += "&");
            queryString += `${param}=${params[param]}`;
        }
    });
    return queryString;
};

export const getDataOptionIndex = (queryParams, queryParamName, dataOptions) => {
    const value = queryParams[queryParamName];
    const validNumber = /^[0-9]+$/.test(value);
    const parsedNumber = validNumber ? parseInt(value) : 0;
    return parsedNumber < dataOptions.length ? parsedNumber : 0;
};

export const getDataOption = (queryParams, queryParamName, dataOptions, settingName) => {
    const value = queryParams[queryParamName];
    if (value) {
        const validNumber = /^[0-9]+$/.test(value);
        const parsedNumber = validNumber ? parseInt(value) : 0;
        const optionsIndex = parsedNumber < dataOptions.length ? parsedNumber : 0;
        return dataOptions[optionsIndex];
    } else {
        const preference = localStorage.getItem(settingName) || null;
        const descending = [...dataOptions];
        const option = preference
            ? descending.find((option) => option.includes(preference))
            : dataOptions.find((option) => option.includes(DropdownDefaults.DATA));
        return option ? option : dataOptions[0];
    }
};
