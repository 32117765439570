import React from "react";
import { StyledComponentContext } from "../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

/*
 * ================
 *      Button
 * ================
 */
const ButtonElem = styled.button`
    padding: 3px 12px 4px !important;
    margin-top: 2px;
    font-size: 12px !important;
    width: max-content;
    max-height: 27px;
`;

/**
 * Accessed via <Widget.Button>, this component is to be passed through the "button"
 * parameter on a <Widget> component.
 *
 * @param {obj} obj.text the text displayed on the button
 * @param {obj} obj.onClick the function to call when the button is clicked
 */
export const Button = ({ text, icon, onClick, ...props }) => {
    const { variant } = props;
    return (
        <ButtonElem
            {...props}
            type="button"
            className={`btn btn-sm btn-${variant || "success"}`}
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}>
            {icon}
            {icon && <>&nbsp;</>}
            <span>{text}</span>
        </ButtonElem>
    );
};
