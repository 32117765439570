import React, { useContext } from "react";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { StyledComponentContext } from "../../../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

/*
 * ==============================================
 *      Size & Travel Distance  Calculations
 * ==============================================
 */
const vert_pad = 6;
const horiz_pad = 6;
const left = horiz_pad / 2 - 2;
const bottom = vert_pad / 2 - 2;
const width = (size) => size * 2 + 9;
const height = (size) => size + vert_pad;
const travel = (size) => size + horiz_pad / 2;

/**
 * Returns the correct color for the background of the slider.
 *
 * @param {Boolean} obj.checked whether or not the input is currently checked
 * @param {Boolean} obj.darkMode whether or not dark mode is enabled
 * @returns hex color code as string
 */
const sliderBackground = ({ checked, darkMode }) => {
    const lightThemeGray = "#ccc";
    const lightThemeBlue = "#7bc1f8";
    const darkThemeGray = "#888";
    const darkThemeBlue = "#2196f3";

    if (checked) return darkMode ? darkThemeBlue : lightThemeBlue;
    else return darkMode ? darkThemeGray : lightThemeGray;
};

/**
 * Returns the correct color for the thumb of the slider (the toggle switch).
 *
 * @param {Boolean} obj.checked whether or not the input is currently checked
 * @param {Boolean} obj.darkMode whether or not dark mode is enabled
 * @returns hex color code as string
 */
const sliderThumbBackground = ({ checked, darkMode }) => {
    const lightThemeGray = "#a5a5a5";
    const lightThemeBlue = "#2d96ea";
    const darkThemeGray = "#656565";
    const darkThemeBlue = "#186cae";

    if (checked) return darkMode ? darkThemeBlue : lightThemeBlue;
    else return darkMode ? darkThemeGray : lightThemeGray;
};

/*
 * ===========================
 *      Styled Components
 * ===========================
 */
const Checkbox = styled.input`
    display: none;
`;

const Label = styled.label`
    position: relative;
    display: inline-block;
    width: ${({ size, disabled }) => (disabled ? height(size) : width(size))}px;
    height: ${({ size }) => height(size)}px;
    float: right;
    margin-bottom: 0;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
`;

const Slider = styled.span`
    ${({ darkMode, checked, disabled }) => `
        position: absolute;
        box-sizing: border-box;
        cursor: ${disabled ? "default" : "pointer"};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 34px;
        background-color: ${sliderBackground({ checked, darkMode })};
        transition: 0.4s;
        border: 2px solid transparent;
    `}
`;

const SliderThumb = styled.span`
    ${({ darkMode, size, checked, disabled }) => `
        position: absolute;
        content: "";
        height: ${size}px;
        width: ${size}px;
        left: ${left}px;
        bottom: ${bottom}px;
        border-radius: 50%;
        background-color: ${
            disabled ? "transparent" : sliderThumbBackground({ checked, darkMode })
        };
        transition: 0.2s;

        // Slides the toggle switch over
        ${checked && `transform: translateX(${travel(size)}px);`}
    `}
`;

const Wrapper = styled.span`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: normal;
    justify-content: flex-end;
    border-right: ${({ borderRight }) => (borderRight ? "1px solid #c1c1c1" : "none")};
    padding-right: ${({ borderRight }) => (borderRight ? "20px" : "0")};

    .switch-label {
        margin: 0;
        align-self: center;
    }
`;

/**
 * Checkbox input styled to appear as a toggle switch.
 * Fully themed for both light and dark themes.
 *
 * @param {Function} props.onChange (required) function to call when toggle is changed
 * @param {String} props.label (optional) label for the toggle switch
 * @param {String} props.id (optional) id for the input
 * @param {String} props.name (optional) name for the input
 * @param {Number} props.size (optional) size adjustment for the input: default is 16
 * @param {Boolean} props.checked (optional) whether or not the input should be checked
 * @param {Boolean} props.borderRight (optional) adds a 1px border to the right of the switch to create
 * visual separation from elements to the right of the switch
 */
const Toggle = React.forwardRef((props, ref) => {
    if (!props.onChange) throw Error("<Toggle> must be provided an 'onChange' handler");
    const { id, name, size, checked, onChange, disabled } = props;
    const { darkMode } = useContext(ThemeContext);
    const defaultSize = size || 16;

    const checkboxProps = { size: defaultSize, checked, onChange, disabled };
    if (id) checkboxProps.id = id;
    if (name) checkboxProps.name = name;

    return (
        <Wrapper borderRight={props.borderRight}>
            {props.label && <span className="switch-label">{props.label}</span>}
            <Label size={defaultSize} disabled={disabled}>
                <Checkbox ref={ref} type="checkbox" darkMode={darkMode} {...checkboxProps} />
                <Slider
                    size={defaultSize}
                    checked={checked}
                    darkMode={darkMode}
                    disabled={disabled}
                    className="slider">
                    <SliderThumb
                        size={defaultSize}
                        checked={checked}
                        darkMode={darkMode}
                        disabled={disabled}
                        className="slider_thumb"
                    />
                </Slider>
            </Label>
        </Wrapper>
    );
});

export default Toggle;
