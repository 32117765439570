import { GetUserSearchPresets } from "../models/Settings";
import { v4 as uuid } from "uuid";
import { Settings } from "../models/Settings";
// import { HIVEMIND_BASE_URI } from "../enums/Endpoints";
import { GetPayload } from "./Http";

export const add = (name, search) => {
    const preset = {
        id: uuid(),
        name,
        search,
    };
    const presets = GetUserSearchPresets();
    const newPresets = [...presets, preset];
    localStorage.setItem(Settings.PRESETS, JSON.stringify(newPresets));
    return newPresets;
};

export const isValidName = (name, presets) => {
    if (name.length === 0) return false;
    const presetNames = presets.length > 0 ? presets.map((p) => p.name) : [];
    return !presetNames.includes(name);
};

export const isNewSearch = (search, presets) => {
    if (search.length === 0) return false;
    const presetSearches = presets.length > 0 ? presets.map((p) => p.search) : [];
    return !presetSearches.includes(search);
};

export const getTMLAccounts = (token) => {
    // return (async () => {
    //     try {
    //         const lastRefresh = DateTime.fromJSDate(localStorage.getItem("lastAccountRefresh"));
    //         if (!lastRefresh) return await refreshAccounts();

    //         const storedAccounts = localStorage.getItem("accounts");
    //         const { hours: difference } = DateTime.local().diff(lastRefresh, "hours").values;
    //         if (difference < 12 && storedAccounts) return storedAccounts;
    //     } catch (err) {
    //         return await refreshAccounts();
    //     }
    // })();

    // console.log("Accounts", response);
    // return response;
    const storedAccounts = localStorage.getItem("accounts");
    if (!storedAccounts?.length) refreshAccounts(token);
    try {
        return storedAccounts ? JSON.parse(storedAccounts) : [];
    } catch (err) {
        return [];
    }
};

export const refreshAccounts = async (token) => {
    localStorage.removeItem("accounts");
    const apiPath = JSON.parse(localStorage.getItem("env"))?.apiPath;
    const res = await fetch(`https://hivemind.tmlinfo.com/${apiPath}/accounts`, GetPayload(token));
    const { accounts } = await res.json();
    localStorage.setItem("accounts", JSON.stringify(accounts));
    // localStorage.setItem("lastAccountRefresh", +DateTime.local());
    return accounts;
};
