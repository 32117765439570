import React, { createContext } from "react";
import styled, { css } from "styled-components";

export const StyledComponentContext = createContext({
    styled,
    css,
});
export const StyledComponentProvider = (props) => {
    return (
        <StyledComponentContext.Provider value={{ styled, css }}>
            {props.children}
        </StyledComponentContext.Provider>
    );
};
