import React, { createContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useInterval } from "../hooks/useInterval";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    // const location = useLocation();
    const history = useHistory();
    const [token, setToken] = useState(getAccessTokenFromLocalStorage());
    const [decodedToken, setDecodedToken] = useState(decodeToken(token));
    const [authenticated, setAuthenticated] = useState(!!token);
    const [authenticating, setAuthenticating] = useState(true);
    const { user, isLoading, getAccessTokenSilently } = useAuth0();

    /**
     * Check to see if the token contains the specified permissions.
     *
     * @param {string[]} permissions an array of permissions to check against the token
     */
    const hasPermissions = (permissions) => {
        if (!decodedToken) return false;
        return permissions?.length
            ? permissions.every((p) => decodedToken?.permissions.includes(p))
            : true;
    };

    /**
     * Check to see if the token contains at least one of the specified permissions.
     *
     * @param {string[]} permissions an array of permissions to check against the token
     */
    const hasOneOrMorePermissions = (permissions) => {
        if (!decodedToken) return false;
        return permissions?.length
            ? permissions.some((p) => decodedToken?.permissions.includes(p))
            : true;
    };

    const checkSession = async (ignoreCache = false) => {
        if (isLoading) return;
        await getAccessTokenSilently({ ignoreCache })
            .then((token) => {
                const decodedPayload = decodeToken(token);
                if (decodedPayload.permissions.length && !authenticated) setAuthenticated(true);
                setToken(token);
                setDecodedToken(decodedPayload);
            })
            .catch(() => {
                setAuthenticated(false);
                setToken(null);
                setDecodedToken(null);
                Swal.close();
            });
        if (authenticating) setAuthenticating(false);
    };

    /**
     * This will fire the first time the context renders
     * and check the access token against Auth0's API, not
     * local storage. Then, it adds a listener to the
     * history object that will fire each time the user
     * navigates to a new route that checks the token.
     *
     * The `history.listen` function returns an `unlisten`
     * callback that will be called during cleanup for this
     * `useEffect` call.
     */
    useEffect(() => {
        if (isLoading) return;
        const token = getAccessTokenFromLocalStorage();
        if (token) {
            setToken(token);
            setDecodedToken(decodeToken(token));
            setAuthenticated(true);
        } else {
            setToken(null);
            setDecodedToken(null);
            setAuthenticated(false);
        }
        setAuthenticating(false);
        (async () => await checkSession(true))();
        return history.listen(async () => await checkSession());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const oneMinute = 1000 * 60;

    /**
     * Check the access token on a regular interval.
     *
     * If the session is already invalid, `null` prevents
     * `useInterval` from calling the provided function so
     * that we're not checking the session when we already
     * know it's invalid.
     */
    useInterval(checkSession, authenticated ? 5 * oneMinute : null); // Null means no function calls

    return (
        <UserContext.Provider
            value={{
                user,
                hasPermissions,
                hasOneOrMorePermissions,
                authenticated,
                authenticating,
                token,
                decodedToken,
            }}>
            {children}
        </UserContext.Provider>
    );
};

function getAccessTokenFromLocalStorage() {
    const id = "@@auth0spajs@@";
    for (var i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).indexOf(id) !== -1) {
            const value = localStorage.getItem(localStorage.key(i));
            const { access_token } = JSON.parse(value).body;
            return access_token;
        }
    }
    return null;
}

function decodeToken(token) {
    if (!token) return null;
    const rawTokenPayload = token.split(".")[1];
    const decodedPayload = JSON.parse(atob(rawTokenPayload));
    return decodedPayload;
}
