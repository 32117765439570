import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { PostPayload } from "../../../../../functions/Http";
import Widget from "../../../../../widgets/Widget";
import SaveCancelButtons from "../../../../reusable/SaveCancelButtons";
import { AreYouSureAlert, ErrorAlert, SuccessAlert } from "../../../../reusable/Alerts";
import { useAccounts } from "src/hooks/react-query/account-list/useAccounts";
import { useForm, useWatch } from "react-hook-form";
import { useMutateAccount } from "src/hooks/react-query/accounts/useAccount";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
import { ErrorMessage } from "src/components/reusable/ReactFormComponents";
import { useAccount } from "src/hooks/react-query/accounts/useAccount";

const getDefaultValues = (account) => ({
    active: /^active$/i.test(account.status),
    billingId: String(account.billingId),
    companyName: account.companyName || "",
});

const DetailsModal = (props) => {
    const { account, showModal, setShowModal } = props;
    const { refetch } = useAccount(account.name);
    const defaultValues = getDefaultValues(account);
    const accountList = useAccounts().data?.accounts;
    const [active, setActive] = useState(defaultValues.active);

    const { register, errors, handleSubmit, control, reset } = useForm({
        mode: "onChange",
        defaultValues,
    });
    const companyName = useWatch({ control, name: "companyName" });
    const billingId = useWatch({ control, name: "billingId" });
    const changesMade =
        active !== defaultValues.active ||
        companyName !== defaultValues.companyName ||
        billingId !== defaultValues.billingId;

    const { isLoading: isSaving, mutate } = useMutateAccount({
        accountName: account.name,
        onSuccess: () => {
            setShowModal(false);
            SuccessAlert().then(() => {
                reset({ active, companyName, billingId });
                refetch();
            });
        },
        onError: () => ErrorAlert(),
    });

    const onCancel = () => {
        const cancel = () => {
            reset(defaultValues);
            setShowModal(false);
        };
        if (!changesMade) return cancel();
        AreYouSureAlert().then(({ isConfirmed }) => isConfirmed && cancel());
    };

    const sorted = accountList && [...accountList?.sort((a, b) => a.name.localeCompare(b.name))];
    const billingAccount = sorted?.find((a) => String(a.id) === billingId);

    return (
        <Modal
            show={showModal}
            backdrop={changesMade ? "static" : true}
            onHide={() => setShowModal(false)}
            id="details-modal">
            <Modal.Header closeButton>
                <Modal.Title>Edit Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StatusToggle
                    defaultValues={defaultValues}
                    register={register}
                    active={active}
                    setActive={setActive}
                />
                <ErrorMessage message={errors.active} />
                <Widget.Spacer />
                <Widget.Input
                    ref={register()}
                    name="companyName"
                    type="text"
                    label="Company Name"
                    placeholder={companyNameExample(account.name)}
                    value={companyName}
                    permissions={["account:company-name"]}
                />
                <ErrorMessage message={errors.companyName} />
                <Widget.Spacer />
                {accountList ? (
                    <Widget.Select
                        ref={register()}
                        name="billingId"
                        label="Bill to"
                        options={sorted}
                        optionValueProp={"id"}
                        optionLabelProp={"name"}
                        noValueOption={!account.billingId}
                        defaultValue={billingAccount}
                        permissions={["account:billing-account"]}
                    />
                ) : (
                    <Widget.Input type="text" label="Bill to" value={"Loading..."} readOnly />
                )}
                <Widget.Spacer />
                <SaveCancelButtons
                    visible={true}
                    saveDisabled={Object.keys(errors).length || !changesMade}
                    onSave={handleSubmit(onSave)}
                    onCancel={onCancel}
                    style={{ marginTop: "5px" }}
                    showSpinner={isSaving}
                    permissions={["account:update"]}
                />
                <ErrorMessage message={errors.billingId} />
            </Modal.Body>
        </Modal>
    );

    function onSave(values) {
        AreYouSureAlert().then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            mutate((token) =>
                PostPayload(token, {
                    accountId: account.id,
                    active: values.active,
                    companyName: values.companyName,
                    billingId: parseInt(values.billingId),
                })
            );
        });
    }
};

export default DetailsModal;

const companyNameExample = (name) => {
    const capitalized = name.substr(0, 1).toUpperCase() + name.substr(1);
    return `Ex: ${capitalized} LLC`;
};

function StatusToggle(props) {
    const { defaultValues, register, active, setActive } = props;
    const permissions = defaultValues.active ? ["account:disable"] : ["account:enable"];
    const toggle = (disabled) => (
        <Widget.ToggleableListItem
            ref={register()}
            inputName="active"
            label="Active status"
            checked={active}
            changed={active !== defaultValues.active}
            disabled={disabled}
            {...(disabled ? { onChange: () => {} } : { onChange: () => setActive(!active) })}
        />
    );

    return withAuthorizationCheck(permissions, toggle(false), toggle(true))();
}
