import React, { useState } from "react";
import Widget from "src/widgets/Widget";
import withAuthorizationCheck from "src/components/HOC/withAuthorizationCheck";
import { FormatDate } from "src/functions/FormatDate";
import { Badge } from "react-bootstrap";
import { DateTime } from "luxon";
import NewCredentialModal from "./NewCredentialModal";
import CredentialListItemContent from "./CredentialListItemContent";
import { useCredentials } from "src/hooks/react-query/accounts/useCredentials";

const CredentialList = ({ account, permissions }) => {
    const { data, error, isLoading, refetch } = useCredentials(account.name);
    const credentials = data?.credentials;
    const [showModal, setShowModal] = useState(false);

    const credentialList = credentials?.map((cred) => {
        const daysRemaining = DateTime.fromISO(cred.expiresOn).diffNow("days").days;
        const expiringSoon = daysRemaining <= 30;
        const expiringVerySoon = daysRemaining <= 14;
        const isExpired = daysRemaining < 0;

        let variant = "success";
        if (expiringSoon) variant = "warning";
        if (expiringVerySoon || isExpired) variant = "danger";
        if (!cred.active) variant = "secondary";

        let text = "ACTIVE";
        if (expiringSoon) text = "EXPIRING SOON";
        if (isExpired) text = "EXPIRED";
        if (!cred.active) text = "INACTIVE";

        const badge = (
            <Badge pill variant={variant}>
                {text}
            </Badge>
        );

        return (
            <Widget.ListItem
                key={cred.id}
                label={cred.nickname}
                value={badge}
                subtext={startAndEndDates(cred)}>
                <CredentialListItemContent
                    account={account}
                    credential={cred}
                    refresh={refetch}
                    permissions={permissions}
                />
            </Widget.ListItem>
        );
    });

    const AddCredentialButton = withAuthorizationCheck(
        permissions,
        <Widget.Button text="Add" onClick={() => setShowModal(true)} />
    );

    return (
        <div>
            <Widget
                loading={isLoading}
                header={
                    <Widget.Header info={headerInfo()} button={<AddCredentialButton />}>
                        Credentials
                    </Widget.Header>
                }>
                {error && <span>ERROR: Could not fetch credentials</span>}
                {!error && credentialList?.length && credentialList}
            </Widget>
            <NewCredentialModal
                account={account}
                showModal={showModal}
                setShowModal={setShowModal}
                onSave={refetch}
                permissions={permissions}
            />
        </div>
    );

    /**
     * Shows the most recent date between the insert date and updated date
     * and formats the date to be readable.
     *
     * @param {Object} cred object containing all note fields
     */
    function startAndEndDates(cred) {
        return (
            <span
                style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    marginTop: 5,
                }}>
                <span style={{ justifySelf: "start" }}>
                    <b>Created:</b> {FormatDate(cred.createdOn)}
                </span>
                <span style={{ justifySelf: "end" }}>
                    <b>Expires:</b> {FormatDate(cred.expiresOn)}
                </span>
            </span>
        );
    }

    /**
     * Information to display beside the header for this widget when
     * hovering over the information icon.
     */
    function headerInfo() {
        return (
            <span>
                These credentials contain generated <code>username</code> and <code>password</code>{" "}
                pairs that customers will use to authenticate to the <code>/authenticate</code> URL
                in the REST API.
                <hr />
                <b>Note:</b>&nbsp;these credentials cannot be changed once created. If changes are
                needed (other than the description), a new credential will need to be created and
                issued.
            </span>
        );
    }
};

export default CredentialList;
