const urlSafeCharacters = [..."abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~"];
const numericCharacters = [..."0123456789"];
const distinguishableCharacters = [..."CDEHKMPRTUWXY012458"];
const asciiPrintableCharacters = [
    ..."!\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~",
];
const alphanumericCharacters = [
    ..."ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
];

export function generateRandomString(charset, length) {
    let characters;
    switch (charset) {
        case "urlSafe":
            characters = urlSafeCharacters;
            break;
        case "numeric":
            characters = numericCharacters;
            break;
        case "distinguishable":
            characters = distinguishableCharacters;
            break;
        case "ascii":
            characters = asciiPrintableCharacters;
            break;
        case "alphanumeric":
        default:
            characters = alphanumericCharacters;
    }
    let result = "";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters[Math.floor(Math.random() * charactersLength)];
        counter++;
    }
    return result;
}
