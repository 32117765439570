import { useQuery } from "@tanstack/react-query";
import { GetPayload } from "src/functions/Http";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useSecureMutate } from "src/hooks/useSecureMutate";
import { PostbackType } from "src/components/accounts/account/account_widgets/credentials/webhook/auth-schema-list/AuthSchemaListItemContent";
import { SuccessAlert } from "src/components/reusable/Alerts";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const url = (baseUrl, accountName) => `${baseUrl}/accounts/${accountName}/webhook-auth`;

export const useAuthSchemas = (accountName) => {
    const { baseUrl } = useCurrentEnvironment();
    const { secureFetch } = useSecureFetch(url(baseUrl, accountName));

    const query = useQuery({
        queryKey: ["accounts", accountName, "auth-schemas"],
        queryFn: async () => await secureFetch((token) => GetPayload(token)),
    });

    return {
        ...query,
        detectDuplicate: (schema) => {
            const configKey = {
                [PostbackType.KEY]: "key",
                [PostbackType.BASIC]: "basic",
                [PostbackType.BEARER]: "bearer",
            }[schema.type];
            const otherKeys = query?.data?.schemas
                ?.filter((s) => s.id !== schema?.id && s.type === schema.type)
                .map((s) => s[configKey])
                .map(JSON.stringify);
            const currentKey = JSON.stringify(schema[configKey]);
            return otherKeys?.includes(currentKey);
        },
    };
};

export const useMutateAuthSchemas = ({ accountName, onSuccess, onError }) => {
    const { refetch } = useAuthSchemas(accountName);
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({
        url: url(baseUrl, accountName),
        onSuccess: onSuccess || (() => SuccessAlert().then(refetch)),
        onError,
    });
};
