import { useSecureMutate } from "src/hooks/useSecureMutate";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";
import { ErrorAlert } from "src/components/reusable/Alerts";
import Swal from "sweetalert2";

const url = (baseUrl, uuid, command) => `${baseUrl}/transactions/${uuid}/${command}`;

export const useCommand = ({ uuid, command, onSuccess, onError }) => {
    const { baseUrl } = useCurrentEnvironment();
    return useSecureMutate({
        url: url(baseUrl, uuid, command),
        onSuccess: onSuccess ?? (() => Swal.close()),
        onError: onError ?? (() => ErrorAlert()),
    });
};
