import { Data, DropdownDefaults, Language } from "../enums/DropdownValues";

export const Themes = {
    LIGHT: "light",
    DARK: "dark",
};

export const InfoPanelLayout = {
    COLLAPSED: "collapsed",
    EXPANDED: "expanded",
};

export const EditorLayout = {
    VERTICAL: "vertical",
    HORIZONTAL: "horizontal",
};

export const CaseSensitivity = {
    SENSITIVE: "sensitive",
    INSENSITIVE: "insensitive",
};

export const State = {
    ENABLED: "enabled",
    DISABLED: "disabled",
};

export const LinkStyle = {
    CURRENT_TAB: "current_tab",
    NEW_TAB: "new_tab",
};

export const Settings = {
    THEME: "theme",
    INFO_PANEL_LAYOUT: "infoPanelLayout",
    SEARCH_MIRROR: "searchMirror",
    EDITOR_LAYOUT: "editorLayout",
    LEFT_CASE: "leftEditorCase",
    LEFT_DATA: "leftEditorData",
    LEFT_LANG: "leftEditorLang",
    LEFT_FONT: "leftEditorFont",
    LEFT_SEARCH: "leftEditorSearch",
    RIGHT_CASE: "rightEditorCase",
    RIGHT_DATA: "rightEditorData",
    RIGHT_LANG: "rightEditorLang",
    RIGHT_FONT: "rightEditorFont",
    RIGHT_SEARCH: "rightEditorSearch",
    PRESETS: "presets",
    LINK_STYLE: "linkStyle",
};

export const DefaultSettings = {
    theme: Themes.LIGHT,
    infoPanelLayout: InfoPanelLayout.EXPANDED,
    searchMirror: State.DISABLED,
    editorLayout: EditorLayout.VERTICAL,
    leftEditor: {
        caseSensitive: DropdownDefaults.CASE_SENSITIVE,
        data: Data.RAW_DATA,
        language: Language.types.XML,
        fontSize: DropdownDefaults.FONT_SIZE,
        search: "",
    },
    rightEditor: {
        caseSensitive: DropdownDefaults.CASE_SENSITIVE,
        data: Data.RAW_DATA,
        language: Language.types.XML,
        fontSize: DropdownDefaults.FONT_SIZE,
        search: "",
    },
    presets: [],
    linkStyle: LinkStyle.CURRENT_TAB,
};

export const GetUserSearchPresets = () => {
    const storedPresets = localStorage.getItem(Settings.PRESETS);
    const parsedPresets = storedPresets ? JSON.parse(storedPresets) : [];
    parsedPresets.sort((s1, s2) => {
        return s1.name.localeCompare(s2.name);
    });
    return parsedPresets;
};

const getSearchFromPreset = (searchSetting) => {
    const presets = localStorage.getItem(Settings.PRESETS);
    if (!presets) return "";
    const presetId = localStorage.getItem(searchSetting);
    const preset = GetUserSearchPresets().find((p) => p.id === presetId);
    return preset ? preset.search : "";
};

export const GetUserSettings = () => {
    return {
        theme: localStorage.getItem(Settings.THEME),
        infoPanelLayout: localStorage.getItem(Settings.INFO_PANEL_LAYOUT),
        searchMirror: localStorage.getItem(Settings.SEARCH_MIRROR),
        editorLayout: localStorage.getItem(Settings.EDITOR_LAYOUT),
        leftEditor: {
            case: localStorage.getItem(Settings.LEFT_CASE),
            data: localStorage.getItem(Settings.LEFT_DATA),
            language: localStorage.getItem(Settings.LEFT_LANG),
            fontSize: localStorage.getItem(Settings.LEFT_FONT),
            search: getSearchFromPreset(Settings.LEFT_SEARCH),
        },
        rightEditor: {
            case: localStorage.getItem(Settings.RIGHT_CASE),
            data: localStorage.getItem(Settings.RIGHT_DATA),
            language: localStorage.getItem(Settings.RIGHT_LANG),
            fontSize: localStorage.getItem(Settings.RIGHT_FONT),
            search: getSearchFromPreset(Settings.RIGHT_SEARCH),
        },
        linkStyle: localStorage.getItem(Settings.LINK_STYLE),
    };
};

export const InitSettings = () => {
    localStorage.setItem(Settings.THEME, DefaultSettings.theme);
    localStorage.setItem(Settings.INFO_PANEL_LAYOUT, DefaultSettings.infoPanelLayout);
    localStorage.setItem(Settings.SEARCH_MIRROR, DefaultSettings.searchMirror);
    localStorage.setItem(Settings.EDITOR_LAYOUT, DefaultSettings.editorLayout);
    localStorage.setItem(Settings.LEFT_CASE, DefaultSettings.leftEditor.caseSensitive);
    localStorage.setItem(Settings.LEFT_DATA, DefaultSettings.leftEditor.data);
    localStorage.setItem(Settings.LEFT_LANG, DefaultSettings.leftEditor.language);
    localStorage.setItem(Settings.LEFT_FONT, DefaultSettings.leftEditor.fontSize);
    localStorage.setItem(Settings.LEFT_SEARCH, DefaultSettings.leftEditor.search);
    localStorage.setItem(Settings.RIGHT_CASE, DefaultSettings.rightEditor.caseSensitive);
    localStorage.setItem(Settings.RIGHT_DATA, DefaultSettings.rightEditor.data);
    localStorage.setItem(Settings.RIGHT_LANG, DefaultSettings.rightEditor.language);
    localStorage.setItem(Settings.RIGHT_FONT, DefaultSettings.rightEditor.fontSize);
    localStorage.setItem(Settings.RIGHT_SEARCH, DefaultSettings.rightEditor.search);
    localStorage.setItem(Settings.LINK_STYLE, DefaultSettings.linkStyle);
};

export const InitPresets = () => {
    localStorage.setItem(Settings.PRESETS, []);
};
