/**
 * Email can include alphanumeric characters as well as:
 *    * periods
 *    * underscores
 *    * dashes
 *
 * A demo of the regex can be found here:
 *    * https://regex101.com/r/W5shvI/1
 */
export const ValidEmailRegex = /^[\w._-]+@[\w_-]+\.[A-Za-z]{2,4}$/;

/**
 * The requirements for an account name are that it
 * must be alphanumeric characters and it cannot contain
 * spaces.
 */
export const ValidAccountNameRegex = /^(\w+){4,12}$/;

/**
 * Accepts 123-456-7890 format as well as empty string.
 */
export const ValidPhoneRegex = /(^\d{3}-\d{3}-\d{4}$)|(^.{0}$)/;

/**
 * Regular expression taken from StackOverflow
 * separated into segments for readability.
 *
 * See https://stackoverflow.com/a/3809435
 *
 * Original RegExp:
 * /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)$/
 */
//prettier-ignore
const urlSegments = [
    "(https?://)?",                     // Protocol (optional)
    "(www\\.)?",                        // World Wide Web (optional)
    "[-a-zA-Z0-9@:%._\\+~#=]{1,256}",   // Domain
    "\\.[a-zA-Z0-9()]{1,6}\\b",         // Top-level Domain
    "([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)", // Path/Query (?)
];
export const ValidURLRegex = new RegExp(`^${urlSegments.join("")}$`);

/**
 * This regex ensures that the URL being validated
 * is a valid URL format. The original regex taken
 * from Stack Overflow allowed for `@` symbols in
 * some places and that has been removed. In addition,
 * this requires the URL to be `https`.
 *
 * Modified from https://stackoverflow.com/a/3809435/11397483.
 */
export const SecureURLRegex =
    /^https:\/\/(www\.)?[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9():%_+.~#?&//=]*)$/i;

/**
 * This regex is for defining a webhook URL pattern
 * which can contain wildcards after the domain. This
 * was modified from the {@link SecureURLRegex} expression
 * and, like that expression, requires the URL to be HTTPS.
 */
// export const WebhookPatternRegex =
//     /^https:\/\/(www\.)?[-a-zA-Z0-9._]{1,256}\.[a-zA-Z0-9]{1,6}\b([a-zA-Z0-9_#&=/*-]*)$/i;
export const WebhookPatternRegex =
    /^https:\/\/(?!.*\/\/)(www\.)?[-a-zA-Z0-9._]{1,256}\.[a-zA-Z0-9]{1,6}\b([a-zA-Z0-9_#&=/*-]*)$/i;
