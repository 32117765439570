import { useQuery } from "@tanstack/react-query";
import { GetPayload } from "src/functions/Http";
import { useSecureFetch } from "src/hooks/useSecureFetch";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

export const useAccounts = () => {
    const { baseUrl } = useCurrentEnvironment();
    const { secureFetch } = useSecureFetch(`${baseUrl}/accounts`);
    return useQuery({
        queryKey: ["account-list"],
        queryFn: async () => await secureFetch((token) => GetPayload(token)),
        cacheTime: Infinity,
    });
};
