import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { setHtmlDarkTheme } from "../../../functions/ColorTheme";
import { ThemeContext } from "../../../contexts/ThemeContext";

export const FullScreenTip = () => {
    return (
        <span id="full-screen">
            Press <kbd>F11</kbd> to toggle full screen.
        </span>
    );
};

export const ColorThemeTip = () => {
    const { darkMode, setDarkMode } = useContext(ThemeContext);
    return darkMode ? (
        <Button
            id="color-theme"
            variant="outline-light"
            onClick={() => {
                setHtmlDarkTheme(false);
                setDarkMode(false);
            }}>
            Light Mode
        </Button>
    ) : (
        <Button
            id="color-theme"
            variant="outline-dark"
            onClick={() => {
                setHtmlDarkTheme(true);
                setDarkMode(true);
            }}>
            Dark Mode
        </Button>
    );
};

export const LearnRegexTip = () => {
    return (
        <span id="learn-regex">
            <a href="https://regexone.com/" target="_blank" rel="noopener noreferrer">
                <span>Learn Regex</span>
            </a>
        </span>
    );
};
