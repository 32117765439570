// export const LightTheme_WidgetBG = "#f6f6f6";
const LightTheme_WidgetBG = "#f8f8f8";
const DarkTheme_WidgetBG = "#444";

const LightTheme_WidgetBorder = "#f2f2f2";
const DarkTheme_WidgetBorder = "#505050";

const LightTheme_ListItemBG = "#e8e8e896";
const DarkTheme_ListItemBG = "#58585899";

const LightTheme_ListItemHoverBG = "#d0d0d0";
const DarkTheme_ListItemHoverBG = "#686868";

const LightTheme_ListItemBorder = "#e6e6e6";
const DarkTheme_ListItemBorder = "#5e5e5e";

const LightTheme_AltText = "#6b6b6b";
const DarkTheme_AltText = "#d0d0d0";

const LightTheme_Subtext = "#8f8f8f";
const DarkTheme_Subtext = "#aaa";

const LightTheme_InputBG = "#fff";
const DarkTheme_InputBG = "#323232";

const LightTheme_Placeholder = "#afafaf";
const DarkTheme_Placeholder = "#a0a0a0";

export const WidgetBackground = (darkMode) => (darkMode ? DarkTheme_WidgetBG : LightTheme_WidgetBG);
export const WidgetBorder = (darkMode) =>
    darkMode ? DarkTheme_WidgetBorder : LightTheme_WidgetBorder;
export const ListItemBackground = (darkMode) =>
    darkMode ? DarkTheme_ListItemBG : LightTheme_ListItemBG;
export const ListItemHoverBackground = (darkMode) =>
    darkMode ? DarkTheme_ListItemHoverBG : LightTheme_ListItemHoverBG;
export const ListItemBorder = (darkMode) =>
    darkMode ? DarkTheme_ListItemBorder : LightTheme_ListItemBorder;
export const Subtext = (darkMode) => (darkMode ? DarkTheme_Subtext : LightTheme_Subtext);
export const AltText = (darkMode) => (darkMode ? DarkTheme_AltText : LightTheme_AltText);
export const InputBackground = (darkMode) => (darkMode ? DarkTheme_InputBG : LightTheme_InputBG);
export const Placeholder = (darkMode) =>
    darkMode ? DarkTheme_Placeholder : LightTheme_Placeholder;
