import React from "react";
import { States } from "../../../../enums/States";
import Widget from "../../../../widgets/Widget";
import { ErrorMessage } from "../../../reusable/ReactFormComponents";
import { TwoColumnLayout } from "../../../styled_components/Layouts";

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const AccountAddress = ({ register, errors }) => {
    const errorMsg = (fieldName) => {
        if (!errors.address || !errors.address[fieldName]) return;
        return <ErrorMessage>{errors.address[fieldName].message}</ErrorMessage>;
    };

    return (
        <span>
            <Widget.Spacer height={10} />
            <Widget.Header>Business Address</Widget.Header>
            <TwoColumnLayout>
                <Widget.Input
                    type="text"
                    name="address.streetOne"
                    label="Street one"
                    placeholder="100 Company Ln"
                    required={true}
                    ref={register({ ...required })}
                    style={inputStyle}
                />
                <Widget.Input
                    type="text"
                    name="address.streetTwo"
                    label="Street two"
                    placeholder="Apt. 6"
                    ref={register()}
                    style={inputStyle}
                />
            </TwoColumnLayout>
            <TwoColumnLayout>
                <Widget.Input
                    type="text"
                    name="address.city"
                    label="City"
                    placeholder="Chicago"
                    required={true}
                    ref={register({ ...required })}
                    style={inputStyle}
                />
                <TwoColumnLayout>
                    <span>
                        <Widget.Select
                            name="address.state"
                            label="State"
                            options={[...States]}
                            optionValueProp={"abbreviation"}
                            optionLabelProp={"name"}
                            noValueOption={true}
                            required={true}
                            ref={register({ ...required })}
                            style={inputStyle}
                        />
                    </span>
                    <span>
                        <Widget.Input
                            type="text"
                            name="address.zip"
                            label="Zip code"
                            placeholder="60018"
                            inputAttributes={{ maxLength: 5 }}
                            required={true}
                            ref={register({
                                ...required,
                                pattern: {
                                    value: /^\d{5}$/,
                                    message: "Must be exactly 5 numbers",
                                },
                            })}
                            style={inputStyle}
                        />
                        {errorMsg("zip")}
                    </span>
                </TwoColumnLayout>
            </TwoColumnLayout>
        </span>
    );
};

export default AccountAddress;
