import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Label,
    LabelGrid,
    ContentWrapper,
    OverflowHeight,
    HeaderWrapper,
} from "./_WidgetStyles";
import { ThemeContext } from "../contexts/ThemeContext";
import { Button } from "./WidgetCompounds/WidgetButton";
import { ListItem, ListItemContent, ToggleableListItem } from "./WidgetCompounds/WidgetListItem";
import { TextArea } from "./WidgetCompounds/WidgetTextArea";
import { Input, PasswordInput } from "./WidgetCompounds/WidgetInput";
import { Select } from "./WidgetCompounds/WidgetSelect";
import { SearchBar } from "./WidgetCompounds/WidgetSearchBar";
import WidgetHeader from "./WidgetCompounds/WidgetHeader";
import { Spinner } from "react-bootstrap";
import { StyledComponentContext } from "../contexts/StyledComponentsContext";
const { styled } = StyledComponentContext._currentValue;

/*
 * =======================
 *      Simple Styles
 * =======================
 */
export const Header = styled.h5`
    margin: 0 0 10px;
`;

export const Spacer = styled.div`
    height: ${({ height }) => `${height || 10}px`};
`;

/*
 * ======================
 *      Value Checks
 * ======================
 */
const isOverflowing = (element, defaultValue) =>
    !element ? false : element.scrollHeight >= OverflowHeight;

const childrenPresent = (children) =>
    Array.isArray(children) ? children.filter((c) => !!c).length > 0 : !!children;

/*
 * =======================
 *      Widget Class
 * =======================
 */
export default class Widget extends React.Component {
    _isMounted = false;
    static ListLayout = "ListLayout";
    static GridLayout = "GridLayout";
    static Header = WidgetHeader;
    static Spacer = Spacer;
    static ListItem = ListItem;
    static ToggleableListItem = ToggleableListItem;
    static ListItemContent = ListItemContent;
    static Button = Button;
    static Input = Input;
    static PasswordInput = PasswordInput;
    static TextArea = TextArea;
    static Select = Select;
    static SearchBar = SearchBar;

    static contextType = ThemeContext;
    state = {
        expanded: this.props.collapsible ? false : true,
        collapsible: this.props.collapsible,
        overflowRef: React.createRef(),
        resizeObserver: null,
    };

    /**
     * Adjusts the widget's collapsible status based on the height
     * of the content wrapper.
     */
    checkOverflow() {
        const element = this.state.overflowRef.current;
        const collapsible = isOverflowing(element, this.props.collapsible);
        return collapsible !== this.state.collapsible && this.setState({ collapsible });
    }

    /**
     * If a widget changes size without a new render, this ResizeObserver
     * ensures that the collapsible feature will act as expected.
     */
    attachResizeObserver() {
        if (this.state.collapsible) {
            const element = document.getElementById(this.getWidgetId());
            const observer = new ResizeObserver(() => this.checkOverflow());
            this.setState({ resizeObserver: observer }, () => {
                this.state.resizeObserver.observe(element);
            });
        }
    }

    /**
     * If an ID was passed as a prop, the ID is returned, else one is
     * generated using the Label.
     */
    getWidgetId = () => {
        if (this.props.collapsible && !this.props.id && !this.props.label)
            throw new Error("Collapsible widget must contain an ID or a label prop");
        return this.props.id || `widget-${this.props.label}`;
    };

    componentDidUpdate() {
        if (!this._isMounted && this.props.collapsible) this.checkOverflow();
    }

    componentDidMount() {
        this._isMounted = true;
        // this.checkOverflow();
        this.attachResizeObserver();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
        const { resizeObserver } = this.state;
        if (resizeObserver) {
            const element = document.getElementById(this.getWidgetId());
            resizeObserver.unobserve(element);
        }
    };

    render() {
        const { darkMode } = this.context;
        const { label, header, underLabel, button, searchBar, layout, loading, children, onEdit } =
            this.props;
        const { expanded, collapsible, overflowRef } = this.state;
        const addedComponents = button || searchBar;

        const content = loading ? (
            <div className="txt-ctr">
                <Spinner animation="border" size="sm" />
            </div>
        ) : childrenPresent(children) ? (
            <ContentWrapper collapsible={collapsible} expanded={expanded} ref={overflowRef}>
                <Layout layout={layout}>{children}</Layout>
            </ContentWrapper>
        ) : (
            <span>Nothing to display</span>
        );

        return (
            <Container darkMode={darkMode} className="widget" id={this.getWidgetId()}>
                {header ? (
                    <HeaderWrapper>{header}</HeaderWrapper>
                ) : (
                    label && (
                        <Label
                            darkMode={darkMode}
                            collapsible={childrenPresent(children) && collapsible}
                            expanded={expanded}
                            addedComponents={addedComponents}
                            onClick={() => collapsible && this.setState({ expanded: !expanded })}>
                            {label}
                            {addedComponents && (
                                <LabelGrid>
                                    {searchBar || <div />}
                                    {button}
                                </LabelGrid>
                            )}
                            {onEdit && <i className="fas fa-pencil-alt ptr" onClick={onEdit} />}
                        </Label>
                    )
                )}
                {underLabel && <div className="widget-underLabel">{underLabel}</div>}
                {content}
            </Container>
        );
    }
}

const ListLayout = styled.div`
    display: grid;
    grid-auto-flow: row;
    row-gap: 10px;
`;

const GridLayout = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(2, 1fr);
`;

function Layout({ layout, children }) {
    switch (layout) {
        case Widget.GridLayout:
            return <GridLayout>{children}</GridLayout>;
        default:
            return <ListLayout>{children}</ListLayout>;
    }
}

Widget.propTypes = {
    /**
     * Simple heading for widget.
     */
    label: PropTypes.string,
    /**
     * Used instead of `label`, a more customizable heading for
     * the widget. This is intended to be used with <Widget.Header>.
     */
    header: PropTypes.element,
    /**
     * Text or other elements appearing underneath the heading.
     */
    underLabel: PropTypes.any,
    /**
     * If specifying a simple label, this button will be displayed
     * at the top right of the widget.
     */
    button: PropTypes.element,
    /**
     * A search bar to include at the top of the widget. This is
     * intended to be used with <Widget.SearchBar>.
     */
    searchBar: PropTypes.element,
    /**
     * The layout of the widget. This can be either "ListLayout" or
     * "GridLayout". This will affect how the children are rendered.
     */
    layout: PropTypes.oneOf([Widget.ListLayout, Widget.GridLayout]),
    /**
     * Display a loading icon in place of children.
     */
    loading: PropTypes.bool,
    /**
     * If specifying a simple label, this will appear on the far
     * right of the widget as a pencil icon that signals to the
     * user the items in this widget can be edited.
     */
    onEdit: PropTypes.func,
};
