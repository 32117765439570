import * as Colors from "./_Colors";
import { StyledComponentContext } from "../contexts/StyledComponentsContext";
const { styled, css } = StyledComponentContext._currentValue;

const OverflowHeight = 250;

/*
 * ===============
 *      Local
 * ===============
 */
const collapseExpandButton = css`
    &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        float: right;
        content: "${({ expanded }) => (expanded ? "\\f077" : "\\f078")}";
        // content: "${({ expanded }) => (expanded ? "\\f151" : "\\f150")}";
    }
`;

const scroll = css`
    max-height: ${({ expanded }) => (expanded ? "unset" : `${OverflowHeight}px`)};
    overflow-y: ${({ expanded }) => (expanded ? "" : "scroll")};}
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #c2c9d2;
    }
    & > * {
        margin-right: ${({ expanded }) => (expanded ? "0" : "8px")};
    }
`;

/*
 * ==================
 *      Exported
 * ==================
 */
export { OverflowHeight };

export const Container = styled.div`
    border-radius: 2px;
    // padding: 8px;
    padding: 12px;
    margin-bottom: 20px;
    background: ${({ darkMode }) => Colors.WidgetBackground(darkMode)};
    border: 1px solid ${({ darkMode }) => Colors.WidgetBorder(darkMode)};
`;

export const Label = styled.h5`
    cursor: ${({ collapsible }) => collapsible && "pointer"};
    ${({ collapsible }) => collapsible && collapseExpandButton}
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: ${({ addedComponents }) =>
        addedComponents ? "auto 1fr auto" : "1fr auto"};
    align-items: center;
`;

export const LabelGrid = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    column-gap: 10px;
    align-items: center;
    margin-left: 10px;
    position: relative;
`;

export const HeaderWrapper = styled.div`
    margin-bottom: 10px;
`;

export const ContentWrapper = styled.div`
    ${({ collapsible }) => collapsible && scroll}
`;
