import React, { useRef, useState } from "react";
import { Navbar as NavBar, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Widget from "../../widgets/Widget";
import { Routes } from "./Routes";
import { ErrorAlert } from "../reusable/Alerts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MenuDropdown from "./MenuDropdown";
import { GetPayload } from "../../functions/Http";
import { useAuth0 } from "@auth0/auth0-react";
import EnvironmentDropdown from "./EnvironmentDropdown";
import { useCurrentEnvironment } from "src/hooks/useEnvironment";

const ReactSwal = withReactContent(Swal);

export default function Navbar({ location }) {
    const history = useHistory();
    const searchRef = useRef(null);
    const [search, setSearch] = useState("");
    const { getAccessTokenSilently } = useAuth0();
    const { baseUrl } = useCurrentEnvironment();
    // const [showACDModal, setShowACDModal] = useState(false);

    /**
     * Submits the transaction search.
     *
     * IDs for testing.
     * fc7eae1ea02245286c2aa8879e93 // Checkr record
     * 82982c56-867c-11ea-b2d7-12d9a115fe3e // Same Checkr record UUID
     * 12345678901234567890123456789012 // Test record with duplicate reference ids
     *
     * @param {String} search the search value.
     */
    const handleSearch = async (search) => {
        const token = await getAccessTokenSilently();
        ReactSwal.fire({
            title: "Searching...",
            showConfirmButton: false,
            willOpen: async () => {
                Swal.showLoading();
                fetch(`${baseUrl}/transactions/filter?id=${search}`, GetPayload(token))
                    .then((res) => {
                        if (res.ok) return res.json();
                        throw res.json();
                    })
                    .then(({ transactions }) => {
                        transactions.length === 1
                            ? history.push(`/transactions?id=${transactions.pop().uuid}`)
                            : history.push(`/transactions`, { transactions, id: search });
                        ReactSwal.close();
                    })
                    .catch((err) => {
                        console.log("ERROR", err);
                        ErrorAlert();
                    });
            },
        });
    };

    const RouteToNavLink = (route, idx) => (
        <Nav.Item key={idx}>
            <Nav.Link
                href={`${window.location.origin}${route.path}`}
                eventKey={route.path}
                onSelect={(eventKey, event) => {
                    event.preventDefault();
                    history.push(eventKey);
                }}
                active={route.pattern.test(location.pathname + location.search)}>
                {route.menuOption}
            </Nav.Link>
        </Nav.Item>
    );

    const LeftSideRoutes = Routes.LeftSide.map(RouteToNavLink);
    // const RightSideRoutes = Routes.RightSide.map(RouteToNavLink);

    return (
        <NavBar variant="dark" sticky="top">
            <Nav fill variant="pills">
                <Nav.Item>
                    <EnvironmentDropdown />
                </Nav.Item>
                {LeftSideRoutes}
            </Nav>
            <Widget.SearchBar
                ref={searchRef}
                search={search}
                setSearch={setSearch}
                placeholder="Search transactions..."
                onSubmit={handleSearch}
                style={{ height: "25px", paddingBottom: "5px" }}
            />
            <Nav fill variant="pills">
                <Nav.Item>
                    <MenuDropdown />
                </Nav.Item>
            </Nav>
        </NavBar>
    );
}
