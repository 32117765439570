const { default: styled } = require("styled-components");

export const RequiredHint = styled.span`
    font-style: italic;
    &::before {
        content: "*";
        color: orangered;
    }
    &::after {
        content: " = required";
    }
`;
