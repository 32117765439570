import React from "react";

/**
 * Returns a "no-content" div for displaying a large icon
 * with text underneath within a cirlce. This is used in
 * place of a component such as a results table when there
 * are no results to display.
 *
 * @param {JSXElement} icon the `<i>` element to display
 * @param {string} text the text to display
 */
export const NoContent = ({ icon, text }) => {
    return (
        <div className="no-content">
            <div className="wrapper">
                <div>
                    {icon}
                    <br />
                    <br />
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
};

export const NoResults = () => (
    <NoContent icon={<i className="fas fa-heart-broken fa-10x" />} text="No results found" />
);

export const ErrorPrompt = () => (
    <NoContent
        icon={<i className="far fa-sad-cry fa-10x" />}
        text="An error was encountered. Please try again."
    />
);

export const SearchPrompt = () => (
    <NoContent icon={<i className="fas fa-search fa-10x" />} text="Search for records above..." />
);

export const NoData = () => {
    return (
        <div id="no-data">
            <h5>No data to display for this transaction.</h5>
        </div>
    );
};
