/**
 * Filters a collection of objects by matching entries whose specified property match
 * the search value or (by default) by matching entries that have any property whose
 * value matches the search value.
 *
 * @param {obj} obj.search the search term to filter with
 * @param {obj} obj.columns the ResultTable columns
 * @param {obj} obj.collection the collection to filter from
 * @param {obj} obj.column a specific column to filter on
 */
export const FilterObjectCollection = ({ search, columns, collection, column, oppositeSearch }) => {
    try {
        if (!search) return collection;
        const regex = new RegExp(search, "i");

        const filterByColumn = (entry) => {
            return Object.entries(entry).some(([key, val]) => {
                const { objProperty, objPropertyRegex } = column;
                if (objProperty && key !== objProperty) return false;
                if (objPropertyRegex && !objPropertyRegex.test(key)) return false;

                const { valueFunction, valueFormatter } = column;
                if (valueFunction) val = valueFunction(val);
                if (valueFormatter) val = valueFormatter(val);
                return oppositeSearch ? !regex.test(val) : regex.test(val);
            });
        };

        const filterByAny = (entry) => {
            const matchesFound = Object.entries(entry).some(([key, val]) => {
                const col = columns?.find((c) => {
                    return c.objProperty === key || c.objPropertyRegex?.test(key);
                });
                const { valueFunction, valueFormatter } = col || {};
                if (valueFunction) val = valueFunction(val);
                if (valueFormatter) val = valueFormatter(val);
                return regex.test(val);
            });
            return oppositeSearch ? !matchesFound : matchesFound;
        };

        return collection.filter(column ? filterByColumn : filterByAny);
    } catch (err) {
        console.log(err);
        return collection;
    }
};

// export const FilterObjectCollection = ({ search, collection, objProperty }) => {
//     try {
//         if (!search) return collection;
//         const regex = new RegExp(search, "i");
//         const regexTest = objProperty
//             ? (entry) => regex.test(entry[objProperty])
//             : (entry) => Object.values(entry).some((val) => regex.test(val));

//         return collection.filter(regexTest);
//     } catch (err) {
//         console.log(err);
//         return collection;
//     }
// };

/**
 * Filters a collection of strings based on the search value.
 *
 * @param {obj} obj.search the search term to filter with
 * @param {obj} obj.collection the collection to filter from
 */
export const FilterStringCollection = ({ search, collection }) => {
    try {
        if (!search) return collection;
        const regex = new RegExp(search, "i");
        return collection.filter(regex.test);
    } catch (err) {
        console.log(err);
        return collection;
    }
};
