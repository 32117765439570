import React, { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { ErrorMessage } from "src/components/reusable/ReactFormComponents";
import Widget from "src/widgets/Widget";
import { PostbackType } from "../NewAuthSchemaModal";
import { TwoColumnLayout } from "src/components/styled_components/Layouts";
import { useAuthSchemas } from "src/hooks/react-query/accounts/useAuthSchemas";
import { DuplicateAlert } from "./_DuplicateAlert";

const required = { required: "This field is required" };
const inputStyle = { marginBottom: "5px" };

const validInputRegex = /^[A-Za-z0-9!@#$%^&*()[\]?_-]{1,}$/;
const invalidMessage =
    "Must only contain letters, numbers, and the following symbols: !@#$%^&*()[]?_-";

export function BasicAuthContent(props) {
    const {
        account,
        visible,
        readOnly,
        schema,
        setUnsavedWork,
        setSaveBtnVisible,
        setSaveBtnDisabled,
        disableSaveWhenUnchanged,
        updatePermissions,
        currentPostbackType,
        selectedPostbackType,
    } = props;
    const { username: currentUsername, password: currentPassword } = schema?.basic || {};

    const { register, errors, control } = useFormContext();
    const username = useWatch({ control, name: "basic.username" });
    const password = useWatch({ control, name: "basic.password" });
    const changesMade = username !== currentUsername || password !== currentPassword;

    const { detectDuplicate } = useAuthSchemas(account.name);
    const identicalAuth = detectDuplicate({
        id: schema?.id,
        type: PostbackType.BASIC,
        basic: { username, password },
    });

    const handleChange = () => setUnsavedWork(changesMade);

    useEffect(() => {
        if (!visible) return;
        const fromOtherAuthType = currentPostbackType !== PostbackType.BASIC;
        setSaveBtnVisible(fromOtherAuthType || changesMade);
        if (disableSaveWhenUnchanged && !changesMade) return setSaveBtnDisabled(true);
        if (identicalAuth) return setSaveBtnDisabled(true);
        setSaveBtnDisabled(!username || !password || !!errors.basic);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password, currentPostbackType, selectedPostbackType]);

    if (!visible) return null;
    return (
        <div>
            {identicalAuth && DuplicateAlert}
            <TwoColumnLayout>
                <div>
                    <Widget.Input
                        readOnly={readOnly}
                        permissions={updatePermissions}
                        type="text"
                        name="basic.username"
                        label="Username"
                        value={username}
                        placeholder="username"
                        ref={register({
                            ...required,
                            validate: (value) => {
                                if (!validInputRegex.test(value)) return invalidMessage;
                                if (value.length < 4) return "Must be 4 or more characters";
                            },
                        })}
                        style={inputStyle}
                        onChange={handleChange}
                    />
                    <ErrorMessage message={errors.basic?.username?.message} />
                </div>
                <div>
                    <Widget.Input
                        readOnly={readOnly}
                        permissions={updatePermissions}
                        type={readOnly ? "password" : "text"}
                        name="basic.password"
                        label="Password"
                        value={password}
                        placeholder="password"
                        ref={register({
                            ...required,
                            pattern: {
                                value: /^[\w\d_-]{1,}$/,
                                message:
                                    "Must be alphanumeric characters and must not contain spaces",
                            },
                            validate: (value) => {
                                if (!validInputRegex.test(value)) return invalidMessage;
                                if (value.length < 8) return "Must be 8 or more characters";
                            },
                        })}
                        style={inputStyle}
                        onChange={handleChange}
                    />
                    <ErrorMessage message={errors.basic?.password?.message} />
                </div>
            </TwoColumnLayout>
        </div>
    );
}
